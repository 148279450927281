import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Container } from 'reactstrap';
import TableContainer from './TableContainer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Constants from "./Constants";
import './SITLSearch.css';
import { MenuItem, Select, Button, Paper, Grid, TextField } from '@mui/material';

export const calculate_zone = (eventdate) => {
    if (eventdate) {
        var myLocal = eventdate.getHours();
        var myUtc = eventdate.getUTCHours();
        if (myUtc < 4) {
            myUtc += 24;
        }
        return myLocal - myUtc;
    }
    return 0
}

/*
This turned out to be useless as when I try to use it from the ControlForm I get a React Error in console.
 */
export const getStartTimeForDatePicker = (datestring_from_row, alreadyGMT) => {

    var input = new Date(datestring_from_row);
    var zd = 0;
    if (!alreadyGMT) {
        zd = calculate_zone(input);
    }
    input.setHours(input.getHours() - zd);
    // First GMT
    // Then 2 hour setup.. if minutes > 30 then we'll do that hour
    input.setSeconds(0);
    var align_min = input.getMinutes();
    console.log(align_min);
    console.log("align_min: " + align_min);
    if (align_min > 0) {
        if (align_min > 30) {
            input.setMinutes(0);
        } else {
            input.setHours(input.getHours() - 1);
            input.setMinutes(0);
        }
    }
    return input;
}


export const SITLSearch = (props) => {
    const navigate = useNavigate(); // extract navigation prop here
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState(location.state.sitlsearchterm);
    // const [criteria, setCriteria] = useState(JSON.parse(location.state.criteria));
    const [data, setData] = useState([]);
    const [mytable, setTable] = useState({
        order: 'asc',
        selected: [],
        //page: 0,
        searchField: typeof searchTerm === "undefined" ? "Diffusion" : searchTerm,
        //rowsPerPage: 25,
        //counter: 0,
        data: null
    });
    const buttonRef = useRef(null);
    const options = Constants.SITL_SUGGEST;
    let mystuff = [{}];
    //add condition for best undefined
    if (typeof location.state.sitlsearchresults != "undefined") {
        mystuff = useState(JSON.parse(location.state.sitlsearchresults));
    }

    useEffect(() => {
        const contacts = mystuff;
        setData(contacts);
    }, []);
    /*
    Navigates back to Landing or Results page with new selected date or the
    passed in, original date
     */
    const assignToDatePicker = (row_value) => { //redirect 4
        //check to see if row value is not null or blank
        console.log('row_value', row_value);
        // if (!row_value) { // Keep original time button
        //     row_value = criteria.startTime;
        //     var passable_date = getStartTimeForDatePicker(row_value, 1);
        // } else { // get selected date and pass it back to landing page through shared store
        //     var passable_date = getStartTimeForDatePicker(row_value);
        // }

        //props.startTime = passable_date;
        //props.searchStartTime(row_value);
        // props.sharedStore.dispatch({
        //     type: 'SET_STARTTIME',
        //     value: passable_date
        // });

        // props.sharedStore.dispatch({ // So that the landing page can recieve on Retain date case
        //     type: 'SET_SITL', value: searchTerm
        // });

        // console.log("assign2: " + searchTerm)


        // var destination = "/results/"
        // if (location.state.context === "landingpage") {
        //     destination = "/"
        // }
        // props.startTime = passable_date;
        // props.sharedStore.dispatch({
        //     type: 'SET_STARTTIME',
        //     value: passable_date
        // });
        console.log("assign3" + searchTerm);

        /*
        In the landing screen case, we are really not passing anything as nothing shows up in the location var when returning to landing screen.
        the landing screen already has the search term and it gets the start time from the sharedStore.
         */
        // navigate(destination, {
        //     state: {
        //         startTime: passable_date,
        //         context: location.state.context,
        //         criteria: JSON.stringify(criteria), // for ResultsScreen case
        //         searchQuery: searchTerm             // for results page case
        //     }
        // });
    }
    const sitlSuggestMenuChange = (ev) => {
        console.log(ev.target.value);
        firstQuery(ev.target.value);
        setTable({
            ...mytable,
            searchField: ev.target.value
        });
        setSearchTerm(ev.target.value);
    }

    /*
    Queries the sitl table when the user is already on the SITL table search page
    then setData() at the end of the function triggers the table to update
     */



    const firstQuery = (initialSearchQuery) => { //redirect 4
        //Dont need this anymore
        // props.sharedStore.dispatch({ // So that the landing page can recieve
        //     // the new sitl-search-query term
        //     type: 'SET_SITL',
        //     value: initialSearchQuery
        // });
        console.log('initialSearchQuery', initialSearchQuery);

        if (mytable) {
            axios.get('api/search_results?q=' + initialSearchQuery)
                .then((response) => {
                    console.log('response', response);
                    const rows = response.data.brstsegs;
                    var firstdata = [];
                    for (var i = 0; i < rows.length; ++i) {
                        firstdata.push(createData(i, rows[i].comment, rows[i].first_buffer, rows[i].fom));
                    }
                    setTable({
                        ...mytable, response: response,
                        data: firstdata,
                        counter: rows,
                        searchField: initialSearchQuery
                    });
                    setData(firstdata);
                    setSearchTerm(initialSearchQuery);
                });
            //Why is this happen outside of the then? 

            // if (mytable != null && mytable.data != null) {
            //     setData(mytable.data);
            //     setSearchTerm(initialSearchQuery);
            //     console.log("after setting:" + searchTerm)
            // }
        }
    }

    function createData(cntr, comment, time, fom) {
        return { id: cntr, comment, time, fom };
    }

    /*
    This is the simplified column object I got from
     https://medium.com/@thewidlarzgroup/react-table-7-sorting-filtering-pagination-and-more-6bc28af104d6
     */
    const columns = useMemo(
        () => [
            {
                Header: "SITL Comment",
                accessor: "comment",
                // sortable: false,
                // filterable: false,
                disableFilters: true,
                // disableSortBy: true,
                //disableGlobalFilter: true,
            },

            {
                Header: "Select Time",
                accessor: "time",
                disableFilters: true,
                Cell: ({ value }) => (
                    <input type="button"
                        value={value}
                        onClick={() => props.setSearchDateTime(value)}
                    />
                ),
            },
            {
                Header: "Figure of Merit (FOM)",
                accessor: "fom",
                disableFilters: true,
            },
        ]
    )

    useEffect(() => {
        // setTimeout(() => {
        firstQuery(mytable.searchField);
        // }, 5000);
    }, []);
    /*
    The MuiTable component (SITL Search) with embedded Table and Filter components
     */
    return (
        <React.Fragment>
            <div class="search-result-container">
                <div class="search-fields">
                    <TextField
                        id="filled-search"
                        type="search"
                        class="search-textField"
                        value={mytable.searchField}
                        onChange={(ev) => {
                            setTable({
                                ...mytable,
                                searchField: ev.target.value
                            });
                            setSearchTerm(mytable.searchField);
                        }}
                        onBlur={(ev) => { // re-render issue: these extra brackets and stuff prevent the function from being constantly called
                            firstQuery(mytable.searchField);
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    class="search-input-icon">
                                    <IconButton ref={buttonRef} onClick={() => firstQuery(mytable.searchField)}>
                                        <SearchIcon />
                                    </IconButton>

                                </InputAdornment>)
                        }}
                    />

                    <Select
                        sx={{
                            width: '40%',
                            marginLeft: '10%',
                            borderRadius: '10px',
                        }}
                        label="Top Searches"
                        onChange={(ev) => { // re-render these extra brackets and stuff prevent the function from being constantly called
                            sitlSuggestMenuChange(ev);
                        }}>
                        {options.map(option => {
                            return (
                                <MenuItem key={option} value={option} sx={{ fontSize: '.7rem' }}>
                                    {option}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </div>

                {/*<h4>Select Another Time Period To Plot</h4>


             <Button
                style={{
                    background: "#f0f0f0",
                    height: "60px"
                }}
                onClick={() => assignToDatePicker()}>
                Return (keep original date)
            </Button> */}


                <TableContainer
                    columns={columns}
                    data={data}
                />
            </div>
        </React.Fragment >
    );
};

export default SITLSearch