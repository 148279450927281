import React from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'
import * as THREE from 'three';
import Button from '@mui/material/Button';
import isWebglEnabled from 'detector-webgl';
import ControlForm from "./ControlForm"
import Constants from "./Constants";
import Utils from "./Utils";
import "./LandingScreen.css";
import rightarrow from "./images/rightcarat.svg";

/**
 * Landing screen. Displays intro content and has the control form component
 * embedded in it. It passes the onGenerateClick function to the ControlForm
 * component as a prop.
 * It also passes (navigates to) the ControlForm's plotting info to the ProgressScreen (as a
 * location variable - as in useLocation)
 * It also uses useLocation to pass SITL Search query results to the MuiTable
 * page. (this happens in the navigation (useNavigation) happens)
 * The MuiTable is most definitely a page with a couple fancy components
 * downloaded from the internet in it (see git log)
 *
 * For whatever reason I was unable to use useLocation to pass data back to the LandingPage
 * from the MuiTable page. So here I use the sharedStore that Daniel set up. You may see in
 * MuiTable I pass several items back to the LandingPage but none are retreived.
 *
 * To ControlForm passes props:
 *                   onGenerateClick - a function to navigate to results page (ResultsScreen)
 *                   toNewMuiClick   - a function to navigate to sitl-search page (MuiTable)
 *                   startTime       - latest startTime received from whatever context
 *                   context         - sending page of navigation
 *                   initialSearchQuery - sitl-search query term
 * LandingPage
 *
 * @prop sharedStore :: Redux state container shared between the routes of the
 *                      application.
 */

function LandingScreen(props) {

    const navigate = useNavigate(); // extract navigation prop here
    const STL_MODEL_PATH = 'static/MMS-3dModel.stl';
    const IMG_MODEL_PATH = 'static/3d-model-default.png';

    //const new_starttime = props.sharedStore.getState().startTime;
    var jestman = null
    if (!Utils.areWeTestingWithJest()) {
        jestman = new STLLoader();
    }
    const [kodak, setState] = useState({
        camera: new THREE.PerspectiveCamera(35, 1, .01, 15),
        cameraTarget: new THREE.Vector3(0, 0, 0),
        container: "",
        renderer: new THREE.WebGLRenderer({ antialias: true }),
        controls: null,
        scene: new THREE.Scene(),
        loader: jestman,
        mms: null
    });
    const [mytable, setTable] = useState({
        response: null,
        redirect: null,
        order: 'asc',
        sharedStore: props.sharedStore,
        selected: [],
        page: 0,
        rowsPerPage: 25,
        counter: 0,
        data: null
    });
    const [startTime, setStartTime] = useState(setupStartTime);
    const [searchStuff, setSearchQuery] = useState(setupSearchQuery);
    function setupStartTime() {
        if (props.sharedStore) {
            let aviation = props.sharedStore.getState().startTime;
            return aviation;
        } else {
            return new Date(Constants.DEFAULT_START_TIME)
        }
    }

    function setupSearchQuery() {
        if (props.sharedStore) {
            let aviation = props.sharedStore.getState().searchQuery;
            return aviation;
        } else {
            return "";
        }
    }


    useEffect(() => {
        componentDidMount();
        document.getElementById("onboarding-2").style.visibility = "hidden";
        document.getElementById("onboarding-2").style.display = "none";
        document.getElementById("onboarding-1").style.visibility = "visible";
        return () => {
            componentDidUnmount();
        }
    }, []);

    const [goToProgressScreen, setGoToProgress] = React.useState({
        state: false,
        transferInfo: "no json yet"
    });

    /*
    I use the following 3 blocks of code to enable ControlForm to call MuiTable (Sitl Search Table page)
    The first is just to set the variables
    The second is passed as a prop to ControlForm component for it to use (from within LandingScreen or ResultsScreen)
    The third does the work to travel,navigate, to the SITL Search page MuiTable
     */
    const [goToNewMui, setGoToNewMui] = React.useState({
        state: false,
        transferInfo: "not using new table yet",
        searchInfo: "no search yet"
    });

    if (goToNewMui.state) {
        navigate('/newsearch', {
            state: {
                context: "landingpage",
                sitlsearchresults: goToNewMui.transferInfo,
                sitlsearchterm: goToNewMui.searchInfo,
                criteria: goToNewMui.formState
            }
        });
    }
    /**
     * These next two are for the Next Plot: Progress...->ResultsScreen
     * This guy is sent as a prop to the ControlForm (and is given to
     * a button's onClick attribute.
     * Called when the generate button is clicked from the control form.
     */
    const onGenerateClick = (formState) => {
        navigate('/results');
        return;
        let localFormState = formState || {
            "spacecraft": 1,
            "duration": 2,
            "units": "hours",
            "event": "none",
            "arrayorder": {
                "Single-Spacecraft": ["M0", "01", "02", "03", "04", "05", "06", "07", "08", "09", "0A", "0B", "0K", "0L", "0C", "0D", "0E", "0F", "0G", "0H", "0I"],
                "Multi-Spacecraft": ["10", "11", "12", "13", "14", "15"]
            },
            "startTime": "2015-10-16T09:00:00.000Z", "endTime": "none",
            "variables": ["00", "M0", "01", "02", "03", "04", "05", "06", "07"],
            "selectAll": false, "fst": 0, "bst": 1, "gsm": 1, "zd": 0, "context": "landingpage", "initialSearchQuery": "", "searchbox_label": "SITL Comment:",
            "searchQuery": "", "searchData": "none", "present": "Wed Aug 16 2023 17:00:00 GMT-0700 (Pacific Daylight Time)", "sitl_present": "2023-08-14T18:00:00.000Z"
        }

        var prep = JSON.stringify(localFormState)
        console.log('goToProgressScreen', goToProgressScreen);
        setGoToProgress({ ...goToProgressScreen, state: true, transferInfo: prep });
    }
    if (goToProgressScreen.state) {
        navigate('/progress', {
            state: {
                criteria: goToProgressScreen.transferInfo,
            }
        });
    }


    /**
     * Called after the component is added to the DOM.
     * In class components, this componentDidMount has magic but
     * does not now in our functional component paradigm.
     * But since useEffect has a similar purpose, I kept the name
     * for what I hope is clarity.
     */
    function componentDidMount() {
        if (props.sharedStore) {
            let aviation = props.sharedStore.getState().startTime;
        }
        if (isWebglEnabled) {
            window.addEventListener('resize', onWindowResize, false);

            initAnimation();
            animate();
            renderAnimation();
        } else {
            const modelDiv = document.getElementById('mms-3d-model');
            modelDiv.style.background = `url("${IMG_MODEL_PATH}") no-repeat center`;
            modelDiv.style.cursor = '';

            const captionDiv = document.getElementById('mms-3d-model-caption');
            captionDiv.innerHTML = '3D Model of an MMS Satellite';

        }
    }

    function componentDidUnmount() {
        if (isWebglEnabled) {
            window.removeEventListener('resize', onWindowResize);
        }
    }

    /**
     * Initialize the animation.
     */
    function initAnimation() {
        let local_container = document.getElementById('mms-3d-model');
        kodak.camera.position.set(.3, .3, .3);

        let localControls = new OrbitControls(kodak.camera, local_container);

        kodak.scene.background = new THREE.TextureLoader().load("/static/stars.jpg", (texture) => {
            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            texture.offset.set(0, 0);
            texture.repeat.set(4, 2);
        });

        var material = new THREE.MeshPhongMaterial({
            color: 0xAAAAAA,
            specular: 0x111111,
            shininess: 200
        });

        kodak.loader.load(STL_MODEL_PATH, (geometry) => {
            var mesh = new THREE.Mesh(geometry, material);
            mesh.position.set(-0.855, -.50, -0.855);
            mesh.rotation.set(0, 0, 0);
            mesh.scale.set(.2, .2, .2);

            setState({ ...kodak, mms: mesh });
            kodak.scene.add(mesh);
        });

        // Lights
        kodak.scene.add(new THREE.HemisphereLight(0xffffff, 0x111122));

        // Renderer
        kodak.renderer.setPixelRatio(window.devicePixelRatio);
        kodak.renderer.setSize(window.innerWidth * .70 - 20, 300);

        kodak.camera.aspect = window.innerWidth * .70 / 300;
        kodak.camera.updateProjectionMatrix();

        var local_renderer = kodak.renderer;
        local_renderer.gammaInput = true;
        local_renderer.gammaOutput = true;
        local_renderer.shadowMap.enabled = true;
        local_container.appendChild(local_renderer.domElement);
        setState({
            ...kodak, renderer: local_renderer,
            scene: kodak.scene,
            container: local_container,
            controls: localControls,
            camera: kodak.camera
        });
    }

    /**
     * Called when the window is resized.
     */
    const onWindowResize = () => {
        kodak.camera.aspect = window.innerWidth * .70 / 400;
        kodak.camera.updateProjectionMatrix();
        kodak.renderer.setSize(window.innerWidth * .70 - 20, 400);
        setState({
            ...kodak, renderer: kodak.renderer,
            camera: kodak.camera
        });
    }

    function animate() {
        requestAnimationFrame(() => animate());
        renderAnimation();
    }

    function renderAnimation() {
        kodak.camera.lookAt(kodak.cameraTarget);
        kodak.renderer.render(kodak.scene, kodak.camera);
    }

    /*
    Finally, the Page: (including the ControlForm component)
     */
    return (
        <div className="left-split">
            {/* About Page */}
            <div className='landing-content' valign='top'>

                <h2 className='about-header'>About</h2>

                <p>
                    The Magnetospheric Multiscale (MMS) mission is the fourth mission of the Solar Terrestrial Probe (STP)
                    program of the National Aeronautics and Space Administration (NASA). MMS utilizes four identically
                    instrumented observatories to perform the first definitive study of magnetic reconnection in space
                    and tests critical hypotheses about reconnection. Magnetic reconnection is the primary process by
                    which energy is transferred from the solar wind to the Earth’s magnetosphere and is also fundamental
                    to the explosive release of energy during substorms and solar flares.
                    {/* The Fast Plasma Investigation (FPI) was developed for flight on the <a href={Constants.GSFC_MMS}
                        target="_Blank"
                        rel="noopener noreferrer">Magnetospheric
                        Multiscale (MMS) mission</a> to measure the differential directional
                    flux of magnetospheric electrons and ions with unprecedented time
                    resolution to resolve kinetic-scale plasma dynamics. This increased
                    resolution has been accomplished by placing four dual 180-degree
                    top hat spectrometers for electrons and four dual 180-degree top
                    hat spectrometers for ions around the periphery of each of four MMS
                    spacecraft. */}
                </p>
                <div className='model-container'>
                    <div id="mms-3d-model" />

                    <p
                        id="mms-3d-model-caption"
                        className='model-caption'>
                        Interactive 3D Model of an MMS Satellite
                    </p>
                </div>
                <p>
                    MMS studies magnetic reconnection in the Earth’s magnetosphere,
                    magnetosheath, bowshock, and solar wind within 29RE.
                    The four MMS observatories have primarily flown in a tetrahedral formation to
                    unambiguously determine the orientation, dynamics, and impacts of magnetic reconnection diffusion regions.
                    Information on the scientific purpose of the mission, science operations, instrumentation status, and best practices
                    regarding data usage are documented in the <a href={(Constants.MMS_CMAD)} target='_blank'>MMS Calibration and Measurement Algorithms Document (CMAD)</a>.
                </p>
                {/* <p>
                    Using electrostatic field-of-view deflection, the
                    eight spectrometers for each species together provide 4pi-sr
                    field-of-view with, at worst, 11.25-degree sample spacing.
                    Energy/charge sampling is provided by swept electrostatic
                    energy/charge selection over the range from 10 eV/q to 30 keV/q.
                    The eight dual spectrometers on each spacecraft are controlled and
                    interrogated by a single block redundant Instrument Data Processing
                    Unit, which in turn interfaces to the observatory’s Instrument Suite
                    Central Instrument Data Processor.
                </p> */}
                <div className='landing-btns'>
                    <Button className='explore-btn' size="large" onClick={() => onGenerateClick()}>
                        Explore MMS Data
                        <span class="rightarrow-form"></span>
                    </Button>
                    <Button
                        variant='outlined'
                        onClick={() => window.open(Constants.MMS_PAPER_LINK)}>
                        View MMS Paper
                    </Button>
                    <Button
                        variant='outlined'
                        onClick={() => window.open(Constants.MMS_CMAD)}>
                        View MMS CMAD
                    </Button>
                </div>
            </div>
        </div>
    );

}

export default LandingScreen;
