import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ToggleButton from '@mui/material/ToggleButton';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from '@mui/material/Tooltip';
import DatePicker from "react-datepicker";

/* Here is the documentation for the datepicker:
        https://reactdatepicker.com/#example-17
 */
import 'react-datepicker/dist/react-datepicker.css';

import Constants from "./Constants";
import './ControlForm.css';
import Sortable from 'sortablejs';
import axios from "axios";
import Utils from "./Utils";
import TextField from "@mui/material/TextField";


const EVENTS = require('./events.json');
const VARIABLES = require('./variables.json');
const DEFAULT_INCLUDE_DATES = require('./include_dates');
const sitlsuggest = Constants.SITL_SUGGEST;
/**
 * Control form is a component that is embedded in the LandingPage and the ResultsScreen.
 * It navigates to 2 other pages but does so with callbacks (functions) passed to it as props.
 * So as such it does not need to itself navigate anywhere. So that is why it remains in it's
 * original condition: class component.
 * It displays the criteria selection for the visualizer. Includes
 * fields such as spacecraft, start time, variables, etc.
 *
 * @prop onGenerateClick :: Callback function that accepts an object of form
 *                          state, called when the form is submitted. (Big Green Button)
 * @prop toNewMuiClick   :: Callback function that accepts an object of form
 *                          state, and sitl search data (when the sitl search query is selected)
 *                          (this functional component, replaces old sitl searchpage class component)
 * @prop defaults        :: (Optional) Default values for the form. Uses same
 *                          keys as the form state passed to `onGenerateClick`.
 */
export default class ControlForm extends Component {

    constructor(props) {
        super(props);
        this.newList = {};
        this.onGenerateClick = this.onGenerateClick.bind(this);
        this.CaptureListStuff = this.CaptureListStuff.bind(this);
        this.triggerSearch = this.triggerSearch.bind(this);
        this.IncludeDates = this.importDatesToInclude();

        const arrayorder = {};
        // maintain the DatePicker yearDropDown
        this.yearRange = new Date()
        this.yearRange.setFullYear(this.yearRange.getFullYear() + 1);

        // Handle long and short(url) variable names
        if (VARIABLES.options.idformat === 'long') {
            VARIABLES.categories
                .filter(variable => variable.full_id)
                .forEach(variable => {
                    variable.id = variable.full_id;
                    variable.selectionAlsoSelects = variable.full_selectionAlsoSelects;
                })
        }
        ;

        VARIABLES.categories.forEach(category => {
            arrayorder[category.title] = [];
        });

        if (props.defaults) {
            this.state = props.defaults;
            // Convert start time from string (comes from JSON) to new Date
            this.state.startTime = new Date(this.state.startTime);
            this.setState({ searchQuery: props.initialSearchQuery }); //  props.initialSearchQuery has our value... why doesn't it set?
            console.log("set searchQuery to: " + this.state.searchQuery);

            if (this.state.duration > 6) {
                this.state.units = "minutes"
            }
            else {
                this.state.units = "hours"
            }
            this.state.context = 'resultspage';
            this.state.initialSearchQuery = props.initialSearchQuery || props.defaults.initialSearchQuery;
        } else {
            this.state = {
                spacecraft: 1,
                duration: 2,
                units: "hours",
                event: "none",
                arrayorder,
                startTime: new Date(Constants.DEFAULT_START_TIME),
                endTime: "none",
                variables: this.getDefaultVariables(),
                selectAll: false,
                fst: 0,
                bst: 1,
                gsm: 1,

                context: 'landingpage',
                initialSearchQuery: "",
                searchbox_label: props.searchbox_label || "SITL Comment:",
                searchQuery: props.searchQuery || props.initialSearchQuery || "",
                searchData: "none"
            };
        }
    } // end contructor


    /*
    SITL Search sent over to MuiTable
     */
    triggerSearch(ev) {
        console.log("triggerSearch: " + this.state.searchQuery);
        axios.get('api/search_results?q=' + this.state.searchQuery)
            .then((response) => {
                const rows = response.data.brstsegs;
                var firstdata = [];
                for (var i = 0; i < rows.length; ++i) {
                    firstdata.push(this.createData(i, rows[i].comment, rows[i].first_buffer, rows[i].fom));
                }
                this.setState({ searchData: firstdata });
                const formState = Object.assign({}, this.state);
                this.props.toNewMuiClick(formState);

            });
    };
    createData(id, comment, time, fom) {
        return { id: id, comment, time, fom };
    };
    /*
    This uses the callback,toNewMuiClick, sent over as a prop
    from LandingPage or ResultsPage (ControlForm is embedded in both)
     */

    importDatesToInclude() {
        const dates = [];
        if (Utils.areWeTestingWithJest()) {
            dates.push(new Date());
            return dates;
        }

        axios.get(`api/include_dates?file=on_backend`
        ).then(resp => {
            if (resp.data.error) {
                const { message } = resp.data;
                // need to do something here: const redirect = <Navigate to={`/error/${message}`}/>
                // this.setState({redirect});
                return;
            }

            const {
                results
            } = resp.data;
            results.forEach(thisdate => {
                dates.push(new Date(thisdate));
            });
            this.get_latest_date(dates);

        })
            .catch(error => {
                if (!Utils.areWeTestingWithJest()) {
                    const message = "An unknown error downloading included dates. Using default set";
                    //console.log(message);
                }
                // if the file isn't under temp on the server, get this default set
                // which is shipped with the application:
                DEFAULT_INCLUDE_DATES.forEach(thisdate => {
                    var thisDate = new Date(thisdate);
                    var zd = this.calculate_zone(thisDate);
                    thisDate.setHours(thisDate.getHours() + zd);

                    dates.push(new Date(thisdate));
                });

            })
        return dates;

    }


    // string of 2char pieces, convert to 2 char string array -- reduce URL size
    restoreArrayOrder(currentList) {
        var inside = [];
        var temp = "";
        for (let i = 0; i < currentList.length; ++i) {
            if (i > 0 && i % 2 !== 0) {
                temp += currentList[i];
                inside.push(temp);
                temp = "";
            } else {
                temp += currentList[i];

            }

        }
        return inside;
    }

    /**
     * Returns list of default enabled variables.
     *
     * @return Defaultv value for `this.state.variables`
     */
    getDefaultVariables() {
        const defaultVariables = [];

        VARIABLES.categories.forEach(category => {
            category
                .variables
                .filter(variable => variable.defaultEnabled)
                .forEach(variable => {
                    defaultVariables.push(variable.id);
                });
        })

        return defaultVariables;
    }

    // 1. Arrange (indented) component-variables as needed
    // 2. create sortablejs list
    CaptureListStuff(title) {
        var tobe_resorted = document.getElementById(title)
        VARIABLES.categories.forEach(category => {
            category.variables
                .filter(variable => variable.selectionAlsoSelects && variable.selectionAlsoSelects.length > 1)
                .forEach(variable => {
                    // create our orderedlist element
                    var elem = document.createElement("ol");
                    elem.setAttribute('className', 'indent');
                    // move all of the component variables
                    variable.selectionAlsoSelects.forEach(item => {
                        elem.appendChild(document.getElementById(item))
                    });
                    // attach them to the parent
                    var parent = document.getElementById(variable.id);
                    parent.appendChild(elem);
                }
                )
            this.newList[title] = new Sortable(tobe_resorted);

        })
    }


    /**
     * Called immediately after the component is added to the DOM.
     */
    componentDidMount() {

        this.sitl_present = this.get_latest_sitl_date();
        /* This disables the ability to edit the date directly
        const datePicker = document.getElementsByClassName("new-date-picker")[0];
        const input = datePicker.children[0].children[0].children[0];
        input.setAttribute('readonly', true);
        input.setAttribute('type', 'button');
         */
        VARIABLES.categories.forEach(category => {
            this.CaptureListStuff(category.title);
        });
        /* critical for using SITL start time... */
        if (this.props.startTime) {
            this.setState({ startTime: this.props.startTime });
        }
        if (this.props.initialSearchQuery != null && this.props.initialSearchQuery != "") {
            this.setState({ searchQuery: this.props.initialSearchQuery });
            console.log("reset searchQuery to: " + this.state.searchQuery);
        }

    }

    get_latest_date(dates) {
        var latest = DEFAULT_INCLUDE_DATES.slice(-1).toString();
        if (dates && dates.length > 1) {
            latest = dates.slice(-1).toString();
        }
        this.setState({ present: latest });
    }

    get_latest_sitl_date() {
        var latest = this.present;
        axios.get('api/latest_sitl_date?q=' + this.initialSearchQuery)
            .then((response) => {
                latest = response.data.brstsegs[0].first_buffer;
                let aviation = this.getStartTimeForDatePicker(latest, true);
                this.setState({ sitl_present: aviation }); /* see import statement at top of page */
            });
    }

    getStartTimeForDatePicker = (datestring_from_row, alreadyGMT) => {

        var input = new Date(datestring_from_row);
        var zd = 0;
        if (!alreadyGMT) {
            zd = this.calculate_zone(input);
        }
        input.setHours(input.getHours() - zd);
        // First GMT
        // Then 2 hour setup.. if minutes > 30 then we'll do that hour
        input.setSeconds(0);
        var align_min = input.getMinutes();
        console.log(align_min);
        console.log("align_min: " + align_min);
        if (align_min > 0) {
            if (align_min > 30) {
                input.setMinutes(0);
            } else {
                input.setHours(input.getHours() - 1);
                input.setMinutes(0);
            }
        }
        return input;
    }
    /**
     * Toggle variable selected. Called when checkboxes are clicked`
     *
     * @param variableId Identifier of variable within `this.state.variables`
     *
     * This now has code to uncheck and un-include corresponding magnitude and
     * vector checkboxes (M V)
     *
     * */

    toggleVariableSelected(variableId) {
        var variables = this.state.variables.slice();
        document.getElementById("no-var-warn").style.visibility = "hidden";

        if (variables.includes(variableId)) {// if it is being unselected:
            //this.state.selectAll = false;
            this.setState({ selectAll: false });
            var buttonState = this.state.selectAll;

            VARIABLES.categories.forEach(category => {
                category.variables.filter(variable => variable.id === variableId)
                    .forEach(variable => {
                        variables = variables.filter(elem => elem !== variableId);
                    })
            });

        } else { // if it is being selected/checked:


            VARIABLES.categories.forEach(category => {
                category.variables.filter(variable => variable.id === variableId)
                    .forEach(variable => {
                        variables.push(variableId);
                        // Display minion case. The main variable itself has no plotPanels. Only it's minions
                        if (variable.selectionAlsoSelects) {
                            variable.selectionAlsoSelects.forEach(also => {
                                document.getElementById(also).checked = false;
                                variables = variables.filter(elem => elem !== also);
                            })
                        }
                    })

            });

        }

        this.setState({ buttonState });
        this.setState({ variables });
    }

    /**
     * Determines if user has selected at least one var
     * @param passed
     * @returns {boolean}
     */
    noneSelected(passed) {
        if (passed.variables.length < 2) {
            for (let i = 0; i < passed.variables.length; ++i) {
                if (passed.variables != VARIABLES.categories[0].variables[0].id) {
                    return false;
                }
            }
            return true;
        }
        return false
    }

    /**
     * Returns boolean whether the variable is selected.
     *
     * @param variableId Identifier of variable within `this.state.variables`
     * @returns Whether or not variable is included in `this.state.variabes`
     */
    isVariableSelected(variableId) {
        return this.state.variables.includes(variableId);
    }


    /**
     * Callback for when the generate plots button is clicked.
     */
    onGenerateClick() {
        if (this.props.onGenerateClick) {
            // Copy the state into a local variable
            const formState = Object.assign({}, this.state);


        // Sort enabled variables list to be the same order as the
        // variable.json
        var sortedVariables = [];


            // if using bookmark, restore shortened string to array
            if (formState.variables.constructor !== Array) {
                formState.variables = this.restoreArrayOrder(formState.variables);
            }

            // Burst tracker is always first
            if (this.state.bst) {
                if (formState.variables[0] == VARIABLES.categories[0].variables[0].id) {
                    sortedVariables.push(formState.variables[0])
                } else {
                    sortedVariables.push(VARIABLES.categories[0].variables[0].id)
                }
            }

            if (this.noneSelected(formState)) {
                document.getElementById("no-var-warn").style.visibility = "visible";
                return;
            }


            VARIABLES.categories.forEach(category => {
                // Notice how we save state here. We assign a variable to the state
                // variable and then save that variable at the end.
                var allVariables = this.state.arrayorder[category.title];
                // if using bookmark, restore shortened string to array
                if (allVariables.constructor !== Array) {
                    allVariables = this.restoreArrayOrder(allVariables);
                    // perhaps since allVariables is set to only part of array order I can set the whole thing:
                    //this.state.arrayorder[category.title]  = allVariables;//not sure I can change this partial setstate
                    this.setState({ arrayorder: allVariables }) //not sure I can change this partial setstate
                }
                if (this.newList[category.title]) {

                    // This is the current user moved around order
                    const thisList = this.newList[category.title].el.childNodes;

                    for (let j = 0; j < thisList.length; ++j) {
                        let variable_id = thisList[j].id;
                        if (formState.variables.includes(variable_id)) {
                            sortedVariables.push(variable_id);
                        }
                        // check for checked indented variables in this independent list:
                        // It needs to be done here to maintain the thisList order.
                        category.variables.forEach(variable => {
                            if (variable.selectionAlsoSelects && variable_id === variable.id) {
                                variable.selectionAlsoSelects.forEach(item => {
                                    if (formState.variables.includes(item)) {
                                        sortedVariables.push(item);
                                    }
                                });
                            }
                        });
                        allVariables[j] = variable_id;
                    }
                    this.setState({ allVariables });

                }
                // This is done after the each subsequent plot if the user has changed the order
                // Actually I am not sure this is ever useiabiad...
                else if (this.state.arrayorder[category.title].length > 0) {
                    sortedVariables.push(formState.variables[0])
                    for (var k = 0; k < this.state.arrayorder[category.title].length; ++k) {
                        if (formState.variables.includes(this.state.arrayorder[category.title][k])) {
                            sortedVariables.push(this.state.arrayorder[category.title][k]);
                        }
                    }
                }
            });

            //if (this.noneSelected(sortedVariables)) {
            //   document.getElementById("no-var-warn").style.visibility = "visible";
            //  return;
            //}

            formState.variables = sortedVariables;

            this.props.onGenerateClick(formState);
        }
    }

    // This evidently, does the trick. That is it shows last date in the datePicker
    showingLocalSendingUTC(lastdate) {
        var localdate = new Date(lastdate);
        var zone = this.calculate_zone(localdate);
        localdate.setHours(localdate.getHours() - zone);
        this.setState({
            startTime: new Date(localdate) || this.state.startTime,
            event: 'none'
        })
    }

    calculate_zone(eventdate) {

        if (eventdate) {
            var myLocal = eventdate.getHours();
            var myUtc = eventdate.getUTCHours();
            if (myUtc <= 4) {
                myUtc += 24;
            }
            return myLocal - myUtc;
        }
        return 0
    }

    /**
     * Gets the start time for an event as a new Date object.
     *
     * @param eventKey Identifier of the event, pairs with the key named "key"
     * in events.json.
     * @returns Moment associated with start time or "null" if no such event
     * exists.
     */
    getStartTimeForEvent(eventKey) {
        const targetEvent = EVENTS.events.filter(_ => _.key === eventKey);

        if (targetEvent.length === 0) {
            return null;
        } else {
            this.setState({ initialSearchQuery: "" });
            var input = new Date(targetEvent[0].startTime);
            var zd = this.calculate_zone(input);
            input.setHours(input.getHours() - zd);
            return input;
        }
    }

    // To do this after plot I must do it each time
    // I can wait until the first plot has been done
    // to re-order the list on every state change (selection)
    sortableorder(listitems, category) {
        if (this.state.variables && this.state.arrayorder) {
            var currentList = this.state.arrayorder[category];
            // if using bookmark with shortened arrayorder conv to string restore:
            if (!currentList) return; // on it's way out to go back to progressScreen with bookmarke currentList isn't available.
            if (currentList.constructor !== Array) {
                currentList = this.restoreArrayOrder(currentList); //restore not reducedUrl state
            }
            if (currentList.length > 0) {
                for (var i = 0; i < listitems.length; ++i) {
                    var hasN = this.nextInSortableOrder(listitems, currentList[i])
                    if (hasN) {
                        var tmp = listitems[i];
                        listitems[i] = listitems[hasN];
                        listitems[hasN] = tmp;
                    } else {
                        //console.log(this.state.arrayorder[category][i]);
                    }
                }
            }
        }
    }

    nextInSortableOrder(mylist, current) {
        if (!current) {
            return;
        }
        for (var i = 0; i < mylist.length; ++i) {
            if (mylist && mylist[i].key === current) {
                return i;
            }
        }
    }

    /*
    Use 1 and 0 instead of true false for a shorterUrl
     */
    selectFastOnly(cbstate) {
        if (cbstate.target.checked) {
            this.setState({ fst: 1 })
        } else {
            this.setState({ fst: 0 })
        }
    }
    selectBurstTracker(cbstate) {
        if (cbstate.target.checked) {
            this.setState({ bst: 1 })
        } else {
            this.setState({ bst: 0 })
        }
    }
    selectGSM(cbstate) {
        if (cbstate.target.checked) {
            this.setState({ gsm: 1 })
        } else {
            this.setState({ gsm: 0 })
        }
    }

    /* for selectAll button */
    selectThemAll(cbstate) {
        var variables = this.state.variables.slice();
        const checkedVariables = [];
        if (cbstate.target.checked === false) {
            // if unchecking, we want to keep burst variable
            // if checking, we don't need burst variable
            checkedVariables.push(variables[0]);
        }
        //this.state.selectAll = cbstate.target.checked;
        this.setState({ selectAll: cbstate.target.checked })
        var buttonState = this.state.selectAll;
        VARIABLES.categories.forEach(category => {
            category.variables.forEach(variable => {
                // Don't select by default indented variables
                if (!(variable.selectionAlsoSelects &&
                    variable.selectionAlsoSelects.length === 1)) {
                    if (cbstate.target.checked) {
                        checkedVariables.push(variable.id);
                    }
                }
            })
        });

        variables = checkedVariables;
        this.setState({ buttonState });
        this.setState({ variables });
    }

    /* Perhaps the reason Daniel made the DatePicker a button
       so that you could not edit it was to avoid this very problem.
       This function keeps the app from crashing if the user manually
       deletes the whole date
     */
    protect_utc() {
        if (this.state.startTime != null) {
            const newAddress = this.state.startTime;
            const display = new Date(newAddress);
            return display;
        }
        return null;
    }

    adjustToUTC(start) {
        start = this.handleDatePicker(start)
        // without this if statement, the date picker doesn't close after selecting a time.
        if (this.state.event !== "none") {
            this.setState({ event: "none" });
        }
        this.setState({ startTime: start });
    }

    /* Let's open up some possibilities */
    handleDatePicker(startTime) {
        var resetSitl = this.state.initialSearchQuery;
        //var assessTime = new Date(startTime);
        if (startTime !== null) {
            var hourNazi = startTime.getMinutes();
            if (hourNazi.toString().length === 2) {
                if (hourNazi < 30) {
                    startTime.setMinutes(0);
                } else {
                    startTime.setMinutes(30);
                }
            }
        }
        if (resetSitl !== "") {
            resetSitl = "";
            this.setState({ initialSearchQuery: resetSitl });
        }
        return startTime;
    }

    /**
     * Returns body of the component.
     */
    render() {
        /* hide this warning as soon as they select a checkbox
           Build list of elements for the variable picker */
        const variableCategoryLists = VARIABLES.categories.map(category => {
            const listItems = category.variables
                .filter(variable => variable.displayLabel !== null)
                .map(variable =>
                    <li className={variable.indent}
                        id={variable.id}
                        style={{ listStyle: 'none', display: 'block' }}
                        key={variable.id}>
                        <input

                            type="checkbox"
                            color="#6699cc"
                            id={variable.id}
                            checked={this.isVariableSelected(variable.id)}
                            onChange={() => this.toggleVariableSelected(variable.id)}
                        />
                        <span
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => this.toggleVariableSelected(variable.id)}
                        >
                            {variable.displayLabel}
                        </span>
                    </li>
                );
            this.sortableorder(listItems, category.title);
            return (
                <div key={category.title}>
                    <span style={{ fontSize: '14px' }}>
                        {category.title}
                    </span>
                    <Tooltip
                        title="Drag and drop variable names in this list to re-order the plotted variables"
                        placement="top">
                        <LiveHelpOutlinedIcon
                            style={{
                                height: '14px',
                                cursor: 'text',
                                color: '#6699cc'
                            }} />
                    </Tooltip>

                    <ol id={category.title}
                        style={{ paddingLeft: '3px', marginTop: '2px' }}>
                        {listItems}
                    </ol>
                </div>
            );

        });

        // Build list of elements for the events picker
        const eventMenuItems = [
            <MenuItem value={'none'} key="none">Featured Events</MenuItem>
        ];

        EVENTS.events.forEach(eventObj => {
            const menuItem = (
                <MenuItem
                    style={{
                        fontSize: '13px',
                        padding: '5px'
                    }}
                    value={eventObj.key}
                    key={eventObj.key}>
                    {eventObj.key} - {eventObj.displayLabel}
                </MenuItem>
            );

            eventMenuItems.push(menuItem);
        });


        // Return component body
        return (

            <div style={{ padding: '10px' }}>
                <h2 style={{ marginTop: '0.2em' }}>
                    Visualize
                </h2>

                {/* Spacecraft Selection */}
                <h5>Spacecraft</h5>

                <span className="sc-selector" style={{ display: 'flex', width: '100%' }}>
                    <ToggleButtonGroup
                        exclusive
                        value={this.state.spacecraft}
                        onChange={(_, spacecraft) => this.setState({ spacecraft })}>
                        <ToggleButton value={1} style={{ width: '25%' }}>MMS1</ToggleButton>
                        <ToggleButton value={2} style={{ width: '25%' }}>MMS2</ToggleButton>
                        <ToggleButton value={3} style={{ width: '25%' }}>MMS3</ToggleButton>
                        <ToggleButton value={4} style={{ width: '25%' }}>MMS4</ToggleButton>
                    </ToggleButtonGroup>
                </span>


                <div style={{ marginTop: '16px' }}>
                    <div className="date-picker" id="new-date-picker">
                        <DatePicker
                            selected={this.protect_utc()}
                            onChange={(startTime) => this.adjustToUTC(startTime)}
                            showTimeSelect
                            includeDates={this.IncludeDates}
                            timeIntervals={30}
                            showYearDropdown
                            scrollableYearDropdown
                            minDate={new Date('2014-09-01')}
                            maxDate={this.yearRange}
                            timeCaption="Time"
                            timeFormat="HH:mm"
                            dateFormat="yyyy-MM-dd HH:mm" /*{format of text date}*/
                            dateFormatCalendar="MMMM" /*{format of dropdown cal}*/
                        />
                    </div>

                    <p style={{ margin: '8px' }}>
                        <span style={{ marginRight: '1px' }}>
                            Available data spans 2015-09-01 to the
                        </span>
                        <Tooltip
                            title="Set the start date to lastest available Burst data"
                            placement="bottom">
                            <button
                                style={{
                                    fontSize: '12px',
                                    fontFamily: 'helvetica',
                                    border: '1px',
                                    color: '#6699cc',
                                    textTransform: 'none',
                                    background: 'light-green',
                                    transitionDuration: '0.5s',
                                    padding: '2px'
                                }}
                                onClick={(event) => this.showingLocalSendingUTC(this.state.sitl_present)} >
                                present.
                            </button>
                        </Tooltip>
                    </p>
                </div>

                {/* Interval and Burst Events */}
                <div style={{ marginTop: '8px' }}>
                    <Tooltip
                        title="Breadth in time of the plot"
                        placement="left-start">
                        <Select className="duration-selection"
                            style={{
                                width: '30%',
                                fontSize: '13px'
                            }}
                            value={this.state.duration}
                            onChange={(event) => this.setState({ duration: event.target.value })}>
                            <MenuItem value={10}>10 min</MenuItem>
                            <MenuItem value={1}>1 hr</MenuItem>
                            <MenuItem value={2}>2 hrs</MenuItem>
                            <MenuItem value={4}>4 hrs</MenuItem>
                            <MenuItem value={6}>6 hrs</MenuItem>
                        </Select>
                    </Tooltip>

                    <Select className="event-picker"
                        id="event-picker"
                        style={{ width: '60%', float: 'right', fontSize: '13px' }}
                        value={this.state.event}
                        onChange={(event) => this.setState({
                            event: event.target.value,
                            startTime: this.getStartTimeForEvent(event.target.value)
                                || this.state.startTime
                        })}>
                        {eventMenuItems}
                    </Select>
                </div>
                <div style={{ paddingTop: "10px" }}>
                    <TextField className="sitlSugg"
                        id="NEW-MUI-TABLE-search"
                        placeholder="SITL Comment Search"
                        value={this.state.searchQuery}
                        onChange={(ev) => {
                            this.setState({ searchQuery: ev.target.value, label: "" });
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position={'start'}>
                                    <IconButton onClick={(ev) => {
                                        this.triggerSearch(ev)
                                    }}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>)
                        }} />
                    <Select className="sitlSuggMenu"
                        label="suggestions"
                        value={''}
                        onChange={(ev) => {
                            this.setState({ searchQuery: ev.target.value, label: "" });
                        }}>
                        {options.map(option => {
                            return (
                                <MenuItem key={option} value={option} sx={{ fontSize: '.7rem' }}>
                                    {option}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </div>

                <div style={{ display: 'flex', alignContent: 'stretch', alignItems: 'baseline', width: '100%' }}>
                    <div style={{ paddingTop: '10px', flexGrow: '9', flexBasis: '240px', verticalAlign: 'top' }}><h6>Variables</h6></div>
                </div>
                <div className="features" style={{ display: 'flex', alignContent: 'stretch', alignItems: 'baseline', width: '100%', paddingBottom: '10px' }}>
                    <Tooltip
                        title="Use this button to NOT automatically include burst data in the result"
                        placement="top">
                        <span style={{ flexGrow: '1', whiteSpace: 'nowrap' }}>
                            <input id="fast-only-input" type="checkbox"
                                style={{ margin: '0px 3px 0px 0px' }}
                                checked={this.state.fst}
                                onChange={(event) => this.selectFastOnly(event)}
                            />
                            <label htmlFor="fast-only-input" style={{ verticalAlign: 'top' }}>Fast Only</label>
                        </span>
                    </Tooltip>
                    <Tooltip
                        title="Use this button to include Burst Tracker in the result"
                        placement="top">
                        <span style={{ flexGrow: '1', whiteSpace: 'nowrap' }}>
                            <input id="burst-tracker-input" type="checkbox"
                                style={{ margin: '0px 3px 0px 0px' }}
                                checked={this.state.bst}
                                onChange={(event) => this.selectBurstTracker(event)}
                            />
                            <label htmlFor="burst-tracker-input" style={{ verticalAlign: 'top' }}>Include Burst Tracker</label>
                        </span>
                    </Tooltip>
                    <span style={{ flexGrow: '1', whiteSpace: 'nowrap' }}>
                        <input id="switch-input" type="checkbox"
                            style={{ margin: '0px 3px 0px 0px' }}
                            checked={this.state.selectAll}
                            onChange={(event) => this.selectThemAll(event)}
                        />
                        <label htmlFor="switch-input" style={{ verticalAlign: 'top' }} >Select All</label>
                    </span>
                </div>
                <div className="variable-selector" style={{
                    fontSize: '13px',
                    padding: '10px',
                    background: 'linear-gradient(#e9e9e9,white)',
                    border: '1px inset white',
                    borderRadius: '4px',
                    lineHeight: '1em'
                }}>
                    {variableCategoryLists}
                </div>
                <div className="no-var" id="no-var-warn" style={{ visibility: "hidden" }}>Please Select at least 1
                    variable
                </div>
                {/* Generate Button */}
                <div style={{ marginTop: '8px' }}>
                    <Button
                        id="generate-plots-btn"
                        variant="contained"
                        color="primary"
                        onClick={this.onGenerateClick}
                        style={{
                            width: '100%',
                            color: 'black',
                            backgroundColor: '#B6D7A9'
                        }}
                    >
                        Generate Plots
                    </Button>
                </div>
            </div>
        );
    }
}

