import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { HashRouter, Link, Route, Routes } from "react-router-dom";
import { createStore } from "redux";

import ProgressScreen from './ProgressScreen';
import sharedStoreReducer from "./sharedStoreReducer";
import LandingScreen from './LandingScreen';
import ResultsScreen from './ResultsScreen';
//import MuiTable from "./MuiTable";
import SITLSearch from './SITLSearch';
import './App.css';

import nasaWhite from './images/nasa_white.png';
import Constants from './Constants';

import './App.css';
import './ControlForm.css';
import OnboardingSteps from "./OnboardingSteps";
import ResultsOnboardingSteps from "./ResultsOnboardingSteps";
import icon from './images/favicon.ico';

/**
 * Top-level component of the page. Holds a HashRouter that switches between
 * different pages based on the hash (#) location.
 */
export default class App extends Component {

  constructor() {
    super();
    this.sharedStore = createStore(sharedStoreReducer);
    this.plottingStuff = "";
    const favicon = document.getElementById('favicon');
    favicon.setAttribute('href', icon);
  }


  /**
   * Determine how the component is displayed on the page.
   * 
   * @returns component body
   */
  render() {
    // Return component body
    return (
      <HashRouter>
        {/* Header bar */}
        <header>
          <span style={{ float: 'left' }}>
            <img
              src={nasaWhite}
              className="nasa-logo"
              alt="NASA Meatball"
            />
            <Link to="/" className={"mms-title"}>
              <b>MMS</b> <b>D</b>ata <b>V</b>isualizer
            </Link>
          </span>
          <span style={{ float: 'right', display: 'flex', marginright: '10px' }}>
            <OnboardingSteps />
            <ResultsOnboardingSteps />
            <Button
              style={{ color: "white" }}
              onClick={() => { window.location.href = Constants.FEEDBACK_LINK }}>
              Feedback
            </Button>
          </span>
        </header>

        {/* Content */}
        <body>
          <Routes>
            <Route path="/" element={<LandingScreen sharedStore={this.sharedStore} state={this.props.state} />} />
            <Route path="/error" element={<LandingScreen sharedStore={this.sharedStore} state={this.props.state} />} />
            <Route path="/progress/" element={<ProgressScreen sharedStore={this.sharedStore} />} />
            <Route path="/results/" element={<ResultsScreen sharedStore={this.sharedStore} />} />
            {/* bookmark related:
                     two results paths
                     ending slashes on paths */}
            <Route path="/results/:criteria" element={<ResultsScreen sharedStore={this.sharedStore} />} />
            <Route path="/progress/:criteria" element={<ProgressScreen sharedStore={this.sharedStore} />} />
            <Route path="/newsearch" element={<SITLSearch sharedStore={this.sharedStore} />} />
          </Routes>
        </body>
        {/* Footer */}
        <footer>
          <div
            className="footer"
            style={{
              position: 'fixed',
              width: '100%',
              bottom: '0px',
              height: '30px',
              backgroundColor: '#eee',
              paddingTop: '0.2em'
            }}>
            &nbsp; NASA Official:
            <a
              id="nasa-official-link"
              href="mailto:daniel.j.gershman@nasa.gov">
              Daniel Gershman
            </a>
            &nbsp; | &nbsp;
            <a rel="noopener noreferrer" id="foia-link" href={Constants.FOIA_LINK} target="_Blank">
              FOIA
            </a>
            &nbsp; | &nbsp;
            <a rel="noopener noreferrer" id="privacy-link" href={Constants.PRIVACY_LINK} target="_Blank">
              Privacy Notice</a>
          </div>
        </footer>
      </HashRouter >
    );
  }
}
