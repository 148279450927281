/**
 * Constants for the application.
 */
export default Object.freeze({
    // Progess screen poll interval (ms)
    PROGRESS_POLL_INTERVAL: 2000,

    // Minimim width user can resize the sidebar splitter to
    MIN_SIDEBAR_WIDTH: 250,

    // Default start time displays for the control form
    //DEFAULT_START_TIME: '2015-10-16T12:00',
    DEFAULT_START_TIME: '16 Oct 2015 12:00:00 GMT-0400',

    // Email address for feedback
    FEEDBACK_LINK: 'mailto:fpi-feedback@lists.nasa.gov',

    // MMS Paper Link
    MMS_PAPER_LINK: 'https://link.springer.com/article/10.1007/s11214-015-0164-9',

    // FPI Paper Link
    FPI_PAPER_LINK: 'https://link.springer.com/article/10.1007/s11214-016-0245-4',

    // Freedom of information act link
    FOIA_LINK: 'https://www.nasa.gov/FOIA/index.html?_ga=2.107592779.839672224.1539889993-744929377.1499623720',

    SITL_SUGGEST: [
        "Partial MP",
        "Partial MP crossing",
        "FPI Burst Cal",
        "fill",
        "FTE",
        "BL",
        "Bow shock",
        "BS",
        "Magnetosheath CS",
        "Full MP",
        "BBF",
        "SW CS",
        "boundary layer",
        "Full MP crossing",
        "Partial MP crossings",
        "KH CS",
        "MP crossings",
        "MSH CS",
        "bow shock",
        "Solar wind current sheet",
        "BS crossing",
        "Boundary layer",
        "Strong KH waves",
        "5h of pristine SW, turbulence campaign. Delta(B) is around 1nT",
        "pristine solar wind. No energetic ion signature.",
        "PSBL",
        "upstream waves",
        "orphan burst buffer sweep"
    ],

    ERROR_FLAGS: {
        ">25%Cold": "Significant (>25%) cold plasma (<10eV) component",
        "L2pre Mag": "Survey l2pre magnetic field used instead of brst l2pre magnetic field (burst only)",
        "SCpot>20V": "Reported spacecraft potential above 20V",
        "Contact FPI": "manual, contact FPI",
        "Saturation": "Overcounting/saturation effects likely present in skymap",
        "noSCpot": "Invalid/unavailable spacecraft potential",
        ">25%Hot": "Significant (>25%) hot plasma (>30keV) component",
        "High Mach#": "High sonic Mach number (v/vth > 2.5)",
        "Low Dens": "Low calculated density (n < 0.05 cm^-3)",
        "OnBoard Mag": "BentPipe magnetic field used instead of brst l2pre magnetic field",
        "PhotoElectrons": "No internally generated photoelectron correction applied (DES only)",
        "Compression": "Compression pipeline error",
        "Spintones": "spintone calculation error (DBCS only)",
        "Radiation": "significant (>=20%) penetrating radiation (DIS only)"
    },
    // NASA Privacy link
    PRIVACY_LINK: 'https://www.nasa.gov/about/highlights/HP_Privacy.html',

    // NASA GSFC MMS link
    GSFC_MMS: 'https://mms.gsfc.nasa.gov',

    //old mms_cmad...these change: 'https://lasp.colorado.edu/mms/sdc/public/CMAD%20-%20MMS%20-%2022March2023.pdf'
    MMS_CMAD:  'https://lasp.colorado.edu/mms/sdc/public/MMS%20CMAD%20v9Sept2024.pdf'


});
