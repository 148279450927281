import React, { useState } from 'react';
import ReactJoyride from 'react-joyride';
import './ControlForm.css';
import Button from '@mui/material/Button';

const OnboardingSteps = () => {
  const [joyride, setJoyride] = useState({
    run: false,
    steps: [
        {
            title: "Spacecraft Selector",
            target: ".sc-selector",
            content: "Plot any of the four spacecraft",
            disableBeacon: true, // so things start off right away
        },
        {
            title: "Date Picker",
            target: ".date-picker",
            content: "Select time period of your FPI data plot. If dates are grayed-out, this means that data is not available for that day.",
            disableBeacon: true, // so things start off right away
        },
        {
            title: "Plot duration times",
            target: ".duration-selection",
            placement: "auto",
            content: "Although bursts can be short, we provide plot time ranges of 10 min, 1,2,4 and 6 hours. The plot is interactive so you can zoom in later.",
            disableBeacon: true, // so things start off right away
        },
        {
            title: "Event Picker",
            target: ".event-picker",
            placement: "auto",
            content: "Noted Time Periods suggested by the Science Communty.",
            disableBeacon: true, // so things start off right away
        },
        {
            title: "SITL Comment Search",
            target: ".sitlSugg",
            placement: "auto",
            content: <div>To search for Scientist-In-The-Loop (SITL) comments on burst segments.</div>,
            disableBeacon: true, // so things start off right away
        },
        {
            title: "SITL Comment Search",
            target: ".sitlSuggMenu",
            placement: "auto",
            content: <div> Drop-down list of commonly used search suggestions</div>,
            disableBeacon: true, // so things start off right away
        },
      {
        title: "Variable Features",
        target: ".features",
        content: <div><p>Fast Only - no Burst data.</p>
                  <p>An indicator along the top of the graph to highlight burst data periods.</p>
                  Select All or None of the variables below.
                 </div>,
        disableBeacon: true, // so things start off right away

      },
      {
        title: "Variable Picker",
        target: ".variable-selector",
        content: <div><p>Variables can have their order changed by selecting one and dragging it to the new desired location.</p><br/>
            <p/>The new variable order will be reflected in the plot stack once the plots are re-generated (by selecting the Generate Plots button below)</div>,
        disableBeacon: true, // so things start off right away
      },
    ]
  });

  const handleJoyrideCallback = (data) => {
    const { action } = data;
    if(action === 'close'){
      setJoyride({ ...joyride, run: !joyride.run });
    }
  };

  return (<React.Fragment>
      <Button
        id="onboarding-1"
        onClick={() => { setJoyride({ ...joyride, run: !joyride.run }); }}
        style={{color: 'white',
                paddingRight: '20px'}}>
        TAKE TOUR
      </Button>
    {/* </span> */}
    <ReactJoyride
      steps={joyride.steps}
      run={joyride.run}
      callback={handleJoyrideCallback}
      showSkipButton
      continuous
      disableScrolling
      locale={{
        skip: "Exit",
        last:"Done"
        }} 
       styles={{
            options: {
                arrowColor: 'white',
                backgroundColor: '#ffffff',
                overlayColor: 'rgba(0, 15, 39, 0.35)' ,
                primaryColor: '#163B76',
                textColor: '#000000',
                textSize: '8px',
                width: 300,
                zIndex: 1000
            },
            tooltipContainer: {
              lineHeight: 1.4,
            },
            tooltipTitle: {
               fontSize: 18,
               margin: 0,
                color: 'black',
               textAlign: 'center',
            },
            tooltipContent: {
               color: '#626A85',
               fontSize: 14,
                textAlign: 'left',
               padding: '20px 10px',
            },
           tooltipFooter: {
             alignItems: 'center',
             display: 'flex',
             justifyContent: 'flex-end'
           },
           tooltipFooterSpacer: {
             flex: 1,
           },
           buttonClose: {
            display: 'none',
          },
           buttonNext: {
             backgroundColor: "#163B76",
             borderRadius: 4,
             fontSize: 16,
             color: 'white',
           },
           buttonSkip: {
             color:  '#626A85',
             fontSize: 14,
               text: "exit"
           },
           buttonBack: {
             color: '#626A85',
             marginLeft: 'auto',
             marginRight: 15,
    },
        }}
                />
         </React.Fragment>);
       };
  
       export default OnboardingSteps


