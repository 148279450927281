import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from 'react';
import { ControlFormVariables, ControlFormDateTime } from "./NewControlForm";
import moment from "moment";
import Constants from './Constants';
import axios from "axios";
import './ResultsScreen.css';
import Grid from '@mui/material/Grid';

import { calculate_zone } from "./SITLSearch";
import Utils from "./Utils";
import Distributions from "./Distributions";
//New code
import { Drawer } from '@mui/material';
import { Button } from "@mui/base";
import { SettingsPowerRounded } from '@mui/icons-material';

//move progress screen to here
import ProgressScreen from "./ProgressScreen";

function ResultsScreen(props) {
    const [loadingData, setLoadingData] = useState(false);
    const [plotlyInnerElement, setPlotlyInnerElement] = useState('');
    const [fisrtLoadIn, setFisrtLoadIn] = useState(true);
    const [hasData, setHasData] = useState(false);
    // use this in place of <center> Plots rendering...<br /> <CircularProgress /></center> since you can use import when changing .innerhtml
    const plotRenderHtml = `<center> Plots rendering...<br> 
    <span class="MuiCircularProgress-root MuiCircularProgress-indeterminate MuiCircularProgress-colorPrimary css-z01bqi" role="progressbar" style="width: 40px; height: 40px;">
    <svg class="MuiCircularProgress-svg css-13o7eu2" viewBox="22 22 44 44"><circle class="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate css-14891ef" cx="44" cy="44" r="20.2" fill="none" stroke-width="3.6">
    </circle></svg></span></center>`

    const navigate = useNavigate();
    const location = useLocation();
    location.state = chooseCriteria(props, true);

    const [criteria, setCriteria] = useState(chooseCriteria(props));
    function chooseCriteria(props, setlocal) {
        if (!setlocal) {
            if (location.state) { // I need this for bookmark case or else it fails here
                if (location.state.criteria) {
                    if (location.state.startTime) {
                        let temp = JSON.parse(location.state.criteria);
                        temp.startTime = new Date(location.state.startTime)
                        return temp
                    }
                    return JSON.parse(location.state.criteria);
                }
            }
        }
        // used to allow initial load of page
        return {
            arrayorder: {
                'Single-Spacecraft': ["M0", "01", "02", "03", "04", "05", "06", "07", "08", "09", "0A", "0B", "0K", "0L", "0C", "0D", "0E", "0F", "0G", "0H", "0I"],
                'Multi-Spacecraft': ["10", "11", "12", "13", "14", "15"]
            },
            bst: 1, context: "landingpage", duration: 2, endTime: "none", event: "none",
            fst: 0, gsm: 1, initialSearchQuery: "", present: "Wed Aug 16 2023 17:00:00 GMT-0700 (Pacific Daylight Time)",
            searchData: "none", searchQuery: "", searchbox_label: "SITL Comment:", selectAll: false,
            sitl_present: "2023-08-14T18:00:00.000Z", spacecraft: 1, startTime: "2015-10-16T12:00:00.000Z",
            units: "hours", variables: ['00', 'M0', '01', '02', '03', '04', '05', '06', '07'], zd: 0, selectAllSing: false, selectAllMult: false
        }
    }

    const [varForm, setVarForm] = useState(false);
    const [dateForm, setDateForm] = useState(false);

    const setVarFormToTrue = () => {
        setVarForm(true)
        setDateForm(false)
    }

    const setDateFormToTrue = () => {
        setDateForm(true)
        setVarForm(false)
    }

    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setVarForm(false);
        setDateForm(false);
    };

    const [currentStatus, setCurrentStatus] = useState("");
    const [dist, setDist] = useState(props.sharedStore.getState().dist);
    const [inc, setInc] = useState("next");
    const [previousBurst, setPreviousBurst] = useState(null);
    const [line_position, setLinePos] = useState("");

    const [progressPercent, setPercent] = useState(0);
    const [redirect, setRedirectStatus] = useState(null);
    const [results, setResults] = useState(props.sharedStore.getState().results);
    const [status, setProgStatus] = useState("Processing");
    //const [species, setSpecies] = useState("ions");
    //const [time_label, setTimeLabel] = useState(".03 secs");
    const [which_species, setSpecies] = useState({
        species: "ions",
        time_delta: ".75 secs",
        currzoom_placeholder: "1500"
    });


    const [firstDistLoad, setfirstDistLoad] = useState(true);

    const [searchbox_label, set_searchbox_label] = useState("SITL Comment:");
    const [workflowId, setWorkflowId] = useState(props.sharedStore.getState().workflowId);
    const [image_w, setImage_w] = useState(250);
    const [image_min, setImage_min] = useState(0);
    const [image_max, setImage_max] = useState(255);
    const [image_ext, setImage_ext] = useState(1);
    const [image_offset, setImage_offset] = useState(1);
    const [goToContact, setGoToContact] = React.useState({
        state: false,
        transferInfo: "no json yet"
    });

    const [mytable, setTable] = useState({
        response: null,
        rDist: null,
        redirect: null,
        order: 'asc',
        sharedStore: props.sharedStore,
        selected: [],
        page: 0,
        rowsPerPage: 25,
        counter: 0,
        data: null,
        min: 0,
        max: 255,
        image_offset: 20,
        mid_time: null

    });

    const [goToBookmark, setGoToBookmark] = React.useState({
        state: false,
        criteria: decodeURI(window.location.href).slice(window.location.href.search("{"))
    });
    const [goToSearch, setGoToSearch] = React.useState({
        state: false,
        transferInfo: "no json yet",
        crit: "not sure yet"
    });



    //  const [orgTitle, setOrgTitle] = useState(find_GSM_subtitle);
    const [orgTitle, setOrgTitle] = useState(find_GSM_subtitle);
    const [searchStuff, setSearchQuery] = useState(setupSearchQuery);
    const handleDist4 = useRef(null);
    const dist_resize = useRef(null);

    let plotlyOptions = specifyIcons();

    // here are the ones we only want to set, save once:
    useEffect(() => {
        find_GSM_subtitle();
    }, []);

    // used when progressScreen.js is finished loading what it needs for plot
    useEffect(() => {
        if (!loadingData) {
            setDist(props.sharedStore.getState().dist);
            setResults(props.sharedStore.getState().results);
            // componentDidMount(results, dist);
        }
        // Dont know what this part is
        if (document.getElementById("onboarding-2") != null) {
            document.getElementById("onboarding-2").style.visibility = "visible";
            document.getElementById("onboarding-2").style.display = "block";
            document.getElementById("onboarding-1").style.visibility = "hidden";
            document.getElementById("onboarding-1").style.display = "none";
        }
        //window.scrollTo(0, 0);
    }, [loadingData]);

    useEffect(() => { //needed var for plot
        setResults(props.sharedStore.getState().results);
    }, [dist]);

    useEffect(() => { //needed var for plot
        componentDidMount(results, dist);
    }, [results]);

    // useEffect(() => { // loading spinning wheel between progress screen being done and plot rendering
    //     if(plotlyInnerElement === '') {
    //         const plotlyResults = document.getElementById('plotly-results');
    //         plotlyResults.innerHTML = plotRenderHtml;
    //     }
    // }, [plotlyInnerElement]);

    useEffect(() => {
        toBookmarkClick();
    }, []);

    function setState(re) {
        setRedirectStatus(re);
    }
    function firstQuery(initialSearchQuery) {

        axios.get('api/search_results?q=' + initialSearchQuery)
            .then((response) => {
                const rows = response.data.brstsegs;
                var firstdata = [];
                for (var i = 0; i < rows.length; ++i) {
                    firstdata.push(createData(rows[i].comment, rows[i].first_buffer, rows[i].fom));
                }
                setTable({
                    ...mytable, response: response,
                    data: firstdata,
                    counter: rows
                });
            });
    };
    function createData(comment, time, fom) {
        mytable.counter += 1;
        return { id: mytable.counter, comment, time, fom };
    };

    const toBookmarkClick = () => {  // why is ths illegal?
        if (!location.state) {
            setGoToBookmark({
                ...goToBookmark, state: true
            });
        }
    };

    function setupSearchQuery() {
        if (props.sharedStore) {
            let aviation = props.sharedStore.getState().searchQuery;

            return aviation;
        }
        else {
            return "";
        }
    }

    function react_to_cursor_change(e) {
        console.log("reacting to cursor change", e);
        const tgt = e.target;
        const inline = tgt.style.cursor || "Not defined"
        const computed = window.getComputedStyle(tgt)["cursor"]
        console.log("Inline: ", inline, "Computed: ", computed)
    }
    /* through this name mimicking class component */
    /* The reason that the plotly graph stuff is all in this componentDidMount function
       is that the process starts in ProgressScreen.js. As soon as the tick() finds that
       it has the plot, it redirects to here, ResultScreen and then useEffect() calls this guy

       This actually gets called AFTER Distributions.js's componentDidMount...
     */
    function componentDidMount(results, dist) {
        if (!location.state || Utils.areWeTestingWithJest() || fisrtLoadIn) {
            return;
        }
        // Under a set timeout so the loading screen displays before
        // Plotly rendering starts.
        setTimeout(() => {
            // Clear results div
            const plotlyResults = document.getElementById('plotly-results');
            plotlyResults.addEventListener("pointercancel", react_to_cursor_change);
            const distributionsResults = document.getElementById('distributions-results');
            if (plotlyResults !== null) {
                plotlyResults.innerHTML = '';
            }
            // document.getElementById("ion-sel").checked = true;
            // document.getElementById("browse-sel").checked = true;

            // Render reults in the plotly div.
            if (Plotly && results !== null && !loadingData) {
                // hover data was too much for the system so now we are doing it on the client side
                if (results.traces) {
                    for (var i = 0; i < results.traces.length; ++i) {
                        if (results.traces[i].type === 'heatmap') {
                            if (!results.traces[i].customdata[0][0].includes("cm")) {
                                if (results.traces[i].showbins) {
                                    var f = addCustomDataWithBins(results.traces[i].customdata,
                                        results.traces[i].x,
                                        results.traces[i].y)
                                } else {
                                    f = addCustomData(results.traces[i].customdata,
                                        results.traces[i].x,
                                        results.traces[i].y)
                                }
                                results.traces[i].customdata = f;
                            }
                        } else if (results.traces[i].type === 'markers+lines') {
                            if (results.traces[i].hovertemplate === '%{customdata}') {
                                //This is speific to burst segements.
                                //Ashwin Added: called page load to get all burst ranges stored.
                                var b = find_range_of_burst(results.traces[i]);
                                results.traces[i].burstStartStop = b;
                            }
                        }
                    }
                    // plotlyOptions = specifyIcons();
                } else {
                    // plotlyOptions = deSpecifyIcons();
                }

                //Ashwin Added: This is important  this is what renders the plots on the DOM
                //'ele-dr', and 'ion-dr'
                if (dist != null && firstDistLoad) {
                    try {
                        if (dist.electron) {//example dist
                            dist_resize.current(dist.electron.layout, dist.electron.data[0], 1);
                            Plotly.newPlot('ele-dr0', {
                                data: dist.electron.data, layout: dist.electron.layout
                            });
                            // if (dist.hasOwnProperty(dist)) {
                            //     if (dist.dist.electron[0]) { // already displayed distribution plots
                            //         //(dist.dist.electron.layout,dist.dist.electron.data[0],1);
                            //         dist_resize.current(dist.dist.electron[0].layout, dist.dist.electron[0].data[0], 0);
                            //         dist_resize.current(dist.dist.ion[0].layout, dist.dist.ion[0].data[0], 0);
                            //         dist_resize.current(dist.dist.slice3[0].layout, dist.dist.slice3[0].data[0], 0);

                            //         Plotly.newPlot('ele-dr0', {
                            //             data: dist.dist.electron[0].data, layout: dist.dist.electron[0].layout
                            //         });

                            //         Plotly.newPlot('ion-dr0', {
                            //             data: dist.dist.ion[0].data, layout: dist.dist.ion[0].layout,
                            //         });
                            //         Plotly.newPlot('sl3-dr0', {
                            //             data: dist.dist.slice3[0].data, layout: dist.dist.slice3[0].layout
                            //         });
                            //         document.getElementById("dist-button").style.visibility = "visible";
                            //         document.getElementById("dist-button").style.display = "inline-block";
                            //     }
                            // }
                        }
                        setPlotlyInnerElement('');

                    } catch (error) {
                        console.error(error);
                    }
                } else {
                    console.log("no burst for this period, try another");
                }


                Plotly.newPlot(
                    'plotly-results',
                    results.traces,
                    results.layout,
                    plotlyOptions
                );

                plotlyResults.on('plotly_click', eventData => {
                    if (eventData.points) {
                        if (eventData.hasOwnProperty('points')) {
                            onPlotlyClick(eventData);
                        }
                    }
                });
                plotlyResults.on('plotly_hover', eventData => {
                    if (eventData.points) {
                        if (eventData.hasOwnProperty('points')) {
                            onPlotlyHover(eventData);
                        }
                    }
                });



                plotlyResults.on('plotly_relayout', eventData => {
                    var gd = document.getElementById('plotly-results');
                    const modifiedLayout = Object.assign({}, results.layout);
                    const modifiedTraces = Object.assign({}, results.traces);

                    // the new drawlineshape
                    if (eventData.hasOwnProperty('drawmode')) {
                        for (const key in eventData) {
                            const value = eventData[key];
                            if (key == "dragmode") {
                                if (value == "drawline") {
                                    return;
                                }
                            }
                        }
                    }
                    if (eventData.hasOwnProperty('shapes')) {
                        for (const key in eventData) {
                            const value = eventData[key];
                            if (key == "shapes") {
                                if (eventData.hasOwnProperty('shapes[0].x0') ||
                                    eventData.hasOwnProperty('shapes')) {
                                    onPlotlyDrawLine(eventData,);

                                }
                            }
                        }

                        // draw a zoom in rectangle
                    } else if (eventData.hasOwnProperty('xaxis.range[0]')) {
                        onPlotlyZoom(eventData, modifiedTraces, modifiedLayout, gd);

                        // update title on zoom out. no xrange so this is AutoScale event
                        // - return to default axis etc... (house icon)
                    } else {
                        let just_advancing_slider = props.sharedStore.getState().slider_condition;

                        for (const key in eventData) {
                            const value = eventData[key];
                            if (key.includes('.autorange')) {
                                if (modifiedLayout.xaxis.autorange) {
                                    resetTitle(modifiedLayout);
                                    resetArrayModeForXaxisLabels(modifiedLayout);
                                }
                                Plotly.react('plotly-results', results.traces, modifiedLayout, plotlyOptions);
                                break;
                            }
                            if (value == "drawline") {
                                return;
                            }
                            if (key.includes('shapes[0].x0')) {
                                setLinePos(eventData[key]);
                                props.sharedStore.dispatch({ type: 'SET_LINEPOS', value: eventData[key] });
                                props.sharedStore.dispatch({ type: 'SET_DIST_BUTTON', value: "slider-moved" });
                                document.getElementById("slider-button").disabled = false;
                                if (just_advancing_slider != "auto") {
                                    document.getElementById("slider-dist-button").style.visibility = "visible";
                                    document.getElementById("slider-dist-button").style.display = "inline-block";
                                    document.getElementById("burst-instructions").style.visibility = "hidden";
                                    document.getElementById("burst-instructions").style.display = "none";
                                    document.getElementById("progress0").style.visibility = "hidden";
                                    document.getElementById("progress0").style.display = "none";
                                }
                                document.getElementById("next-set").style.visibility = "hidden";
                                document.getElementById("next-set").style.display = "none";
                                document.getElementById("prev-set").style.visibility = "hidden";
                                document.getElementById("prev-set").style.display = "none";
                            }

                        }
                    }
                    // relatively indendent
                    if (eventData.hasOwnProperty('xaxis.autorange')) {
                        criteria.endTime = "none";
                    }
                    props.sharedStore.dispatch({
                        type: 'SET_SLIDER_COND', value: "normal"
                    });

                });
            } else {
                if (loadingData) {
                    // doesnt really ever get here
                    plotlyResults.innerHTML = '';
                } else if (results === null) {
                    // this should only happen on first load of page.
                    plotlyResults.innerHTML = '';
                } else {
                    plotlyResults.innerHTML = '<div id="plotly-error">An error occurred loading Plotly</div>';
                }
            }
        }, 100); // setTimeOut

    } // onComponentDidMount

    const onPlotlyDrawLine = (eventData, modifiedTraces, modifiedLayout) => {
        // Pick xaxis.range that deviates from the others-- this is the one
        // the user selected from. Picks less frequent.
        const rangeStartCounter = new Map();
        const rangeEndCounter = new Map();
        for (const key in eventData) {
            const value = eventData[key];

            const counter = key.includes('.range[0]')
                ? rangeStartCounter
                : rangeEndCounter;

            if (!counter.has(value)) {
                counter.set(value, 0);
            }
            if (key.includes("yaxis")) {
                if (key.includes("range[0]")) {
                    var axis = Number(key.slice(5, 6));
                    var axis_name = 'y' + key.slice(5, 6);
                }
            }
            if (key.includes("x0")) {
                setLinePos(eventData[key])
                props.sharedStore.dispatch({ type: 'SET_LINEPOS', value: eventData[key] });
                props.sharedStore.dispatch({ type: 'SET_DIST_BUTTON', value: 'slider-dist-button' });
                document.getElementById("slider-dist-button").style.visibility = "visible";
                document.getElementById("slider-dist-button").style.display = "inline-block";
                document.getElementById("burst-instructions").style.visibility = "hidden";
                document.getElementById("burst-instructions").style.display = "none";
                document.getElementById("next-set").style.visibility = "hidden";
                document.getElementById("next-set").style.display = "none";
                document.getElementById("prev-set").style.visibility = "hidden";
                document.getElementById("prev-set").style.display = "none";
            }
            if (key.includes("shapes")) {
                //modifiedTraces[2].shape = eventData[key][0];
                setLinePos(eventData.shapes[0].x0)
                props.sharedStore.dispatch({ type: 'SET_LINEPOS', value: eventData[key] });
                props.sharedStore.dispatch({ type: 'SET_DIST_BUTTON', value: 'slider-dist-button' });
                document.getElementById("slider-dist-button").style.visibility = "visible";
                document.getElementById("slider-dist-button").style.display = "inline-block";
                document.getElementById("burst-instructions").style.visibility = "hidden";
                document.getElementById("burst-instructions").style.display = "none";
                document.getElementById("next-set").style.visibility = "hidden";
                document.getElementById("next-set").style.display = "none";
                document.getElementById("prev-set").style.visibility = "hidden";
                document.getElementById("prev-set").style.display = "none";
            }


            counter.set(value, counter.get(value) + 1);
        }

        // Plotly.react( 'plotly-results', modifiedTraces, modifiedLayout, plotlyOptions );
        //Plotly.relayout(gd, modifiedTraces, modifiedLayout, plotlyOptions);
    }

    /**
     * Have Title time respond to onPlotlyZoom
     */
    function displayNewHeaderTime(range) {
        if (range) {
            const n = range.lastIndexOf(".")
            if (n > 0) {
                var newFormat = range.substring(0, n);
                newFormat = newFormat.replace(" ", "T") + "Z";
            } else {
                //already in correct format
                return range;
            }
        } else {
            // very last resort:
            newFormat = criteria.startTime.format();
        }
        return newFormat

    }

    /*
    Hover handler to control, first off: hovermode
     */
    function onPlotlyHover(eventData) {
        var gd = document.getElementById('plotly-results');
        var change = false;
        for (const key in eventData) {
            const value = eventData[key];
            if (key.includes('points')) {
                var modifiedLayout = Object.assign({}, results.layout);
                var currentHoverMode = modifiedLayout.hovermode;
                var currentDataType = value[0].data.type;
                if (currentDataType == 'scatter' &&
                    currentHoverMode == 'x') {
                    modifiedLayout.hovermode = 'x unified'
                    change = true;
                } else if (currentDataType != 'scatter' &&
                    currentHoverMode == 'x unified') {
                    modifiedLayout.hovermode = 'x'
                    change = true;
                } else {
                    return
                }
                if (change) {
                    results.layout = modifiedLayout;
                    Plotly.react('plotly-results', results.traces, modifiedLayout, plotlyOptions);
                    return;
                }
            }
        }
    }

    function resize(layout, data, setForExample) {
        var localSpecies = getSpecies();
        if (localSpecies == "ions") {
            var pzoom = 1500
            var psize = 350
            const tdl = document.getElementById("time_label");
            tdl.innerHTML = ".15 secs"
            const czp = document.getElementById("resize-value-zoom");
            czp.innerHTML = "1500"
        } else {
            var pzoom = 9000
            var psize = 350
            const tdl = document.getElementById("time_label");
            tdl.innerHTML = ".03 secs"
            const czp = document.getElementById("resize-value-zoom");
            czp.innerHTML = "12000"
        }
        var user_values_zoom = document.getElementById("resize-value-zoom").value;
        var user_values_size = document.getElementById("resize-value-size").value;
        if (user_values_zoom || user_values_size) { // 100
            // Both ION and ELECTRON image sizes can only be so small...
            if (user_values_size < 230) {
                user_values_size = 350
                document.getElementById('resize-value-size').value = user_values_size;
                document.getElementById('resize-value-zoom').value = user_values_zoom
            }
            // ION can zoom in much closer since it is 10 times slower
            if (localSpecies == "ions") {
                if (user_values_zoom < 200) {
                    user_values_zoom = 200
                    document.getElementById('resize-value-size').value = user_values_size;
                    document.getElementById('resize-value-zoom').value = user_values_zoom
                }
            } else {
                if (user_values_zoom < 2000) {
                    user_values_zoom = 3000
                    document.getElementById('resize-value-size').value = user_values_size;
                    document.getElementById('resize-value-zoom').value = user_values_zoom
                }
            }

            psize = parseInt(user_values_size);
            pzoom = parseInt(user_values_zoom);
        }
        layout.height = psize;
        layout.width = psize;
        layout.xaxis.range = [-pzoom, pzoom];
        layout.yaxis.range = [-pzoom, pzoom];
        var adjust = Math.floor(psize) / 50
        adjust = adjust + 1
        if (pzoom >= 350) {
            adjust = Math.floor(psize / 35)
        }

        if (setForExample == 1) {
            layout.title.text = "Example Burst Distribution";
        }
        layout.title.font.size = adjust;
        layout.xaxis.tickfont.size = adjust;
        layout.yaxis.tickfont.size = adjust;
        layout.xaxis.title.font.size = adjust;
        layout.yaxis.title.font.size = adjust;
        data.colorbar.tickfont.size = adjust;

    }

    function getSpecies() {
        if (props.sharedStore.getState().species != null) {
            return props.sharedStore.getState().species;
        } else {
            if (document.getElementById("ion-sel") != null) {
                if (document.getElementById("ion-sel").checked) {
                    setSpecies({ ...which_species, species: "ions" });
                    return "ions";
                } else if (document.getElementById("electron-sel").checked) {
                    setSpecies({ ...which_species, species: "electron" });
                    return "electron";
                } else {
                    return "ions";
                }
            } else {
                return "ions";
            }
        }
    }


    function tooMuchData(cause) {
        return {
            "layout": {
                "xaxis": {
                    "visible": false
                },
                "yaxis": {
                    "visible": false
                },
                "annotations": [
                    {
                        "text": "There is too much data to process based on the selected criteria (" + cause + ")",
                        "xref": "paper",
                        "yref": "paper",
                        "showarrow": false,
                        "font": {
                            "size": 28
                        }
                    }
                ]
            }
        }
    }

    function ratio_colorbar_labels(modifiedTraces, ith, timeMin, timeMax, zoomRanges) {
        var n = modifiedTraces[ith].colorbar.tickvals.length
        if (timeMax == timeMin) { // return to default axis event
            let xs = ["10<sup>" + modifiedTraces[ith].colorbar.tickvals[0] + "</sup>"];
            for (let i = 1; i < n; ++i) {
                xs.push("10<sup>" + modifiedTraces[ith].colorbar.tickvals[i] + "</sup>");
            }
            modifiedTraces[ith].colorbar.ticktext = xs;
            return modifiedTraces;
        }
        var zRangeMin = zoomRanges[0];
        var zRangeMax = zoomRanges[1];
        var total_zRangeMin = zoomRanges[2];
        var total_zRangeMax = zoomRanges[3];

        var startcolor = Math.ceil((zRangeMin - total_zRangeMin) / (total_zRangeMax - total_zRangeMin) * n) + 1;
        var endcolor = Math.ceil((zRangeMax - total_zRangeMin) / (total_zRangeMax - total_zRangeMin) * n) + 1;
        var delta = Math.ceil((endcolor - startcolor) / n);

        let xs = [" "];
        for (let i = 0; i < n; ++i) {
            xs.push(" ")
        }
        modifiedTraces[ith].colorbar.ticktext = xs;
        // just 3
        xs[0] = "10<sup>" + (startcolor - 1).toString() + "</sup>";
        if (endcolor - 1 > startcolor) {
            if (n / 2.0 == 0) {
                xs[Math.floor((n) / 2)] = "10<sup>" + (Math.trunc((startcolor + endcolor) / 2)).toString() + "</sup>";
            } else {
                xs[Math.floor((n) / 2)] = "10<sup>" + ((startcolor + endcolor) / 2).toString() + "</sup>";
            }
        }
        xs[n - 1] = "10<sup>" + endcolor + "</sup>";
        modifiedTraces[ith].colorbar.ticktext = xs;
    }

    function arrayMax(arr) {
        return arr.reduce(function (p, v) {
            return (p > v ? p : v);
        });
    }

    function arrayMin(arr) {
        return arr.reduce(function (p, v) {
            return (p < v ? p : v);
        });
    }

    function arrayReduce(arr) {
        let x = arr.length;
        var out = [];
        let nzeros = 0;
        for (let i = 0; i < x; ++i) {
            let y = arr[i].length;
            for (let j = 0; j < y; ++j) {
                // trying to avoid arrayMin later returning 0 values
                if (arr[i][j] != 0 || nzeros > 10) {
                    out.push(arr[i][j]);
                    if (arr[i][j] == 0) {
                    }
                } else {
                    nzeros += 1;
                }
            }
        }
        return out;
    }

    function get_zoom_ranges(modifiedTraces, ith, eventData, rangeStart, rangeEnd,
        y_rangeStart, y_rangeEnd, ytotal_rangeStart, ytotal_rangeEnd, axis) {

        var time_len = modifiedTraces[ith].x.length;
        // get x range to see which z columns to search
        var cont = 0;
        var z_0 = 0;
        // index of x direction
        for (let i = 0; i < time_len; ++i) {
            if ((new Date(modifiedTraces[ith].x[i]) - new Date(rangeStart)) > 0) {
                z_0 = i;
                cont = i
                break;
            }
        }
        var z_1 = time_len - 1;
        for (let ii = cont; ii < time_len; ++ii) {
            if ((new Date(modifiedTraces[ith].x[ii]) - new Date(rangeEnd)) > 0) {
                z_1 = ii;
                break;
            }
        }
        // estimate the y/j index for looking for z values
        var y_0 = Math.trunc((y_rangeStart - ytotal_rangeStart) / (ytotal_rangeEnd - ytotal_rangeStart) * 32);
        var y_1 = Math.round((y_rangeEnd - ytotal_rangeStart) / (ytotal_rangeEnd - ytotal_rangeStart) * 32);
        if (y_1 > 32) y_1 = 32;

        var numberArray = [];
        let a = []
        for (let j = y_0; j < y_1; ++j) {
            a.push(modifiedTraces[ith].z[j].slice(z_0, z_1).map(Number));
        }
        numberArray = a.filter(function (value) {
            return (!Number.isNaN(value));
        });
        //let singleArray = numberArray.reduce(function (p, c) { return p.concat(c); });
        let singleArray = arrayReduce(numberArray);
        console.log("arrayMin check:", singleArray.length);
        var zRangeMin = arrayMin(singleArray);
        var zRangeMax = arrayMax(singleArray);
        var numberArray = [];

        // just get absolute range of z
        var t = [];
        for (let j = 0; j < 32; ++j) {
            t.push(modifiedTraces[ith].z[j].map(Number));
        }
        singleArray = t.reduce(function (p, c) {
            return p.concat(c);
        });
        numberArray = singleArray.filter(function (value) {
            return !Number.isNaN(value);
        });
        var total_zRangeMin = arrayMin(numberArray)
        var total_zRangeMax = arrayMax(numberArray)
        console.log("yRanges", y_rangeStart, y_rangeEnd, ytotal_rangeStart, ytotal_rangeEnd);
        console.log("zRanges", zRangeMin, zRangeMax, total_zRangeMin, total_zRangeMax);
        console.log("y_0,y_1", y_0, y_1);

        return [zRangeMin, zRangeMax, total_zRangeMin, total_zRangeMax];

    }

    const adjust_colorbar = (modifiedTraces, ith, rangeStart, rangeEnd, y_rangeStart, y_rangeEnd,
        ytotal_rangeStart, ytotal_rangeEnd, zoomRanges) => {
        if (rangeStart == 0 && rangeEnd == 0) {
            // restore axis event
            var cscale = [ //9
                ['0', 'rgb(85,82,156)'],
                ['.05', 'rgb(72,103,171)'],
                ['.25', 'rgb(114,199,236)'],
                ['.50', 'rgb(133,188,128)'],
                ['.60', 'rgb(124,186,86)'],
                ['.75', 'rgb(181,205,84)'],
                ['.85', 'rgb(251,235,83)'],
                ['.95', 'rgb(233,154,59)'],
                ['1', 'rgb(224,70,50)']
            ]
            modifiedTraces[ith].colorscale = cscale;
            return modifiedTraces;
        }
        var time_len = modifiedTraces[ith].x.length;
        var zRangeMin = zoomRanges[0];
        var zRangeMax = zoomRanges[1];
        var total_zRangeMin = zoomRanges[2];
        var total_zRangeMax = zoomRanges[3];



        var yrange = [1, 4.5]
        var y_ratio = 8;
        if (false) {
            var cscale = [ //9
                ['0', 'rgb(85,82,156)'],
                ['.05', 'rgb(72,103,171)'],
                ['.25', 'rgb(114,199,236)'],
                ['.50', 'rgb(133,188,128)'],
                ['.60', 'rgb(124,186,86)'],
                ['.75', 'rgb(181,205,84)'],
                ['.85', 'rgb(251,235,83)'],
                ['.95', 'rgb(233,154,59)'],
                ['1', 'rgb(224,70,50)']
            ]
            // 1    .125  3
            // 2    .25   6
            // 3    .375  9
            // 4    .5    12
            // 5    .625  15
            // 6    .75   18
            // 7    .875  21
        } else {
            var cscale = [ // 25
                ['0', 'rgb(85,82,156)'], //0
                ['0', 'rgb(81,89,161)'],
                ['0', 'rgb(76,96,166)'],

                ['.05', 'rgb(72,103,171)'],
                ['.05', 'rgb(84,136,191)'],
                ['.05', 'rgb(96,170,211)'], //5

                ['.25', 'rgb(114,199,236)'],
                ['.25', 'rgb(121,195,286)'],
                ['.25', 'rgb(128,191,250)'],

                ['.50', 'rgb(133,188,128)'],
                ['.50', 'rgb(130,188,116)'],//10
                ['.50', 'rgb(128,187,102)'],

                ['.60', 'rgb(124,186,86)'],
                ['.60', 'rgb(144,192,86)'],
                ['.60', 'rgb(164,198,85)'],

                ['.75', 'rgb(181,205,84)'],//15
                ['.75', 'rgb(203,210,84)'],
                ['.75', 'rgb(220,215,83)'],

                ['.85', 'rgb(251,235,83)'],
                ['.85', 'rgb(245,199,73)'],
                ['.85', 'rgb(239,160,63)'], //20

                ['.95', 'rgb(235,134,59)'],
                ['.95', 'rgb(230,109,46)'],
                ['.95', 'rgb(230,80,53)'],
                ['1', 'rgb(224,70,50)']
            ]
        }
        var gauge = ['0', '.05', '.25', '.50', '.60', '.75', '.85', '.95', '1'];
        var startcolor = Math.trunc((zRangeMin - total_zRangeMin) / (total_zRangeMax - total_zRangeMin) * 25);
        if (startcolor > 10) {
            startcolor = startcolor - 4;
        }
        var endcolor = Math.ceil((zRangeMax - total_zRangeMin) / (total_zRangeMax - total_zRangeMin) * 25);
        var delta = (endcolor - startcolor) / 9.;
        for (let i = 0; i < 9; ++i) {
            let jump = startcolor + i * delta + 1;
            if (i < 5) {
                jump = startcolor + i * delta;
            }
            if (jump > 24) jump = 24;
            modifiedTraces[ith].colorscale[i][1] = cscale[Math.round(jump)][1];
            modifiedTraces[ith].colorscale[i][0] = gauge[i];
        }

        //return modifiedTraces;
    }
    /*
    Click handler for alternateColorBar (show 0 eV as white)
     */
    const onPlotlyClick = (eventData) => {
        var gd = document.getElementById('plotly-results');
        for (const key in eventData) {
            const value = eventData[key];
            if (key.includes('points')) {
                if (value[0].fullData.type.includes("heatmap")) {
                    const whichPlot = value[0].fullData.xaxis;
                    var modifiedTraces = Object.assign({}, results.traces);
                    for (let key in modifiedTraces) {
                        if (modifiedTraces[key].xaxis === whichPlot) {
                            var orgColorScale = modifiedTraces[key].colorscale;
                            var label = modifiedTraces[key].name;
                            if (typeof orgColorScale != 'string') {
                                orgColorScale[0][1] = (orgColorScale[0][1] === "rgb(255,255,255)") ? "rgb(85,82,156)" : "rgb(255,255,255)";
                                //label = label.includes("restore") ? label.replace("restore colorbar","show 0 eV") : label.replace("show 0 eV", "restore colorbar")
                                modifiedTraces[key].name = label;
                                //Plotly.react('plotly-results', modifiedTraces, results.layout, plotlyOptions);
                                Plotly.relayout(gd, modifiedTraces, results.layout, plotlyOptions);
                                return;
                            } else {
                                return;
                            }
                        }
                    }
                }
                if (value[0].data.type.includes("markers+lines")) {
                    var firstTime = true;
                    //this.show_error_flag_info(value[0].fullData.text);
                    for (let i in value[0].data.burstStartStop) {
                        if (value[0].customdata === value[0].data.burstStartStop[i].name
                            && firstTime) {
                            //Ashwin added: create an alert for when the burst is clicked.
                            //This works and so does the api call. Somewhere in here you need to add an Async call to properly update data.
                            var answer = window.confirm("Would you like to see the distribution for " + value[0].customdata + '?');
                            // put a mark on the burst tracker where they clicked...
                            var modifiedTraces = Object.assign({}, results.traces);
                            var clicked_at = eventData[key][0].pointIndex;

                            firstTime = false;

                            if (answer) {
                                // burst tracker distribution selection marker: I already changed burst tracker y values from 1,2 to 100,200 to allow this
                                modifiedTraces[1].y[clicked_at] = 220;

                                setCurrentStatus("Starting workflow");
                                document.getElementById("progress0").style.visibility = "visible";
                                document.getElementById("progress0").style.display = "inline";
                                //Plotly.react('plotly-results', modifiedTraces, results.layout, plotlyOptions);
                                Plotly.relayout(gd, modifiedTraces, results.layout, plotlyOptions);
                                // whenever I look for a new location I want to reset this.inc
                                var selectedBurstTime = value[0].x
                                var burstStart = value[0].data.burstStartStop[i].burstStart;
                                var burstStop = value[0].data.burstStartStop[i].burstStop;
                                //this.getDist_org(this.criteria.spacecraft, selectedBurstTime, burstStart, burstStop, this.inc);
                                handleDist4.current(criteria.spacecraft, selectedBurstTime, burstStart, burstStop, null, inc);
                            } else {
                                // selection was cancelled.
                            }


                        }
                    }
                } else {
                    const place = document.getElementById("errorFlagDiv");
                    //place.innerHTML = "";
                }

            }
        }

    }

    function show_error_flag_info(flag) {
        const place = document.getElementById("errorFlagDiv");

        if (Constants.ERROR_FLAGS.hasOwnProperty(flag)) {
            //alert(Constants.ERROR_FLAGS[flag]);
            place.innerHTML = "<br/>" + flag + "=> " + Constants.ERROR_FLAGS[flag];
        } else {
            place.innerHTML = "";
        }

    }

    //Function to get Burst Segement ranges
    //Ashwin Added: This will give us all of the burst segments within a data range.
    function find_range_of_burst(burstData) {
        const burstStartStop = {};
        const storeIterations = [];

        storeIterations.push(0);

        for (var i = 0; i < burstData.y.length; i++) {
            if (burstData.y[i] === null) {
                storeIterations.push(i);
            }
        }
        storeIterations.push(burstData.y.length);

        for (var j = 0; j <= storeIterations.length; j++) {
            if (j === 0) {
                burstStartStop[0] = {
                    "name": burstData.customdata[storeIterations[j]],
                    "burstStart": burstData.x[storeIterations[j]],
                    "burstStop": burstData.x[storeIterations[j + 1] - 1]
                }
            } else {

                burstStartStop[j] = {
                    "name": burstData.customdata[storeIterations[j] + 1],
                    "burstStart": burstData.x[storeIterations[j] + 1],
                    "burstStop": burstData.x[storeIterations[j + 1] - 1]
                }
            }
        }
        return burstStartStop;
    }

    function find_GSM_subtitle(changeTitle) {
        if (!results || Utils.areWeTestingWithJest()) {
            return
        }
        var layoutSearch = Object.assign({}, results.layout);
        for (let key in layoutSearch) {
            if (key.includes('xaxis')) {
                if (layoutSearch[key].title && layoutSearch[key].title.text.includes('Xgsm')) {
                    if (changeTitle) {
                        layoutSearch[key].title.text = changeTitle;
                    } else {
                        return layoutSearch[key].title.text;
                    }
                }
            }
        }

    }

    function move_slider(original_position, additional_seconds) {
        //const zdiff = Math.abs(plot_date_range[1] - plot_date_range[0]);
        var date = new Date(original_position);
        if (additional_seconds == null) {
            date.setSeconds(date.getSeconds() + (zdiff / 1000) / 2);
            const zdiff = Math.abs(zoom_end - zoom_start);
        }
        else {
            date.setMilliseconds(date.getMilliseconds() + 1000 * additional_seconds);
        }

        var string_date = (date.getYear() + 1900).toString() + "-" +
            ("0" + (date.getMonth() + 1).toString()).slice(-2) + "-" +
            ("0" + (date.getDate()).toString()).slice(-2) + "T" +
            ("0" + (date.getHours()).toString()).slice(-2) + ":" +
            ("0" + (date.getMinutes()).toString()).slice(-2) + ":" +
            ("0" + (date.getSeconds()).toString()).slice(-2) + "." +
            ("0" + (date.getMilliseconds()).toString()).slice(-3);
        console.log("org: ", original_position, "new_pos: ", string_date);
        return string_date;
    }
    function date2string(date) {
        var string_date = (date.getYear() + 1900).toString() + "-" +
            ("0" + (date.getMonth() + 1).toString()).slice(-2) + "-" +
            ("0" + (date.getDate()).toString()).slice(-2) + "T" +
            ("0" + (date.getHours()).toString()).slice(-2) + ":" +
            ("0" + (date.getMinutes()).toString()).slice(-2) + ":" +
            ("0" + (date.getSeconds()).toString()).slice(-2);

        return string_date;
    }


    /**
     *  This is how we rewrite the xaxis title (GSM data) on zoom in and out
     */
    function find_new_xaxis_title_values(zoom_start_str, zoom_end_str) {

        var tracesSearch = Object.assign({}, results.traces);
        var gsm_time;
        var gsm_t_str;
        var gsm_x_str;
        var gsm_y_str;
        var gsm_z_str;
        var xaxis_title_text;
        xaxis_title_text = find_GSM_subtitle();
        //setOrgTitle(xaxis_title_text);

        for (let key in tracesSearch) {
            if (tracesSearch[key]["type"].includes('scatter')) {
                if (tracesSearch[key].t) {
                    gsm_t_str = tracesSearch[key].t;
                    gsm_x_str = tracesSearch[key].x;
                    gsm_y_str = tracesSearch[key].y;
                    gsm_z_str = tracesSearch[key].z;
                }
                //var t = tracesSearch[key].type.name;
            }
        }
        var zoom_start = new Date(zoom_start_str);
        var zoom_end = new Date(zoom_end_str);
        const zdiff = Math.abs(zoom_end - zoom_start);
        var zoom_middle = new Date(zoom_start_str);
        zoom_middle.setSeconds(zoom_middle.getSeconds() + (zdiff / 1000) / 2);
        var zoom_midpoint = new Date(zoom_start_str);
        zoom_midpoint.setSeconds(zoom_midpoint.getSeconds() + (zdiff / 1000) / 2 + criteria.zd * 3600);
        setTable({
            ...mytable, mid_time: date2string(zoom_midpoint)
        });
        var xaxisTime = [];
        var xaxisVal = [[0, 1, 2], [3, 4, 5], [6, 7, 8]];
        for (var i = 0; i < gsm_t_str.length; ++i) {
            gsm_time = new Date(gsm_t_str[i])
            const res = Math.abs(gsm_time - zoom_start);
            if (res < 32000) {
                xaxisTime.push(gsm_t_str[i]);
                // it is important for alignment that +/-, each number gets to occupy only 5 spaces:
                xaxisVal[0][0] = tformat(gsm_x_str[i]).substring(0, 5);
                xaxisVal[1][0] = tformat(gsm_y_str[i]).substring(0, 5);
                xaxisVal[2][0] = tformat(gsm_z_str[i]).substring(0, 5);
                break;
            }
        }
        for (var k = i - 1; k < gsm_t_str.length; ++k) {
            gsm_time = new Date(gsm_t_str[k])
            if (Math.abs(gsm_time - zoom_middle) < 32000) {
                xaxisTime.push(gsm_t_str[k]);
                xaxisVal[0][1] = tformat(gsm_x_str[k]).substring(0, 5);
                xaxisVal[1][1] = tformat(gsm_y_str[k]).substring(0, 5);
                xaxisVal[2][1] = tformat(gsm_z_str[k]).substring(0, 5);
                break;
            }
        }
        for (i = k - 1; i < gsm_t_str.length; ++i) {
            gsm_time = new Date(gsm_t_str[i])
            if (Math.abs(gsm_time - zoom_end) < 32000) {
                xaxisTime.push(gsm_t_str[i]);
                xaxisVal[0][2] = tformat(gsm_x_str[i]).substring(0, 5);
                xaxisVal[1][2] = tformat(gsm_y_str[i]).substring(0, 5);
                xaxisVal[2][2] = tformat(gsm_z_str[i]).substring(0, 5);
                break;
            }
        }
        for (i = 0; i < 3; ++i) {
            for (k = 0; k < 3; ++k) {
                var match = "aa" + i + k + "aa";
                xaxis_title_text = xaxis_title_text.replace(/[-0-9]+\.+[0-9]+/i, match);
            }
        }
        for (i = 0; i < 3; ++i) {
            for (k = 0; k < 3; ++k) {
                match = "aa" + i + k + "aa";
                var re = new RegExp(match)
                xaxis_title_text = xaxis_title_text.replace(re, xaxisVal[i][k]);
            }
        }
        for (i = 0; i < 3; ++i) {
            match = "aa" + i + "aa";
            xaxis_title_text = xaxis_title_text.replace(/[-0-9]+:+[0-9]+/i, match);
        }
        for (i = 0; i < 3; ++i) {
            match = "aa" + i + "aa";
            re = new RegExp(match)
            xaxis_title_text = xaxis_title_text.replace(re, xaxisTime[i].substring(11, 16));
        }


        //setOrgTitle(find_GSM_subtitle(xaxis_title_text));
        find_GSM_subtitle(xaxis_title_text);
    }

    function tformat(num) {
        num = num.toFixed(2);
        return String(num).padStart(5, "0");
    }

    /*
     * @param eventData
     */
    const onPlotlyZoom = (eventData, modifiedTraces, modifiedLayout, gd) => {
        // Pick xaxis.range that deviates from the others-- this is the one
        // the user selected from. Picks less frequent.
        const rangeStartCounter = new Map();
        const rangeEndCounter = new Map();
        for (const key in eventData) {
            const value = eventData[key];

            const counter = key.includes('.range[0]')
                ? rangeStartCounter
                : rangeEndCounter;

            if (!counter.has(value)) {
                counter.set(value, 0);
            }
            if (key.includes("yaxis")) {
                if (key.includes("range[0]")) {
                    var axis = Number(key.slice(5, 6));
                    var axis_name = 'y' + key.slice(5, 6);
                }
            }

            counter.set(value, counter.get(value) + 1);
        }
        // find the y min and max for selected area:
        const y_rangeStart = ([...rangeStartCounter.entries()])[([...rangeStartCounter.entries()]).length - 1][0];
        const y_rangeEnd = ([...rangeEndCounter.entries()])[([...rangeEndCounter.entries()]).length - 1][0];

        const rangeStart = (
            [...rangeStartCounter.entries()]        // pick with smallest count
                .sort((a, b) => a[1] - b[1])[0][0]
        );

        const rangeEnd = (
            [...rangeEndCounter.entries()]          // pick with smallest count
                .sort((a, b) => a[1] - b[1])[0][0]
        );


        const newRange = [rangeStart, rangeEnd];
        // Save the original GSM data
        if (criteria.gsm != 0) {
            if (orgTitle === "") {
                // we only want to save this (by using this useStateFunction:setOrgTitle) once,
                // when we specify a new range we create our own new title from this. we never want to 'reset' it.
                setOrgTitle(find_GSM_subtitle());
            }
            find_new_xaxis_title_values(rangeStart, rangeEnd)

        }
        criteria.lasp_startTime = rangeStart;
        criteria.lasp_endTime = rangeEnd;


        for (let key in modifiedTraces) {
            // find the overall ymax for the plot the user selected:
            if (modifiedTraces[key].yaxis == axis_name) {
                let a = modifiedTraces[key].y.map(Number);
                if (modifiedTraces[key].type == "heatmap") {
                    var ytotal_rangeStart = 1; // or -Infinity 1D array
                    var ytotal_rangeEnd = Math.log10(Math.max(...a)); // 1D array
                } else {
                    var ytotal_rangeStart = Math.min(...a); // 1D array
                    var ytotal_rangeEnd = Math.max(...a); // 1D array
                }

            }
        }
        for (let key in modifiedLayout) {
            if (key[0] === 'x') {
                modifiedLayout[key].range = newRange;
                // We want to use array for our standard tickmode and auto when we are zoomed in:
                if (modifiedLayout[key]['showticklabels'] === true) {
                    modifiedLayout[key]['tickmode'] = 'auto';
                }
            }
        }
        // update title on zoom in. original start-end title is calculated in plot_renderer.py
        const currentTitle = String(modifiedLayout['title']['text']);
        var titlesections = currentTitle.split('<br>');
        const topLine = titlesections[0];
        var orbitLine = "";
        if (titlesections.length > 2) {
            orbitLine = titlesections[2]
        }
        const bottomLine = displayNewHeaderTime(rangeStart) + " - "
            + displayNewHeaderTime(rangeEnd);

        modifiedLayout['title']['text'] = topLine + "<br>" + bottomLine + "<br>" + orbitLine;

        Plotly.relayout(gd, modifiedTraces, results.layout, plotlyOptions);
    }

    function resetTitle(modifiedLayout) {
        const currentTitle = String(modifiedLayout['title']['text']);
        var titlesections = currentTitle.split('<br>');
        var orbitLine = "";
        if (titlesections.length > 2) {
            orbitLine = titlesections[2];
        }
        find_GSM_subtitle(orgTitle);
        const topLine = titlesections[0];
        var startTime = new Date(criteria.startTime)
        startTime.setHours(startTime.getHours() + criteria.zd);
        var startString = startTime.toISOString();
        var endTime = Object.assign(startTime); //get a deep copy
        // a criteria.duration > 6 is minutes not hours
        if (criteria.duration > 6) {
            endTime.setMinutes(endTime.getMinutes() + criteria.duration);
        } else {
            endTime.setHours(endTime.getHours() + criteria.duration);
        }
        endTime.setSeconds(endTime.getSeconds() - 1);
        var endString = endTime.toISOString();
        const bottomLine = displayNewHeaderTime(startString) + " - "
            + displayNewHeaderTime(endString);
        modifiedLayout['title']['text'] = topLine + "<br>" + bottomLine + "<br>" + orbitLine;
    }

    /* this is necessary because we want to switch to auto tick mode when we zoom in and
       reverse on the way out. That way we will have times when we zoom in.
     */
    function resetArrayModeForXaxisLabels(modifiedLayout) {
        for (let key in modifiedLayout) {
            if (key[0] === 'x') {
                // We want to use array for our standard tickmode and auto when we are zoomed in:
                if (modifiedLayout[key]['showticklabels'] === true &&
                    modifiedLayout[key]['ticktext'] != null) {
                    modifiedLayout[key]['tickmode'] = 'array';
                }
            }
        }

    }

    function addCustomDataWithBins(trace, dates, bin) {
        //var trace = Object.assign(hoverdata);
        var units = "cm " + "-2".sup() + " s" + "-1".sup() + " sr" + "-1".sup() + "<br>";
        var evLevel = "low"
        for (var row = 0; row < trace.length; ++row) {
            if (bin[row] <= 200) {
                evLevel = "(low)"
            } else if (bin[row] > 2000) {
                evLevel = "(high)"
            } else {
                evLevel = "(med)"
            }
            for (var col = 0; col < trace[row].length; ++col) {
                if (trace[row][col] != " ") {
                    trace[row][col] += units + String(Math.trunc(bin[row])).padStart(7, " ") + " eV "
                        + " bin:" + String(row).padStart((2, " ")) + " " + evLevel + "<br>" + dates[col].substring(0, 23);
                }
            }
        }
        return trace;
    }

    function addCustomData(trace, dates, deg) {
        //var trace = Object.assign(hoverdata);
        var units = "cm " + "-2".sup() + " s" + "-1".sup() + " sr" + "-1".sup() + "<br>";
        for (var row = 0; row < trace.length; ++row) {
            for (var col = 0; col < trace[row].length; ++col) {
                if (trace[row][col] != " ") {
                    trace[row][col] += units + String(deg[row]).padStart(4, " ") + " deg<br>" + dates[col].substring(0, 23);
                }
            }
        }
        return trace;
    }

    /**
     * custom icons, grouping icons
     */
    function specifyIcons() {
        if (typeof Plotly == 'undefined') {
            return
        }
        var options = {
            editable: true,
            displayModeBar: true,
            modeBarButtons: [
                ["toImage",
                    {
                        name: 'Download plot as SVG',
                        icon: Plotly.Icons.disk,
                        click: function (gd) {
                            Plotly.downloadImage(gd, { format: 'svg' })
                        }
                    }],
                ["autoScale2d", "resetScale2d", "hoverClosestCartesian", "hoverCompareCartesian",
                    "pan2d", "toggleSpikelines", "zoom2d", "zoomIn2d", "zoomOut2d"]
            ]
        };
        return options;
    }

    function deSpecifyIcons() {
        var plotlyOptions = {
            displayModeBar: false,
            modeBarButtonsToRemove: [
                ["toImage",
                    {
                        name: 'Download plot as SVG',
                        icon: Plotly.Icons.disk,
                        click: function (gd) {
                            Plotly.downloadImage(gd, { format: 'svg' })
                        }
                    }],
                ["autoScale2d", "resetScale2d", "hoverClosestCartesian", "hoverCompareCartesian",
                    "pan2d", "toggleSpikelines", "zoom2d", "zoomIn2d", "zoomOut2d"]
            ]
        };
        return plotlyOptions;
    }


    /**
     * Called when the previous button is clicked.
     */
    function onPreviousClick() {
        // Generate new criteria
        props.sharedStore.dispatch({ type: 'SET_DIST_BUTTON', value: 'none-restarting' });
        props.sharedStore.dispatch({
            type: 'SET_LINEPOS',
            value: "" /* re-up the distributiions proc */
        });
        props.sharedStore.dispatch({
            type: 'SET_LINEPOS',
            value: null /* re-up the distributiions proc */
        });
        const newCriteria = Object.assign({}, criteria);
        if (newCriteria.duration > 6) {
            newCriteria.units = "minutes";
            newCriteria.startTime = moment(newCriteria.startTime)
                .utc().add(-newCriteria.duration, 'minutes').format();
        } else {
            newCriteria.units = "hours";
            newCriteria.startTime = moment(newCriteria.startTime)
                .utc().add(-newCriteria.duration, 'hours').format();
        }

        var prep = JSON.stringify(newCriteria);
        holdNewCriteria(newCriteria);
        setGoToContact({ ...goToContact, state: true, transferInfo: prep });
        setLoadingData(true);
        setHasData(true);
        setFisrtLoadIn(false);
        setPlotlyInnerElement(<ProgressScreen newCriteria={newCriteria} sharedStore={props.sharedStore}
            setPlotlyInnerElement={setPlotlyInnerElement}
            loadingData={loadingData}
            setLoadingData={setLoadingData}></ProgressScreen>);
        //const redirect = navigate('/progress', { state: { criteria: prep } }); testing progress
        setState({ redirect });
    }

    /**
     * Called when the next button is clicked.
     */
    function onNextClick() {
        // Generate new criteria
        props.sharedStore.dispatch({
            type: 'SET_LINEPOS',
            value: null /* re-up the distributiions proc */
        });
        // Generate new criteria
        props.sharedStore.dispatch({
            type: 'SET_DIST_BUTTON',
            value: 'none-restarting'
        });
        const newCriteria = Object.assign({}, criteria);
        if (newCriteria.duration > 6) {
            newCriteria.units = "minutes";
            newCriteria.startTime = moment(newCriteria.startTime)
                .utc().add(newCriteria.duration, 'minutes').format();

        } else {
            newCriteria.units = "hours";
            newCriteria.startTime = moment(newCriteria.startTime)
                .utc().add(newCriteria.duration, 'hours').format();
        }

        // Set Redirect
        holdNewCriteria(newCriteria);
        var prep = JSON.stringify(newCriteria);
        setGoToContact({ ...goToContact, state: true, transferInfo: prep });
        setLoadingData(true);
        setHasData(true);
        setPlotlyInnerElement(<ProgressScreen newCriteria={newCriteria} sharedStore={props.sharedStore}
            setPlotlyInnerElement={setPlotlyInnerElement}
            loadingData={loadingData}
            setLoadingData={setLoadingData}></ProgressScreen>);
        // const redirect = navigate('/progress', { state: { criteria: prep } }); testing progress
        // setState({ redirect });
    }

    const holdNewCriteria = (data) => {
        for (let key in criteria) {
            if (data[key] !== null) {
                criteria[key] = data[key];
            }
        }
        return;
    }

    /**
     * Called when the generate button is clicked in embedded the control form.
     */
    const onGenerateClick = (formState) => {
        let newCriteriaObj = criteria;
        for (let key in formState) {
            newCriteriaObj[key] = formState[key]
        }
        setFisrtLoadIn(false)
        setCriteria(newCriteriaObj);
        var prep = JSON.stringify(formState)
        setGoToContact({ ...goToContact, state: true, transferInfo: prep });
        handleDrawerClose()
        setLoadingData(true);
        setHasData(true);
        const plotlyResults = document.getElementById('plotly-results'); //empty plot or text when loading new plot
        plotlyResults.innerHTML = '';
        setPlotlyInnerElement(<ProgressScreen newCriteria={formState} sharedStore={props.sharedStore}
            setPlotlyInnerElement={setPlotlyInnerElement}
            loadingData={loadingData}
            setLoadingData={setLoadingData}></ProgressScreen>);
        props.sharedStore.dispatch({
            type: 'SET_LINEPOS',
            value: null /* re-up the distributiions proc */
        });
        var prep = JSON.stringify(formState)
        setGoToContact({ ...goToContact, state: true, transferInfo: prep });
    }

    /**
     *Do a filenames query to build a comma separated download query for download URL
     * get a filename (if a zip file )
     */
    function queryLaspForScienceDownloadUrl(mytable) {
        // document.getElementById("DownloadingLASP").style.visibility = "visible";
        // document.getElementById("preDownloadingLASP").style.visibility = "hidden";
        document.getElementById("DownloadingLASP").style.display = "inline";
        document.getElementById("preDownloadingLASP").style.display = "none";
        // lasp_startTime is used when onPlotlyZoom is invoked
        var lstart = criteria.startTime;
        var lend = criteria.endTime;
        if (criteria.lasp_endTime) {
            lstart = criteria.lasp_startTime;
            lend = criteria.lasp_endTime;
        }

        var wfid = mytable.sharedStore.getState().workflowId;

        axios.get(`api/download_data?workflow_id=${wfid}&start_time=${lstart}&end_time=${lend}`).then(resp => {

            if (resp.data.error) {
                const { message } = resp.data;
                const redirect = navigate(`/error/${message}`);
                setState({ redirect });
                return;
            }

            const {
                done,
                url,
                filename
            } = resp.data;

            if (done) {
                doTheDownload(url, filename);
                return url;
            }

        })
            .catch(error => {
                const message = "An unknown error downloading the data.";
                const redirect = navigate(`/error/${message}`);
                setState({ redirect });
            });
    }


    /**
     * A mechanism to download a file to the user
     */
    function doTheDownload(lasp, filename) {
        axios({
            url: lasp,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            waitForDialog(link);
        });
    }

    function waitForDialog(link) {
        setTimeout(function () {
            // document.getElementById("DownloadingLASP").style.visibility = "hidden";
            document.getElementById("DownloadingLASP").style.display = "none";
            // document.getElementById("preDownloadingLASP").style.visibility = "visible";
            document.getElementById("preDownloadingLASP").style.display = "inline";
        }, 500);
    }

    function mms_popup_prep() {
        // sometimes this is a string sometimes it is a moment
        var posDate = criteria.startTime.toString();
        if (posDate.indexOf(" ") > 0) {
            // convert to Date to use calculate zone
            posDate = new Date(criteria.startTime.toISOString());
        } else {
            posDate = new Date(criteria.startTime);
        }
        var zd = 0; //calculate_zone(posDate);
        posDate.setHours(posDate.getHours() + zd);
        //convert back to moment to use toISOString
        posDate = moment(posDate.toString())
        posDate = posDate.toISOString();

        posDate = posDate.replace('T', '');
        posDate = posDate.replace(/-/g, '');
        posDate = posDate.replace(/:/g, '');
        return posDate;
    }


    function change_time_label(ion_selected) {
        if (ion_selected) {
            setSpecies({ ...which_species, time_delta: ".75 secs", currzoom_placeholder: "1500" });
            const tdl = document.getElementById("time_label");
            tdl.innerHTML = ".75 secs"
            const czp = document.getElementById("resize-value-zoom");
            czp.placeholder = "1500"
        } else {
            setSpecies({ ...which_species, time_delta: ".15 secs", currzoom_placeholder: "10000" });
            const tdl = document.getElementById("time_label");
            tdl.innerHTML = ".15 secs"
            const czp = document.getElementById("resize-value-zoom");
            czp.placeholder = "10000"
        }
    }

    // getDist already has selincplot...so...


    function mms_formation_popup(posDate) {
        var answer = "";
        var idx = posDate.indexOf('.')
        axios.get('api/database_search_for_formation?q=' + posDate.substring(0, idx))
            .then((response) => {
                for (var item in (response.data.formation)) {
                    answer = answer + response.data.formation[item].url + "\n";
                }
                if (answer != null) {
                    var myWindowName = "formation_popup";
                    var myWindowOffset = 60;
                    mms_finish(answer, myWindowName, myWindowOffset, 417, 521);
                }
            });
    }

    function mms_position_popup_xy(posDate) {
        var myWindowName = "position_popup_xy";
        posDate = posDate.slice(0, 10);
        var myLink = "https://lasp.colorado.edu/mms/sdc/public/data/sdc/"
        myLink += "mms_orbit_plots/mms_orbit_plot_" + posDate + "0000.png";
        var myWindowOffset = 40;
        mms_finish(myLink, myWindowName, myWindowOffset);
    }

    function mms_position_popup_xz(posDate) {
        var myWindowName = "position_popup_xz";
        posDate = posDate.slice(0, 10);
        var myLink = "https://lasp.colorado.edu/mms/sdc/public/data/sdc/"
        myLink += "mms_orbit_plots_xz/mms_orbit_plot_xz_" + posDate + "0000.png";
        var myWindowOffset = 20;
        mms_finish(myLink, myWindowName, myWindowOffset);
    }

    function mms_finish(myLink, myWindowName, myWindowOffset, height, width) {
        height = (height > 0) ? height : 600
        width = (width > 0) ? width : 600
        if (!window.focus)
            return true;
        var href;
        var left = (window.screenX) - myWindowOffset;
        var top = (window.screenY - (myWindowOffset * 2)) / 2;
        if (typeof (myLink) == 'string')
            href = myLink; else href = myLink.href;
        window.open(href, myWindowName, 'width=' + width + ',height=' + height +
            ',scrollbars=yes,top=' + top + ', left=' + left);
        return false;
    }

    // Open drawer after .5 second on first load
    if (fisrtLoadIn) {
        if (location.pathname.length > 15) { // check if url has parms for data
            setTimeout(() => { // need this to not case a plotly-results is null error because html hasnt rendered
                try {
                    let url = decodeURI(location.pathname); //get rid of %22 and other ascii incodering
                    url = url.slice(9);
                    onGenerateClick(JSON.parse(url));
                } catch (error) {
                    console.log('error', error)
                    setTimeout(() => {
                        setDateFormToTrue()
                        handleDrawerOpen()
                        setFisrtLoadIn(false)
                    }, 200);
                }
            }, 500);
        } else {
            setTimeout(() => {
                setDateFormToTrue()
                handleDrawerOpen()
                setFisrtLoadIn(false)
            }, 700);
        }
    }
    if (!Utils.areWeTestingWithJest()) { /* under bookmark conditions there is no location.state... no results...*/
        if (hasData) {

            return (

                <React.Fragment>
                    <Grid container columnSpacing={2} rowSpacing={4} style={{ height: "100%" }}>
                        <Grid item xs={1} sm={1} md={.5} lg={.5} xl={.5}>
                            <div
                                className={"btn-position " + (open ? "move-button" : "")}
                                onClick={handleDrawerOpen}
                                defaults={criteria}
                                searchbox_label={searchbox_label}
                                //toNewMuiClick={toNewMuiClick}
                                initialSearchQuery={searchStuff}
                                onGenerateClick={onGenerateClick}>
                                <Button
                                    className={"button-drawer-closed " + (open ? "button-drawer-open " : "") + (dateForm ? "date-button-selected" : "")}
                                    onClick={setDateFormToTrue}>
                                    Datetime
                                </Button>
                                <Button
                                    className={"button-drawer-closed " + (open ? "button-drawer-open " : "") + (varForm ? "var-button-selected" : "")}
                                    onClick={setVarFormToTrue}
                                >Variables
                                </Button>
                            </div>
                            <Drawer
                                open={open}
                                anchor={'left'}
                                PaperProps={{
                                    style: {
                                        width: '85%',
                                        justifyContent: 'center',
                                        height: '92VH',
                                        top: '55px',
                                        backgroundColor: '#e5e2e2',
                                        borderTopRightRadius: '10px',
                                        borderBottomRightRadius: '10px',
                                        boxShadow: 'none'
                                    }
                                }}
                                onClose={handleDrawerClose}
                            >
                                {varForm && (< ControlFormVariables
                                    closeDrawer={handleDrawerClose}
                                    defaults={criteria}
                                    holdNewCriteria={holdNewCriteria}
                                    // toNewMuiClick={toNewMuiClick}
                                    // initialSearchQuery={searchStuff}
                                    onGenerateClick={onGenerateClick}
                                />)}
                                {dateForm && (<ControlFormDateTime
                                    closeDrawer={handleDrawerClose}
                                    nextButton={setVarFormToTrue}
                                    defaults={criteria}
                                    holdNewCriteria={holdNewCriteria}
                                // toNewMuiClick={toNewMuiClick}
                                // initialSearchQuery={searchStuff}
                                // onGenerateClick={onGenerateClick}
                                />)}
                            </Drawer>

                        </Grid>

                        <Grid item xs={10} sm={10} md={7} lg={7} xl={7} className="">
                            {/* xs={7} */}

                            <div id="Title Area Placeholder" className="space-craft-margin">
                                <h1 className="plot-header">Plots</h1>
                                <div className="space-buttons">
                                    <span className="space-craft-label">
                                        Spacecraft Views:
                                    </span>
                                    <button className="button-position-xy spacecraft-button"
                                        onClick={() => mms_position_popup_xy(mms_popup_prep())}>
                                        MMS Position XY
                                    </button>
                                    <button className="button-position-xz spacecraft-button"
                                        onClick={() => mms_position_popup_xz(mms_popup_prep())}>
                                        MMS Position XZ
                                    </button>
                                    <button className="button-position-formation spacecraft-button"
                                        onClick={() => mms_formation_popup(mms_popup_prep())}>
                                        MMS Formation
                                    </button>
                                </div>
                            </div>

                            <div className="grid-item-padding grid-border">
                                <div class="item arrow-left">
                                    <div className="left-arrow-inner-container">
                                        <button className="arrow-housing-left"
                                            href={"#"}
                                            onClick={onPreviousClick}
                                        >
                                            <div className="leftarrow" />
                                            <label
                                                className="arrowtext">Previous {criteria.duration} {criteria.units}</label>
                                        </button>
                                    </div>
                                </div>
                                {/* <button className="arrowtext" href={"#"}>{criteria.duration} {criteria.units} </button> */}
                                <div className="tour_burst item plot" id="contains graph div">
                                    {plotlyInnerElement}
                                    <div className='plotly-placement' id="plotly-results">
                                    </div>
                                </div>
                                <div class="item arrow-right">
                                    <div className="right-arrow-inner-container">
                                        <button className="arrow-housing-right"
                                            onClick={onNextClick}>
                                            <label
                                                className="arrowtext">Next {criteria.duration} {criteria.units}</label>
                                            <div className="rightarrow" />
                                        </button>
                                    </div>
                                </div>
                            </div>


                        </Grid>


                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            {/* <Grid container
                            direction="column"
                            style={{ height: "100%" }}
                            className="grid-item-padding grid-border"> */}
                            {/* where dist instructions were*/}
                            {/* < Grid item> */}
                            <div className="dist-margin">
                                <button className="button-position-download spacecraft-button"
                                    onClick={() => queryLaspForScienceDownloadUrl(mytable)}>
                                    <span id="preDownloadingLASP"> Download CDF Files</span>
                                    <span id="DownloadingLASP"> Downloading  Files... </span>
                                </button>
                                {/* <span style={{ marginLeft: "0px" }}>
                                    <div id="DownloadingLASP"> Downloading LASP Files...<br />
                                        <CircularProgress size=".5rem" />
                                    </div>
                                </span> */}
                            </div>
                            <div className="grid-item-padding grid-border burst-grid">
                                <Distributions
                                    info={which_species}
                                    cs={currentStatus}
                                    progressPercent={progressPercent}
                                    inc={inc}
                                    criteria={criteria}
                                    setCurrentStatus={setCurrentStatus}
                                    handleDist4={handleDist4}
                                    dist_resize={dist_resize}
                                    getSpecies={getSpecies}
                                    workflowId={workflowId}
                                    tooMuchData={tooMuchData}
                                    sharedStore={props.sharedStore}
                                    setState={setState}
                                    rDist={dist}
                                    line_pos={line_position}
                                    mid_time={mytable.mid_time}
                                    move_slider={move_slider}
                                    setfirstDistLoad={setfirstDistLoad}
                                    firstDistLoad={firstDistLoad}
                                    disableButton={loadingData || open}
                                />
                            </div>
                            {/* </Grid > */}

                            {/* </Grid > */}

                        </Grid>

                    </Grid>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Grid container columnSpacing={2} rowSpacing={4} style={{ height: "100%" }}>
                        <Grid item xs={1} sm={1} md={.5} lg={.5} xl={.5}>
                            <div
                                className={"btn-position " + (open ? "move-button" : "")}
                                onClick={handleDrawerOpen}
                                defaults={criteria}
                                searchbox_label={searchbox_label}
                                //toNewMuiClick={toNewMuiClick}
                                initialSearchQuery={searchStuff}
                                onGenerateClick={onGenerateClick}>
                                <Button
                                    className={"button-drawer-closed " + (open ? "button-drawer-open " : "") + (dateForm ? "date-button-selected" : "")}
                                    onClick={setDateFormToTrue}>
                                    Datetime
                                </Button>
                                <Button
                                    className={"button-drawer-closed " + (open ? "button-drawer-open " : "") + (varForm ? "var-button-selected" : "")}
                                    onClick={setVarFormToTrue}
                                >Variables
                                </Button>
                            </div>
                            <Drawer
                                open={open}
                                anchor={'left'}
                                PaperProps={{
                                    style: {
                                        width: '85%',
                                        justifyContent: 'center',
                                        height: '92VH',
                                        top: '55px',
                                        backgroundColor: '#e5e2e2',
                                        borderTopRightRadius: '10px',
                                        borderBottomRightRadius: '10px'
                                    }
                                }}
                                onClose={handleDrawerClose}
                            >
                                {varForm && (< ControlFormVariables
                                    closeDrawer={handleDrawerClose}
                                    onGenerateClick={onGenerateClick}
                                    holdNewCriteria={holdNewCriteria}
                                    defaults={criteria}
                                />)}
                                {dateForm && (<ControlFormDateTime
                                    closeDrawer={handleDrawerClose}
                                    nextButton={setVarFormToTrue}
                                    holdNewCriteria={holdNewCriteria}
                                    defaults={criteria}
                                />)}
                            </Drawer>

                        </Grid>

                        <Grid item xs={10} sm={10} md={7} lg={7} xl={7} className="">
                            <div id="Title Area Placeholder" className="space-craft-margin">
                                <h1 className="plot-header">Plots</h1>
                            </div>
                            <div className="grid-item-padding grid-border emplty-results">
                                <h5 className="plot-subheaders"> Graphs </h5>
                                <div className='plotly-placement' id="plotly-results">
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className="grid-item-padding grid-border burst-grid emplty-results">
                                <h5 className="plot-subheaders"> Burst Distributions </h5>
                            </div>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
    } else {
        return (
            <Grid container spacing={5}>
                test
            </Grid>
        );
    }
}

export default ResultsScreen;

