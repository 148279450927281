import React, { useState } from 'react';
import ReactJoyride from 'react-joyride';
import './ControlForm.css';
import dist_instr from "./images/dist_instr.png";
import slider_tour from "./images/slider-tour.png";
import Button from '@mui/material/Button';
const ResultsOnboardingSteps = () => {


  const [joyride, setJoyride] = useState({
    run: false,

        /**
         * MAIN PLOT
         **/
    steps: [
      {
        title: "The Plot is Interactive ",
        target: ".plotly-placement",
        placement: "center",
        content: <div><p>Plots can be zoomed into by clicking and dragging vertically, horizontally, or by clicking and dragging a rectangular area.</p>
          <p>The scales can be moved by clicking on the numeric values on the left (Y axis) of a plot and dragging vertically.</p>
          <p>Clicking anywhere on a spectrogram toggles the no-data color between light and dark.</p></div>,
        disableBeacon: true,
      },
      {
        title: "Plotting Burst Distributions ",
        target: ".tour_burst",
        placement: "auto",
        content: <div><a> <img href='#' src={slider_tour} alt={'slider'} width="103" height="160"/> </a>
          <p>Once distributions are activated, this is represented by the appearance of a translucent, blue line running vertically through all the plots.
              Grab this line and drag it left or right to a new time position.
            That time will appear in a Distribution Plot widget.  The distribution plots will be generated using this time value.
          </p>
        </div>,
        disableBeacon: true,
      },
     {
            title: "Plot Previous Time Period ",
            target: ".leftarrow",
            placement: "auto",
            content: "Fetch data and plot the previous time period(according to the selected duration).",
            disableBeacon: true
          },
          {
            title: "Plot Next Time Period ",
            target: ".rightarrow",
            placement: "auto",
            content: "Fetch data and plot the next time period.",
            disableBeacon: true
     },
          {
            title: "Generate a Plot PDF ",
            target: ".modebar-group",
            placement: "auto",
            content: <div><p>To create a PDF of the plots, start by downloading an SVG (Scalable Vector Graphic) file by selecting the “floppy disk” icon.  (The resulting file may have a .txt extension --
              if so, change this to .svg.)</p>
              Most browsers can then view the file.  (Drag the file onto the browser's icon or open the file using the Open selection from within the browser.)
              If the browser does not have an Export to PDF option, it may have a PDF export option available in the Print window.</div>,
            disableBeacon: true
          },
          /**
           * DISTRIBUTIONS
           **/
      {
        title: "Get Started with Distributions ",
        target: ".tour_click_here",
        content: <div>This will place a vertical line over the graph which you can move to the right or left
          (that is, forwards or backwards along the time axis to select a time for the distribution plot).</div>,
      },
      {
        title: "Initiate Distribution Plot Processing",
        target: ".tour_start",
        placement: "auto",
        content: <div>Use this button to start the distribution plot processing on the server at the time selected with the position of the vertical slider on the main plot.</div>,
        disableBeacon: true
      },
      {
        title: "Browse or Publish ",
        target: ".brow-pub",
        placement: "auto",
        content: <div>20 sets of 3 distribution plots are nominally returned when the user requests distribution plots.
                In order to save time, these plots are abbreviated.
                Selecting “Publish" instead of “Browse" will return fewer (5 sets of 3) more-complete/detailed distribution plots.</div>,
        disableBeacon: true
      },
      {
                title: "Persistent Distribution Plot Parameters ",
                target: ".dist-controls",
                content: "You can maintain the size, zoom level, and color range of each subsequent processing of distribution plots with these selections",
            },
      {
                title: "Image Size ",
                target: ".image-size",
                content: "Adjust this number (anything above 200) to change the image size. (The default is 3500.)",
            },
      {
            title: "Next Distribution Zoom Level ",
            target: ".zoomText",
            placement: "auto",
            content: <div>
              <p>Although the distribution plots are interactive, to change the zoom level
                of subsequent burst velocity distribution plots, change this number to anything above 230.</p>
              Default values:<br/>
              ion zoom level:1500<br/>
              electron zoom level 12000
            </div>,
            disableBeacon: true
      },
      {
        title: "Apply the Changes ",
        target: ".tour_apply",
        content: <div>Apply the changes you have made to the controls to create a set of distributions at the selected time.
            <p/> Note that this button only applies to the distribution plots that have already been displayed below</div>
      },
      {
            title: "Color Range Slider ",
            target: ".container",
            placement: "auto",
            content: <div><p>There is a default color bar which covers the color range 0-255.
                (Range of phase space density (PSD) values  may vary.)
                If the user needs to focus on a certain range of values, use this double slider to focus the entire range
                of colors to a narrower range of values.</p>
                For example, if the range of PSD values are 1E-30 to 1E-24, but the user wants to focus on the values around 1E-26,
                an estimation of that color can be made. Slide the two outer boundaries of the color range
                slider by moving one or both ends of the slider toward the middle. Then hit the Apply button and a new set of
                distribution plot processing will be initiated with all of the colors focused around values 1E-26. Lower
                values will be saturated in dark blue. Higher values will be saturated in dark red.
            </div>,
            disableBeacon: true
      },
      {
        title: "Show the Next 3 Slices ",
        target: ".next-dist-right",
        placement: "auto",
        content: <div>20 sets of 3 slices are currently displayed. This button will display the next set of these 20 sets.
                  The initial selection of a set of 3 will be slow to generate because although the data is on your desktop, it is  not loaded into the page.
                  When the set of 3 is subsequently selected, the same set will appear very quickly.</div>,
        disableBeacon: true
      },
      {
        title: "Process the Next Set of Distribution Plots ",
        target: ".arrow-housing-dist-right",
        placement: "auto",
        content: "Fetch the next 20 sets of 3 distribution plot slices from the server.",
        disableBeacon: true
      },

      {
        title: "Distribution Plots ",
        target: ".dist-plot-container",
        placement: "left-start",
          content: <div><p>A set of three axial magnetic-field-aligned views are displayed.</p>
              The data is sliced from a single skymap.
              The thickness of the slice is based on a velocity-space slice thickness of 2,000 km/s
              (which corresponds to an energy-space thickness of approximately 10 eV) for dayside DES electron distributions.
        </div>,

        disableBeacon: true

      },
          /**
           * RELATED MATERIAL
           **/
      {
        title: "MMS Satellite Position and Formation ",
        target: ".button-position-xy",
        content: "Show satellite XY Position, XZ Position and Formation",
        disableBeacon: true
      },
      {
            title: "Download Displayed Data ",
            target: ".button-position-download",
            content: "Download the CDF files for the data displayed." + " The data returned will only be the displayed duration.",
            disableBeacon: true
      },
    ]
  }

  );

  const handleJoyrideCallback = (data) => {
    const { action } = data;
    if (action === 'close') {
      setJoyride({ ...joyride, run: !joyride.run });
    }
  };

  return (
    <React.Fragment>
      {/* <div id="onboarding-2" style={{ marginLeft: "10%", marginRight: "auto" }}> */}
      <Button
        id="onboarding-2"
        onClick={() => { setJoyride({ ...joyride, run: !joyride.run }); }}
        style={{
          color: 'white',
          paddingRight: '20px'
        }}>
        TAKE TOUR</Button>
      {/* </div> */}
      <ReactJoyride
        steps={joyride.steps}
        run={joyride.run}
        callback={handleJoyrideCallback}
        continuous
        showSkipButton
        disableScrolling
        locale={{
          skip: "Exit",
          last: "Done"
        }}
        styles={{
          options: {
            arrowColor: 'white',
            backgroundColor: '#ffffff',
            overlayColor: 'rgba(0, 15, 39, 0.35)',
            primaryColor: '#163B76',
            textColor: '#000000',
            textSize: '8px',
            width: 300,
            zIndex: 1000
          },
          tooltipContainer: {
            lineHeight: 1.4,
          },
          tooltipTitle: {
            fontSize: 18,
            margin: 0,
            color: 'black',
            textAlign: 'center',
          },
          tooltipContent: {
            color: '#626A85',
            fontSize: 14,
            textAlign: 'left',
            padding: '20px 10px',
          },
          tooltipFooter: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end'
          },
          tooltipFooterSpacer: {
            flex: 1,
          },
          buttonClose: {
            display: 'none',
          },
          buttonNext: {
            backgroundColor: "#163B76",
            borderRadius: 4,
            fontSize: 16,
            color: 'white',
          },
          buttonSkip: {
            color: '#626A85',
            fontSize: 14,
            text: "exit"
          },
          buttonBack: {
            color: '#626A85',
            marginLeft: 'auto',
            marginRight: 15,
          }
        }}


      />


    </React.Fragment>
  );
};


export default ResultsOnboardingSteps