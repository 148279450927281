import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import React, {useEffect, useRef, useState, useStore} from "react";
import Constants from "./Constants";
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import axios from "axios";
import { Button } from "@mui/base";

const VARIABLES = require('./variables.json');

function ProgressScreen (props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [progressPercent, setPercent] = useState(0);
  const [status, setProgStatus] = useState("Processing");
  const [redirect, setRedirectStatus] = useState(null);
  const [workflowId, setworkflowId] = useState(null);
  const timerRef = useRef(null);
 // const dispatch = useDispatch()
  const criteria = props.newCriteria || location.state;
 

   useEffect(() => {
    startWorkflow();
    window.scrollTo(0, 0);
    return () => {
        clearTimeout(timerRef.current);
        window.history.pushState('','', reduceUrl()) // shorten URL
    }
  },[]);
  function setProgressPercent(p) {
      setPercent(p);
  }
  function setProgressStatus(s) {
      setProgStatus(s);
  }
  function setState(re) {
      setRedirectStatus(re);
  }

    /**
     * Initiates the workflow with a request to the backend and starts the
     * polling process.
     */
    const startWorkflow = () => {
        axios
        .post('api/start_workflow', criteria)
        .then(resp => {
            // Intercept Error
            if (resp.data.error) {
                const {message} = resp.data;
                const redirect = navigate(`/error/${message}`);
                setState({redirect});
                return;
            }

            // Set Loading Status
            const {
                workflowId,
                status,
                progressPercent
            } = resp.data;


            setworkflowId(workflowId);
            setProgressStatus(status);
            setProgressPercent(progressPercent);
            props.sharedStore.dispatch({
                type: 'SET_WORKFLOWID',
                value: workflowId  // for lasp download
            });
            tick(workflowId);
        })
        .catch(error => {
            const message = "An unknown error occurred generating the plots.";
            const redirect = navigate(`/error/${message}`);
            setState({redirect});
        });
    }


    /**
     * Poll the backend for workflow updates.
     *
     * Breaks when the result is received.
     */
    function tick(workflowId) {
        axios
        .get(`api/poll_workflow?workflow_id=${workflowId}&tick=1`)
        .then(resp => {
            const {
                status,
                progressPercent,
                done
            } = resp.data;

            if (done) {
                getResults(workflowId);
                dist();
                if (document.getElementById("start_with_distributions") != null) {
                    document.getElementById("start_with_distributions").style.visibility = "visible";
                    document.getElementById("start_with_distributions").style.display = "block";
                    document.getElementById("slider-dist-button").style.visibility = "hidden";
                    document.getElementById("slider-dist-button").style.display = "none";
                    document.getElementById("burst-instructions").style.visibility = "hidden";
                    document.getElementById("burst-instructions").style.display = "none";
                    document.getElementById("no-burst-tracker-message").style.visibility = "hidden";
                    document.getElementById("no-burst-tracker-message").style.display = "none";
                    document.getElementById("next-set").style.visibility = "hidden";
                    document.getElementById("next-set").style.display = "none";
                    document.getElementById("prev-set").style.visibility = "hidden";
                    document.getElementById("prev-set").style.display = "none";
                }
                return;
            }
            setworkflowId(workflowId);
            setProgStatus(status);
            setPercent(progressPercent)

            props.sharedStore.dispatch({
                type: 'SET_WORKFLOWID',
                value: workflowId  // for lasp download
            });


            timerRef.current = setTimeout(() =>
               tick(workflowId), Constants.PROGRESS_POLL_INTERVAL);
             })
        .catch(error => {
            const message = "An unknown error occurred when checking on the workflow.";
            const redirect = navigate(`/error/${message}`);
        });
    }


  /**
     * Get the results from the backend and redirect to the result screen when
     * complete.
     *
     * Pre-Condition: The `poll_workflow` endpoint returned done=true
     */
    function getResults(workflowId) {
        // Set onDownloadProgress callback for updating the progress bar with
        // the download progress.
        const progressPercentAtStart = progressPercent;
        const remaining = 100 - progressPercentAtStart;

        const onDownloadProgress = (event) => {
            if (event.lengthComputable) {
                const fraction = event.loaded / event.total;
                const progressPercent = Math.round(
                    progressPercentAtStart + fraction * remaining);

                setState({progressPercent});
            }
        };

        // Call the `get_workflow_results` endpoint.
        setState({status: "Transferring plot data to your computer"});
        axios
        .get(`api/get_workflow_results?workflow_id=${workflowId}`, {onDownloadProgress})

        .then(resp => {
            const {results} = resp.data;

            if (!results) {
                const faux = tooMuchData('file transfer'); // file transfer failed (usually Chrome)
                props.sharedStore.dispatch({
                    type: 'SET_RESULTS',
                    value: faux
                });
            }
            else {
                props.sharedStore.dispatch({
                    type: 'SET_RESULTS',
                    value: results
                });
            }

            setTimeout(() => {
                var prep = JSON.stringify(criteria)

                props.setLoadingData(false);
                props.setPlotlyInnerElement('');
                // const redirect =  navigate( '/results', {state:{id:12,criteria:prep}});
                // setState(redirect);
            }, 100);
        })
          .catch(error => {
           const faux = tooMuchData('client processing');  // client side processing failed
                props.sharedStore.dispatch({
                    type: 'SET_RESULTS',
                    value: faux
                });

                setTimeout(() => {
                    var prep = JSON.stringify(criteria)
                    props.setLoadingData(false);
                    props.setPlotlyInnerElement('');
                    // const redirect =  navigate( '/results', {state:{id:12,criteria:prep}});
                    //     setState({redirect});
                }, 100);
        });
    }
   function dist() {
        //This is hardcoded temporarily to get the distribution to display
        //needs to be eventually changed to this api/get_workflow_results_dist?workflow_id=${this.workflowId}
        // What happens is:
        // this bogus workflow id still allows the following code to return
        // var", 'IONS_MMS3_perp1_perp2__2015-12-28_05:58:46.000000_500.json
        // which then gets stored in shared stored
        axios
        .get(`api/get_workflow_results_dist?workflow_id=fpi_web_vis-2022-04-14T18:29:08.939557_c60d9314`)
        .then(resp => {
            const {dist} = resp.data;

            if (!dist) {
                const faux = this.tooMuchData('file transfer'); // file transfer failed (usually Chrome)
                props.sharedStore.dispatch({
                    type: 'SET_DIST',
                    value: faux
                });
            }
            else {
                props.sharedStore.dispatch({
                    type: 'SET_DIST',
                    value: dist
                });
            }

        })
        .catch(error => {
            const message = "An unknown error occurred when checking on the workflow.";
        });
        return;
    }


    function reduceUrl() {
        var prep = JSON.stringify(criteria)
        var jsc = JSON.parse(prep);
        // for each list, combine all of the 2char ids into one string
        VARIABLES.categories.forEach(category => {
            var reduce = jsc['arrayorder'][category.title];
            var inside = ""
            for (var i = 0; i < reduce.length; ++i) {
                inside = inside + reduce[i]
            }
            jsc['arrayorder'][category.title] = inside;
        });
        //Create the URL
        const shortenedUrl = "/#/results/" + JSON.stringify(jsc);
        return shortenedUrl;
    }
    startWorkflow
  return (
        <div class="item plot" id="contains graph div">
            <div className='plotly-placement progress-bar-container' id="plotly-results">
                <h7> {status}</h7>
                <LinearProgress
                variant="determinate"
                value={progressPercent} />
            </div>
        </div>
  );
}

export default ProgressScreen;
