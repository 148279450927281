
import Constants from "./Constants";

/**
 * Static method utilites used throughout components.
 */

    /**
     * Get the secondary splitter pane initial size.
     *
     * @returns integer size in pixels
     */
    const getSecondaryInitialSize =  () => {
        const ifNotStored = Constants.MIN_SIDEBAR_WIDTH;
        const stored = parseInt(localStorage.getItem('splitPos'), 10);

        return stored || ifNotStored;
    }

    /**
     * Callback for when the secondary pane size changes.
     * @param size Size in pixels.
     */
    const onSecondaryPaneSizeChange =  (size) => {
        localStorage.setItem('splitPos', size);
    }

    const areWeTestingWithJest = () => {
        return process.env.JEST_WORKER_ID !== undefined;
    }

    const exportedCrap  = {
        getSecondaryInitialSize,
        onSecondaryPaneSizeChange,
        areWeTestingWithJest
     };

    export default exportedCrap;

