import React, { Component, useState } from 'react';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ToggleButton from '@mui/material/ToggleButton';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from '@mui/material/Tooltip';
import DatePicker from "react-datepicker";
import InputLabel from '@mui/material/InputLabel';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import moment, { duration } from "moment";
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';




// npm install @material-ui/core
/* Here is the documentation for the datepicker:
        https://reactdatepicker.com/#example-17
 */
import 'react-datepicker/dist/react-datepicker.css';

import Constants from "./Constants";
import './NewControlForm.css';
import Sortable from 'sortablejs';
import axios from "axios";
import Utils from "./Utils";
import TextField from "@mui/material/TextField";
//import MuiTable from './MuiTable.js';
import SITLSearch from "./SITLSearch.js";


import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Grid';


const EVENTS = require('./events.json');
const VARIABLES = require('./variables.json');
const DEFAULT_INCLUDE_DATES = require('./include_dates');
const options = Constants.SITL_SUGGEST;
let selectedDate = {
    displayTime: '2015-10-16 12:00',
    date: new Date('Fri Oct 16 2015 05:00:00 GMT-0700 (Pacific Daylight Time)'),
    duration: 2
};

let selectedVariables = {
    selectAllMult: false,
    selectAllSing: false
}

/**
 * Control form is a component that is embedded in the LandingPage and the ResultsScreen.
 * It navigates to 2 other pages but does so with callbacks (functions) passed to it as props.
 * So as such it does not need to itself navigate anywhere. So that is why it remains in it's
 * original condition: class component.
 * It displays the criteria selection for the visualizer. Includes
 * fields such as spacecraft, start time, variables, etc.
 *
 * @prop onGenerateClick :: Callback function that accepts an object of form
 *                          state, called when the form is submitted. (Big Green Button)
 * @prop toNewMuiClick   :: Callback function that accepts an object of form
 *                          state, and sitl search data (when the sitl search query is selected)
 *                          (this functional component, replaces old sitl searchpage class component)
 * @prop defaults        :: (Optional) Default values for the form. Uses same
 *                          keys as the form state passed to `onGenerateClick`.
 */

class ControlFormVariables extends Component {
    constructor(props) {
        super(props);
        this.newList = {};
        //Below goes to variables
        this.onGenerateClick = this.onGenerateClick.bind(this);
        //Below goes to varaibles
        this.CaptureListStuff = this.CaptureListStuff.bind(this);
        //Below is time
        //this.onRowClick = this.onRowClick.bind(this);
        //Below is time
        //this.IncludeDates = this.importDatesToInclude();
        const arrayorder = {};
        // maintain the DatePicker yearDropDown
        //this.yearRange = new Date()
        // this.yearRange.setFullYear(this.yearRange.getFullYear() + 1);

        // Handle long and short(url) variable names
        if (VARIABLES.options.idformat === 'long') {
            VARIABLES.categories
                .filter(variable => variable.full_id)
                .forEach(variable => {
                    variable.id = variable.full_id;
                    variable.selectionAlsoSelects = variable.full_selectionAlsoSelects;
                })
        };

        VARIABLES.categories.forEach(category => {
            arrayorder[category.title] = [];
        });

        console.log('selectedVariables', selectedVariables);

        this.state = {
            spacecraft: 1,
            duration: 2,
            event: "none",
            arrayorder,
            startTime: new Date(Constants.DEFAULT_START_TIME),
            endTime: "none",
            variables: this.getDefaultVariables(),
            selectAll: false,
            selectAllMult: selectedVariables.selectAllMult ? true : false,
            selectAllSing: selectedVariables.selectAllSing ? true : false,
            fst: 0,
            bst: 1,
            gsm: 1,

            // // SITL Comments parms:
            // context: 'landingpage',
            // initialSearchQuery: props.initialSearchQuery || "",
            // searchbox_label: props.searchbox_label || "SITL Comment:"
        };
        if (props.defaults) {
            for (let key in props.defaults) {
                if (this.state[key] !== null) {
                    this.state[key] = props.defaults[key];
                }
            }
        }

    } // end contructor

    /**
     * Called immediately after the component is added to the DOM.
     */


    // string of 2char pieces, convert to 2 char string array -- reduce URL size
    restoreArrayOrder(currentList) {
        var inside = [];
        var temp = "";
        for (let i = 0; i < currentList.length; ++i) {
            if (i > 0 && i % 2 !== 0) {
                temp += currentList[i];
                inside.push(temp);
                temp = "";
            } else {
                temp += currentList[i];

            }

        }
        return inside;
    }

    getDefaultVariables() {
        const defaultVariables = [];
        VARIABLES.categories.forEach(category => {
            category
                .variables
                .filter(variable => variable.defaultEnabled)
                .forEach(variable => {
                    defaultVariables.push(variable.id);
                });
        })
        return defaultVariables;
    }

    // 1. Arrange (indented) component-variables as needed
    // 2. create sortablejs list
    CaptureListStuff(title) {
        var tobe_resorted = document.getElementById(title)
        VARIABLES.categories.forEach(category => {
            category.variables
                .filter(variable => variable.selectionAlsoSelects && variable.selectionAlsoSelects.length > 1)
                .forEach(variable => {
                    // create our orderedlist element
                    var elem = document.createElement("ol");
                    elem.setAttribute('class', 'indent');
                    // move all of the component variables
                    variable.selectionAlsoSelects.forEach(item => {
                        elem.appendChild(document.getElementById(item))
                    });
                    // attach them to the parent
                    var parent = document.getElementById(variable.id);
                    parent.appendChild(elem);
                }
                )
            this.newList[title] = new Sortable(tobe_resorted);

        })
    }

    componentDidMount() {

        // this.sitl_present = this.get_latest_sitl_date();
        /* This disables the ability to edit the date directly
        const datePicker = document.getElementsByClassName("new-date-picker")[0];
        const input = datePicker.children[0].children[0].children[0];
        input.setAttribute('readonly', true);
        input.setAttribute('type', 'button');
         */
        VARIABLES.categories.forEach(category => {
            this.CaptureListStuff(category.title);
        });
        /* critical for using SITL start time... */
        // if (this.props.startTime) {
        //     this.setState({startTime: this.props.startTime});
        // }
        // if (this.props.initialSearchQuery != null && this.props.initialSearchQuery != "" ) {
        //     this.setState({searchQuery: this.props.initialSearchQuery});
        //     console.log("reset searchQuery to: " + this.state.searchQuery);
        // }
    }
    /**
     * Toggle variable selected. Called when checkboxes are clicked`
     *
     * @param variableId Identifier of variable within `this.state.variables`
     *
     * This now has code to uncheck and un-include corresponding magnitude and
     * vector checkboxes (M V)
     *
     * */

    toggleVariableSelected(variableId) {
        var variables = this.state.variables.slice();
        document.getElementById("no-var-warn").style.display = "none";
        //selecting a protocol 
        if (variables.includes(variableId)) {// if it is being unselected:
            this.state.selectAllMult = false;
            this.state.selectAllSing = false;
            selectedVariables.selectAllMult = false;
            selectedVariables.selectAllSing = false;
            this.setState({ selectAll: false }, () => { this.props.holdNewCriteria(this.state); });
            var buttonState = this.state.selectAll;

            VARIABLES.categories.forEach(category => {
                category.variables.filter(variable => variable.id === variableId)
                    .forEach(variable => {
                        variables = variables.filter(elem => elem !== variableId);
                    })
            });

        } else { // if it is being selected/checked:


            VARIABLES.categories.forEach(category => {
                category.variables.filter(variable => variable.id === variableId)
                    .forEach(variable => {
                        variables.push(variableId);
                        // Display minion case. The main variable itself has no plotPanels. Only it's minions
                        if (variable.selectionAlsoSelects) {
                            variable.selectionAlsoSelects.forEach(also => {
                                document.getElementById(also).checked = false;
                                variables = variables.filter(elem => elem !== also);
                            })
                        }
                    })

            });

        }

        this.setState({ buttonState }, () => { this.props.holdNewCriteria(this.state); });
        this.setState({ variables }, () => { this.props.holdNewCriteria(this.state); });
    }

    /**
     * Determines if user has selected at least one var
     * @param passed
     * @returns {boolean}
     */
    noneSelected(passed) {
        console.log('passed', passed)
        if (passed.variables.length < 2) {
            for (let i = 0; i < passed.variables.length; ++i) {
                if (passed.variables != VARIABLES.categories[0].variables[0].id) {
                    return false;
                }
            }
            return true;
        }
        return false
    }

    /**
     * Returns boolean whether the variable is selected.
     *
     * @param variableId Identifier of variable within `this.state.variables`
     * @returns Whether or not variable is included in `this.state.variabes`
     */
    isVariableSelected(variableId) {
        return this.state.variables.includes(variableId);
    }

    /**
     * Callback for when the generate plots button is clicked.
     */
    onGenerateClick() {
        if (this.props.onGenerateClick) {
            // Copy the state into a local variable
            const formState = Object.assign({}, this.state);


            // Sort enabled variables list to be the same order as the
            // variable.json
            var sortedVariables = [];


            // if using bookmark, restore shortened string to array
            if (formState.variables.constructor !== Array) {
                formState.variables = this.restoreArrayOrder(formState.variables);
            }

            // Burst tracker is always first
            if (this.state.bst) {
                if (formState.variables[0] == VARIABLES.categories[0].variables[0].id) {
                    sortedVariables.push(formState.variables[0])
                } else {
                    sortedVariables.push(VARIABLES.categories[0].variables[0].id)
                }
            }

            if (this.noneSelected(formState)) {
                document.getElementById("no-var-warn").style.display = "block";
                return;
            }


            VARIABLES.categories.forEach(category => {
                // Notice how we save state here. We assign a variable to the state
                // variable and then save that variable at the end.
                var allVariables = this.state.arrayorder[category.title];
                // if using bookmark, restore shortened string to array
                if (allVariables.constructor !== Array) {
                    allVariables = this.restoreArrayOrder(allVariables);
                    // perhaps since allVariables is set to only part of array order I can set the whole thing:
                    //this.state.arrayorder[category.title]  = allVariables;//not sure I can change this partial setstate
                    this.setState({ arrayorder: allVariables }, () => { this.props.holdNewCriteria(this.state); }) //not sure I can change this partial setstate
                }
                if (this.newList[category.title]) {

                    // This is the current user moved around order
                    const thisList = this.newList[category.title].el.childNodes;

                    for (let j = 0; j < thisList.length; ++j) {
                        let variable_id = thisList[j].id;
                        if (formState.variables.includes(variable_id)) {
                            sortedVariables.push(variable_id);
                        }
                        // check for checked indented variables in this independent list:
                        // It needs to be done here to maintain the thisList order.
                        category.variables.forEach(variable => {
                            if (variable.selectionAlsoSelects && variable_id === variable.id) {
                                variable.selectionAlsoSelects.forEach(item => {
                                    if (formState.variables.includes(item)) {
                                        sortedVariables.push(item);
                                    }
                                });
                            }
                        });
                        allVariables[j] = variable_id;
                    }
                    this.setState({ allVariables }, () => { this.props.holdNewCriteria(this.state); });

                }
                // This is done after the each subsequent plot if the user has changed the order
                // Actually I am not sure this is ever useiabiad...
                else if (this.state.arrayorder[category.title].length > 0) {
                    sortedVariables.push(formState.variables[0])
                    for (var k = 0; k < this.state.arrayorder[category.title].length; ++k) {
                        if (formState.variables.includes(this.state.arrayorder[category.title][k])) {
                            sortedVariables.push(this.state.arrayorder[category.title][k]);
                        }
                    }
                }
            });

            // if (this.noneSelected(sortedVariables)) {
            //     document.getElementById("no-var-warn").style.display = "block";
            //     return;
            // }

            formState.variables = sortedVariables;
            formState.startTime = selectedDate.date;
            formState.duration = selectedDate.duration;
            formState.units = (selectedDate.duration > 6 ? 'minutes' : (selectedDate.duration === 1 ? 'hour' : 'hours'));

            formState.zd = 0; //zd is timezone but with new forms it should be 0
            console.log('formState', formState);
            this.props.onGenerateClick(formState);
        }
    }

    // To do this after plot I must do it each time
    // I can wait until the first plot has been done
    // to re-order the list on every state change (selection)
    sortableorder(listitems, category) {
        if (this.state.variables && this.state.arrayorder) {
            var currentList = this.state.arrayorder[category];
            // if using bookmark with shortened arrayorder conv to string restore:
            if (!currentList) return; // on it's way out to go back to progressScreen with bookmarke currentList isn't available.
            if (currentList.constructor !== Array) {
                currentList = this.restoreArrayOrder(currentList); //restore not reducedUrl state
            }
            if (currentList.length > 0) {
                for (var i = 0; i < listitems.length; ++i) {
                    var hasN = this.nextInSortableOrder(listitems, currentList[i])
                    if (hasN) {
                        var tmp = listitems[i];
                        listitems[i] = listitems[hasN];
                        listitems[hasN] = tmp;
                    } else {
                        //console.log(this.state.arrayorder[category][i]);
                    }
                }
            }
        }
    }

    nextInSortableOrder(mylist, current) {
        if (!current) {
            return;
        }
        for (var i = 0; i < mylist.length; ++i) {
            if (mylist && mylist[i].key === current) {
                return i;
            }
        }
    }

    // New function for setting mms value
    mmsSelect(value) {
        this.setState({ spacecraft: value }, () => { this.props.holdNewCriteria(this.state); });
    }


    /*
    Use 1 and 0 instead of true false for a shorterUrl
     */
    selectFastOnly(cbstate) {
        if (cbstate.target.checked) {
            this.setState({ fst: 1 }, () => { this.props.holdNewCriteria(this.state); })
        } else {
            this.setState({ fst: 0 }, () => { this.props.holdNewCriteria(this.state); })
        }
    }
    selectBurstTracker(cbstate) {
        if (cbstate.target.checked) {
            this.setState({ bst: 1 }, () => { this.props.holdNewCriteria(this.state); })
        } else {
            this.setState({ bst: 0 }, () => { this.props.holdNewCriteria(this.state); })
        }
    }
    selectGSM(cbstate) {
        if (cbstate.target.checked) {
            this.setState({ gsm: 1 }, () => { this.props.holdNewCriteria(this.state); })
        } else {
            this.setState({ gsm: 0 }, () => { this.props.holdNewCriteria(this.state); })
        }
    }

    /* for selectAll buttons */
    selectThemAll(cbstate, filter) {
        var variables = this.state.variables.slice();
        //const checkedVariables = {};
        // if (cbstate.target.checked === false) {
        //     // if unchecking, we want to keep burst variable
        //     // if checking, we don't need burst variable
        //     checkedVariables.push(variables[0]);
        // }

        VARIABLES.categories.forEach(category => {
            if (filter === "none" || filter === category.title) {
                category.variables.forEach(variable => {
                    // Don't select by default indented variables
                    if (!(variable.selectionAlsoSelects &&
                        variable.selectionAlsoSelects.length === 1)) {
                        if (cbstate.target.checked) {
                            variables.push(variable.id);
                        } else {
                            variables = variables.filter(e => e !== variable.id);
                        }
                    }
                })
            }
        });

        console.log('some reason we are here', this.state.selectAllSing, this.state.selectAllMult)
        //variables = checkedVariables
        if (filter === "none") {
            this.setState({ selectAll: cbstate.target.checked })
            console.log('We are in none');
        }
        if (filter === "none" || filter === "Single-Spacecraft") {
            this.setState({ selectAllSing: cbstate.target.checked })
            selectedVariables.selectAllSing = this.state.selectAllSing;
        }
        if (filter === "none" || filter === "Multi-Spacecraft") {
            this.setState({ selectAllMult: cbstate.target.checked })
            selectedVariables.selectAllMult = this.state.selectAllMult;
        }
        var buttonState = this.state.selectAll;
        this.setState({ buttonState });
        this.setState({ variables }, () => this.props.holdNewCriteria(this.state));
    }

    render() {
        /* hide this warning as soon as they select a checkbox
           Build list of elements for the variable picker */
        const variableSingleCategoryLists = VARIABLES.categories.map(category => {
            const listItems = category.variables
                .filter(variable => variable.displayLabel !== null && category.title === "Single-Spacecraft")
                .map(variable =>
                    <li class={variable.indent}
                        id={variable.id}
                        style={{ listStyle: 'none', display: 'block' }}
                        key={variable.id}>
                        <input
                            type="checkbox"
                            color="#6699cc"
                            className='multiselect-checkbox'
                            id={variable.id}
                            checked={this.isVariableSelected(variable.id)}
                            onChange={() => this.toggleVariableSelected(variable.id)}
                        />
                        <span
                            className='multiselect-text'
                            onClick={() => this.toggleVariableSelected(variable.id)}
                        >
                            {variable.displayLabel}
                        </span>
                    </li>
                );
            this.sortableorder(listItems, category.title);
            return (
                <div key={"display_sortable_list"}>
                    <ol id="Single-Spacecraft">
                        {listItems}
                    </ol>
                </div>
            );

        });

        const variableMultipleCategoryLists = VARIABLES.categories.map(category => {
            const listItems = category.variables
                .filter(variable => variable.displayLabel !== null && category.title === "Multi-Spacecraft")
                .map(variable =>
                    <li class={variable.indent}
                        id={variable.id}
                        style={{ listStyle: 'none', display: 'block' }}
                        key={variable.id}>
                        <input
                            type="checkbox"
                            color="#6699cc"
                            className='multiselect-checkbox'
                            id={variable.id}
                            checked={this.isVariableSelected(variable.id)}
                            onChange={() => this.toggleVariableSelected(variable.id)}
                        />
                        <span
                            className='multiselect-text'
                            onClick={() => this.toggleVariableSelected(variable.id)}
                        >
                            {variable.displayLabel}
                        </span>
                    </li>
                );
            this.sortableorder(listItems, category.title);
            if (listItems.length !== 0) {
                return (
                    <div key={"display_sortable_list"}>
                        {/* <span style={{fontSize: '14px'}}>
                                    Multi-Spacecraft
                               </span> */}
                        {/* <LiveHelpOutlinedIcon
                            style={{
                                height: '14px',
                                cursor: 'text',
                                color: '#6699cc'
                            }} /> */}

                        <ol id="Multi-Spacecraft">
                            {listItems}
                        </ol>
                    </div>
                );
            }
            return;

        });

        // Return component body
        return (
            <React.Fragment>
                <div className='drawer-header'>
                    <span className={"grid-title"}>
                        <h1>Variables</h1>
                        <span>Select variables to apply to the selected datetime: </span>
                        <span>{selectedDate.displayTime}</span>
                    </span>
                    <button aria-label="Close date selector drawer"
                        className='close-drawer-button'
                        onClick={() => this.props.closeDrawer()}></button>
                </div>
                <Grid
                    className='drawer-grid'
                    container
                    columnSpacing={3}
                    style={{
                        marginLeft: '16px',
                        marginRight: '30px',
                        marginTop: '25px',
                        width: 'auto',
                        height: '100%'
                    }}>

                    <Grid item xs={12} sm={12} md={4} lg={2} xl={2} >
                        <div className={"item-border item-container selection-container"}>
                            <h2 className='subheader'>Spacecraft</h2>
                            <FormControl className='multiselect-container no-selectall'>
                                <RadioGroup
                                    aria-labelledby="Space Craft selection"
                                    value={this.state.spacecraft}
                                    onChange={(event) => this.mmsSelect(event.target.value)}
                                >
                                    <FormControlLabel style={{ marginLeft: '10px' }} value={1} control={<Radio color="default" size="small" />} label="MMS 1" className='spacecraft-radio' />
                                    <FormControlLabel style={{ marginLeft: '10px' }} value={2} control={<Radio color="default" size="small" />} label="MMS 2" className='spacecraft-radio' />
                                    <FormControlLabel style={{ marginLeft: '10px' }} value={3} control={<Radio color="default" size="small" />} label="MMS 3" className='spacecraft-radio' />
                                    <FormControlLabel style={{ marginLeft: '10px' }} value={4} control={<Radio color="default" size="small" />} label="MMS 4" className='spacecraft-radio' />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3.5} xl={3.5}>
                        <div className={"item-border item-container selection-container"}>
                            <h2 className='subheader'>Single-Spacecraft</h2>
                            <div className="no-var" id="no-var-warn" style={{ display: "none" }}>Please Select at least 1
                                variable
                            </div>
                            <div className='multiselect-container'>
                                <ol>
                                    <li style={{ listStyle: 'none', display: 'block' }}>
                                        <input
                                            type="checkbox"
                                            color="#6699cc"
                                            className='multiselect-checkbox'
                                            checked={this.state.selectAllSing}
                                            onClick={(event) => this.selectThemAll(event, "Single-Spacecraft")}
                                        />
                                        <span className='multiselect-text'>
                                            Select All
                                        </span>
                                    </li>
                                </ol>
                                {variableSingleCategoryLists}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3.5} xl={3.5}>
                        <div className={"item-border item-container selection-container"}>
                            <h2 className='subheader'>Multiple-Spacecraft</h2>
                            <div className="no-var" id="no-var-warn" style={{ display: "none" }}>Please Select a multi
                                variable
                            </div>
                            <div className='multiselect-container'>
                                <ol>
                                    <li style={{ listStyle: 'none', display: 'block' }}>
                                        <input
                                            type="checkbox"
                                            color="#6699cc"
                                            className='multiselect-checkbox'
                                            checked={this.state.selectAllMult}
                                            onClick={(event) => this.selectThemAll(event, "Multi-Spacecraft")}
                                        />
                                        <span className='multiselect-text'>
                                            Select All
                                        </span>
                                    </li>
                                </ol>
                                {variableMultipleCategoryLists}
                            </div>
                        </div>
                    </Grid>
                    <Grid item
                        xs={12} sm={12} md={4} lg={3} xl={3}
                    >
                        <div className={"item-border item-container add-button selection-container"}>
                            <h2 className='subheader'>Plot Options</h2>
                            <div className='multiselect-container no-selectall'>
                                <ol>
                                    <li style={{ listStyle: 'none', display: 'block' }}>
                                        <input
                                            type="checkbox"
                                            color="#6699cc"
                                            className='multiselect-checkbox'
                                            checked={this.state.fst}
                                            onClick={(event) => this.selectFastOnly(event)}
                                        />
                                        <span
                                            className='multiselect-text'
                                            onClick={(event) => this.selectFastOnly(event)}
                                        >
                                            Fast Only
                                        </span>
                                    </li>
                                    <li style={{ listStyle: 'none', display: 'block' }}>
                                        <input
                                            type="checkbox"
                                            color="#6699cc"
                                            className='multiselect-checkbox'
                                            checked={this.state.bst}
                                            onClick={(event) => this.selectBurstTracker(event)}
                                        />
                                        <span
                                            className='multiselect-text'
                                            onClick={(event) => this.selectBurstTracker(event)}
                                        >
                                            Include Burst Tracker
                                        </span>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <button className='generate-plot-btn' onClick={this.onGenerateClick}>Generate Plots <span class="rightarrow-form"></span></button>
                    </Grid>
                    {/* Generate Button */}


                </Grid >
            </React.Fragment>
        );

    }

}



class ControlFormDateTime extends Component {
    constructor(props) {
        super(props);
        //this.newList = {};
        //Below goes to variables
        //this.onGenerateClick = this.onGenerateClick.bind(this);
        //Below goes to varaibles
        //this.CaptureListStuff = this.CaptureListStuff.bind(this);
        //Below is time
        //this.onRowClick = this.onRowClick.bind(this);
        //Below is time
        this.triggerSearch = this.triggerSearch.bind(this);
        this.IncludeDates = this.importDatesToInclude();
        this.setSearchDateTime = this.setSearchDateTime.bind(this);
        const arrayorder = {};
        // maintain the DatePicker yearDropDown
        this.yearRange = new Date()
        // this.yearRange.setFullYear(this.yearRange.getFullYear() + 1);
        this.minDateObj = { year: 2015, month: 9, day: 1 };
        this.maxDateObj = { year: this.yearRange.getFullYear(), month: this.yearRange.getMonth(), day: this.yearRange.getDate() };
        this.initialLoad = true;
        // Handle long and short(url) variable names
        if (VARIABLES.options.idformat === 'long') {
            VARIABLES.categories
                .filter(variable => variable.full_id)
                .forEach(variable => {
                    variable.id = variable.full_id;
                    variable.selectionAlsoSelects = variable.full_selectionAlsoSelects;
                })
        };

        // VARIABLES.categories.forEach(category => {
        //     arrayorder[category.title] = [];
        // });
        this.state = {
            duration: 2,
            units: "hours",
            event: "none",
            startTime: new Date(Constants.DEFAULT_START_TIME),
            endTime: "none",
            context: 'landingpage',
            initialSearchQuery: "GSM",
            searchbox_label: props.searchbox_label || "SITL Comment:",
            searchQuery: props.searchQuery || props.initialSearchQuery || "",
            searchData: "none",
            sitl_present: null,
            dateObj: {
                showYear: selectedDate.date.getUTCFullYear(),
                year: selectedDate.date.getUTCFullYear(),
                month: selectedDate.date.getUTCMonth() + 1,
                day: selectedDate.date.getUTCDate(),
                time: (selectedDate.date.getUTCHours() < 10 ? '0' : '') + selectedDate.date.getUTCHours() + ':' + (selectedDate.date.getUTCMinutes() < 30 ? '00' : '30')
            },
            //calendar: this.createMonthsCalendar(true)
        };
        if (props.defaults) {
            for (let key in props.defaults) {
                if (this.state[key] !== null) {
                    this.state[key] = props.defaults[key];
                }
                if (key === 'startTime') {
                    selectedDate.date = new Date(props.defaults[key]);
                    this.state.dateObj = {
                        showYear: selectedDate.date.getUTCFullYear(),
                        year: selectedDate.date.getUTCFullYear(),
                        month: selectedDate.date.getUTCMonth() + 1,
                        day: selectedDate.date.getUTCDate(),
                        time: (selectedDate.date.getUTCHours() < 10 ? '0' : '') + selectedDate.date.getUTCHours() + ':' + (selectedDate.date.getUTCMinutes() < 30 ? '00' : '30')
                    };
                }
            }

            if (this.state.duration > 6) {
                this.state.units = "minutes"
            }
            else {
                this.state.units = "hours"
            }
            this.state.context = 'resultspage';
            this.state.initialSearchQuery = props.initialSearchQuery || props.defaults.initialSearchQuery;
        }
        this.state.calendar = this.createMonthsCalendar(true);


        // this.setState({dateObj: {year: 2024, month: 10, day: 16, time: '09:00'}});
        // this.setState({calendar: this.createMonthsCalendar(2024)});
    } // end contructor



    /*
   SITL Search sent over to MuiTable
    */
    triggerSearch(ev) {
        console.log("triggerSearch: " + this.state.searchQuery);
        axios.get('api/search_results?q=' + this.state.searchQuery)
            .then((response) => {
                const rows = response.data.brstsegs;
                var firstdata = [];
                for (var i = 0; i < rows.length; ++i) {
                    firstdata.push(this.createData(i, rows[i].comment, rows[i].first_buffer, rows[i].fom));
                }
                this.setState({ searchData: firstdata });
                const formState = Object.assign({}, this.state);
                console.log(' this.props', this.props);
                console.log('this.state', this.state);
                //this.props.toNewMuiClick(formState);
            });
    };
    createData(id, comment, time, fom) {
        return { id: id, comment, time, fom };
    };

    //Dont need this anymore
    // onRowClick(selectedTime) {
    //     this.setState({ startTime: selectedTime });
    // }

    setSearchDateTime(value) {
        //Saving this state for using for the varaibles
        this.setState({
            searchDate: value
        })
        this.setClanderToDate(value);
    }


    importDatesToInclude() {
        const dates = [];
        if (Utils.areWeTestingWithJest()) {
            dates.push(new Date());
            return dates;
        }

        axios.get(`api/include_dates?file=on_backend`
        ).then(resp => {
            if (resp.data.error) {
                const { message } = resp.data;
                const redirect = <Redirect to={`/error/${message}`} />
                this.setState({ redirect });
                return;
            }

            const {
                results
            } = resp.data;
            results.forEach(thisdate => {
                dates.push(new Date(thisdate));
            });
            this.get_latest_date(dates);

        })
            .catch(error => {
                if (!Utils.areWeTestingWithJest()) {
                    const message = "An unknown error downloading included dates. Using default set";
                    //console.log(message);
                }
                // if the file isn't under temp on the server, get this default set
                // which is shipped with the application:
                DEFAULT_INCLUDE_DATES.forEach(thisdate => {
                    var thisDate = new Date(thisdate);
                    var zd = this.calculate_zone(thisDate);
                    thisDate.setHours(thisDate.getHours() + zd);

                    dates.push(new Date(thisdate));
                });

            })
        return dates;

    }
    /**
    * Called immediately after the component is added to the DOM.
    */
    componentDidMount() {

        this.get_latest_sitl_date();
        /* This disables the ability to edit the date directly
        const datePicker = document.getElementsByClassName("new-date-picker")[0];
        const input = datePicker.children[0].children[0].children[0];
        input.setAttribute('readonly', true);
        input.setAttribute('type', 'button');
         */
        // VARIABLES.categories.forEach(category => {
        //     this.CaptureListStuff(category.title);
        // });
        /* critical for using SITL start time... */
        if (this.props.startTime) {
            this.setState({ startTime: this.props.startTime });
        }
        if (this.props.initialSearchQuery != null && this.props.initialSearchQuery != "") {
            this.setState({ searchQuery: this.props.initialSearchQuery });
            console.log("reset searchQuery to: " + this.state.searchQuery);
        }

    }

    get_latest_date(dates) {
        var latest = DEFAULT_INCLUDE_DATES.slice(-1).toString();
        if (dates && dates.length > 1) {
            latest = dates.slice(-1).toString();
        }
        this.setState({ present: latest });
    }

    get_latest_sitl_date() {
        var latest = this.present;
        axios.get('api/latest_sitl_date?q=' + this.initialSearchQuery)
            .then((response) => {
                latest = response.data.brstsegs[0].first_buffer;
                let aviation = this.getStartTimeForDatePicker(latest, true);
                this.setState({ sitl_present: aviation }); /* see import statement at top of page */
            });
    }

    getStartTimeForDatePicker = (datestring_from_row, alreadyGMT) => {

        var input = new Date(datestring_from_row);
        var zd = 0;
        if (!alreadyGMT) {
            zd = this.calculate_zone(input);
        }
        input.setHours(input.getHours() - zd);
        // First GMT
        // Then 2 hour setup.. if minutes > 30 then we'll do that hour
        input.setSeconds(0);
        var align_min = input.getMinutes();
        console.log(align_min);
        console.log("align_min: " + align_min);
        if (align_min > 0) {
            if (align_min > 30) {
                input.setMinutes(0);
            } else {
                input.setHours(input.getHours() - 1);
                input.setMinutes(0);
            }
        }
        return input;
    }


    calculate_zone(eventdate) {

        if (eventdate) {
            var myLocal = eventdate.getHours();
            var myUtc = eventdate.getUTCHours();
            if (myUtc <= 4) {
                myUtc += 24;
            }
            return myLocal - myUtc;
        }
        return 0
    }


    // when dateObj is changed inorder for it to be reflected in the clander this function must be called in the callback
    // ex: this.setState({dateObj: obj}, () => {this.createMonthsCalendar(true)});
    createMonthsCalendar(scrollToDate) {
        let leapYear = (this.state.dateObj.showYear % 4 === 0);
        let startDate = moment(`${this.state.dateObj.showYear}-01-01`, "YYYY-MM-DD");
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let monthStartDay = days.indexOf(startDate.format('dddd'));
        let calendarObj = {
            'January': { 'daycount': 31, 'display': [], 'num': 1 },
            'February': { 'daycount': (leapYear === true ? 29 : 28), 'display': [], 'num': 2 },
            'March': { 'daycount': 31, 'display': [], 'num': 3 },
            'April': { 'daycount': 30, 'display': [], 'num': 4 },
            'May': { 'daycount': 31, 'display': [], 'num': 5 },
            'June': { 'daycount': 30, 'display': [], 'num': 6 },
            'July': { 'daycount': 31, 'display': [], 'num': 7 },
            'August': { 'daycount': 31, 'display': [], 'num': 8 },
            'September': { 'daycount': 30, 'display': [], 'num': 9 },
            'October': { 'daycount': 31, 'display': [], 'num': 10 },
            'November': { 'daycount': 30, 'display': [], 'num': 11 },
            'December': { 'daycount': 31, 'display': [], 'num': 12 }
        }
        for (let month in calendarObj) {
            let weekCount = 0;
            let weekHTML = [];
            for (let i = 0; (i < calendarObj[month]['daycount'] + monthStartDay) || !(weekCount === 0); i++) {
                let dayNumber = i + 1 - monthStartDay;
                if ((i < monthStartDay && monthStartDay > weekCount) || i >= calendarObj[month]['daycount'] + monthStartDay) {
                    weekHTML.push(<div className='day blank'></div>);
                    weekCount++;
                } else {
                    let notInRange = ((this.maxDateObj.year <= this.state.dateObj.showYear)
                        && (((this.maxDateObj.month === calendarObj[month]['num'])
                            && (this.maxDateObj.day < dayNumber)) || this.maxDateObj.month < calendarObj[month]['num']))
                        || ((this.minDateObj.year >= this.state.dateObj.showYear)
                            && (((this.minDateObj.month === calendarObj[month]['num'])
                                && (this.minDateObj.day > dayNumber)) || this.minDateObj.month > calendarObj[month]['num']));
                    weekHTML.push(<button
                        className={'day ' + (((this.state.dateObj.day === dayNumber) &&
                            (calendarObj[month]['num'] === this.state.dateObj.month) &&
                            (this.state.dateObj.showYear === this.state.dateObj.year)) ? 'slectedDay' : '')}
                        onClick={() => {
                            let obj = this.state.dateObj;
                            obj.month = calendarObj[month]['num'];
                            obj.day = dayNumber;
                            obj.year = this.state.dateObj.showYear;
                            let time = this.state.dateObj.time.split(':');
                            selectedDate.date = new Date(Date.UTC(obj.year, obj.month - 1, obj.day, time[0], time[1]));
                            this.setState({ startTime: selectedDate.date }, () => { this.props.holdNewCriteria(this.state); });
                            this.setState({ dateObj: obj }, () => { this.createMonthsCalendar(false) });
                        }}
                        disabled={notInRange}
                    >
                        {dayNumber}
                    </button>);
                    weekCount++;
                }
                if (weekCount === 7) {
                    calendarObj[month]['display'].push(<div className='week'>{weekHTML}</div>);
                    weekCount = 0;
                    weekHTML = [];
                }
            }
            monthStartDay = (monthStartDay + calendarObj[month]['daycount']) % 7;
        }
        calendarObj.days = (<div className='week'><div className='day'>S</div>
            <div className='day letter'>M</div>
            <div className='day letter'>T</div>
            <div className='day letter'>W</div>
            <div className='day letter'>T</div>
            <div className='day letter'>F</div>
            <div className='day letter'>S</div></div>);
        this.setState({ calendar: calendarObj }, () => {
            if (this.state.dateObj.showYear === this.state.dateObj.year && scrollToDate) {
                this.scrollToSelected()
            }
        });
        return calendarObj;
    }

    // used to create selectable time in date time section 
    createTimeDisplay() {
        let timeDisplay = []
        for (let x = 0; x < 24; x++) {
            if (x < 10) {
                timeDisplay.push(<button
                    className={'timeDisplay ' + (this.state.dateObj.time === '0' + x + ':00' ? 'selectedTime' : '')}
                    onClick={() => {
                        let obj = this.state.dateObj;
                        obj.time = '0' + x + ':00';
                        selectedDate.date = new Date(Date.UTC(obj.year, obj.month - 1, obj.day, '0' + x, '00'));
                        this.setState({ startTime: selectedDate.date }, () => { this.props.holdNewCriteria(this.state); });
                        this.setState({ dateObj: obj });
                    }}
                >{'0' + x + ':00'}</button>);
                timeDisplay.push(<button
                    className={'timeDisplay ' + (this.state.dateObj.time === '0' + x + ':30' ? 'selectedTime' : '')}
                    onClick={() => {
                        let obj = this.state.dateObj;
                        obj.time = '0' + x + ':30';
                        selectedDate.date = new Date(Date.UTC(obj.year, obj.month - 1, obj.day, '0' + x, '30'));
                        this.setState({ startTime: selectedDate.date }, () => { this.props.holdNewCriteria(this.state); });
                        this.setState({ dateObj: obj });
                    }}
                >{'0' + x + ':30'}</button>);
            } else {
                timeDisplay.push(<button
                    className={'timeDisplay ' + (this.state.dateObj.time === x + ':00' ? 'selectedTime' : '')}
                    onClick={() => {
                        let obj = this.state.dateObj;
                        obj.time = x + ':00';
                        selectedDate.date = new Date(Date.UTC(obj.year, obj.month - 1, obj.day, x, '00'));
                        this.setState({ startTime: selectedDate.date }, () => { this.props.holdNewCriteria(this.state); });
                        this.setState({ dateObj: obj });
                    }}>{x + ':00'}</button>);
                timeDisplay.push(<button
                    className={'timeDisplay ' + (this.state.dateObj.time === x + ':30' ? 'selectedTime' : '')}
                    onClick={() => {
                        let obj = this.state.dateObj;
                        obj.time = x + ':30';
                        selectedDate.date = new Date(Date.UTC(obj.year, obj.month - 1, obj.day, x, '30'));
                        this.setState({ startTime: selectedDate.date }, () => { this.props.holdNewCriteria(this.state); });
                        this.setState({ dateObj: obj });
                    }}
                >{x + ':30'}</button>);
            }
        }
        return timeDisplay;
    }

    // sets Clander to reflect new date selected through featured events or SITL search
    // newDateInput parm must work with new Date function. ex: 2015-09-19T07:43:30Z
    setClanderToDate(newDateInput, exactTime) {
        let newDataObj = this.state.dateObj;
        if (exactTime) {
            selectedDate.date = new Date(newDateInput);
        } else {
            selectedDate.date = new Date(moment(newDateInput).add(-(selectedDate.duration / 2), this.state.units));
        }
        newDataObj.year = selectedDate.date.getUTCFullYear();
        newDataObj.showYear = selectedDate.date.getUTCFullYear();
        newDataObj.month = selectedDate.date.getUTCMonth() + 1;
        newDataObj.day = selectedDate.date.getUTCDate();
        newDataObj.time = (selectedDate.date.getUTCHours() < 10 ? '0' : '') + selectedDate.date.getUTCHours() + ':' + ((selectedDate.date.getUTCMinutes() < 15 || selectedDate.date.getUTCMinutes() > 45) ? '00' : '30');
        let time = newDataObj.time.split(':');
        selectedDate.date = new Date(Date.UTC(newDataObj.year, newDataObj.month - 1, newDataObj.day, time[0], time[1]));
        this.setState({ dateObj: newDataObj }, () => { this.createMonthsCalendar(true); });
        this.setState({ startTime: selectedDate.date }, () => { this.props.holdNewCriteria(this.state); });
        return;
    }

    // Scroll to selected values on clander.
    scrollToSelected(attempt) {
        this.initialLoad = false;
        attempt = attempt || 1;
        // used to move selected time and date into view without having to scroll.
        let scrollToDay = document.getElementsByClassName("slectedDay")[0];
        if (scrollToDay) {
            scrollToDay.scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
            if (attempt < 10) {
                // put in recursive timeout with limit of 10 because element doesnt exist on the first call of this function.
                setTimeout(() => {
                    this.scrollToSelected(attempt + 1);
                }, 100);
            }
        }
        let scrollToTime = document.getElementsByClassName("selectedTime")[0];
        if (scrollToTime) {
            setTimeout(() => {
                scrollToTime.scrollIntoView({ behavior: "smooth", block: "center" });
            }, 700);
        } else {
            if (attempt < 10) {
                setTimeout(() => {
                    this.scrollToSelected(attempt + 1);
                }, 100);
            }
        }
        return;
    }

    render() {
        // Build list of elements for the events picker
        const eventMenuItems = [];
        EVENTS.events.forEach(eventObj => {
            const menuItem = (
                <ListItemButton
                    className={((new Date(eventObj.displayLabel) > selectedDate.date && new Date(eventObj.displayLabel) < moment(selectedDate.date).add(selectedDate.duration, this.state.units)) ? 'slectedTime' : '') + ' featuredEventButton'}
                    value={eventObj.key}
                    key={eventObj.key}
                    onClick={() => {
                        this.setState({ event: eventObj.key });
                        this.setClanderToDate(eventObj.displayLabel);
                    }
                    }>
                    <ListItemText className='featuredListText'>
                        {eventObj.key} - {eventObj.displayLabel}
                    </ListItemText>
                </ListItemButton>
            );
            eventMenuItems.push(menuItem);
        });

        let timeDisplay = this.createTimeDisplay()
        selectedDate.displayTime = (this.state.dateObj.year + '-' +
            (this.state.dateObj.month < 10 ? '0' : '') + this.state.dateObj.month + '-' +
            (this.state.dateObj.day < 10 ? '0' : '') + this.state.dateObj.day + ' ' + this.state.dateObj.time);
        if (this.initialLoad) {
            this.scrollToSelected();
        }
        return (
            <React.Fragment>
                <div className='drawer-header'>
                    <span className={"grid-title"}>
                        <h1>Datetime</h1>
                        <span>Select the date and time from the options below</span>
                        <span onClick={() => {
                            let obj = this.state.dateObj;
                            obj.showYear = this.state.dateObj.year;
                            this.setState({ dateObj: obj }, () => {
                                this.createMonthsCalendar(true)
                            });
                        }}>{selectedDate.displayTime}</span>
                    </span>
                    <button aria-label="Close date selector drawer"
                        className='close-drawer-button'
                        onClick={() => this.props.closeDrawer()}></button>
                </div>
                <Grid
                    className='drawer-grid'
                    container
                    columnSpacing={3}
                    style={{
                        marginLeft: '16px',
                        marginRight: '30px',
                        marginTop: '25px',
                        width: 'auto',
                        height: '100%'
                    }}>

                    <Grid item xs={12} sm={12} md={3} lg={2.7} xl={2.7} >
                        <div className={"item-border item-container"}>
                            <h2 className='subheader'>Date / Time</h2>
                            <div className='selection-container'>
                                <button className='latest-data-button' onClick={() => {
                                    this.setClanderToDate(this.state.sitl_present, true)
                                    this.setState({ units: 'hours' });
                                    this.setState({ duration: 1}, () => { this.props.holdNewCriteria(this.state); });
                                }}>
                                    <span>Most Recent Burst</span>
                                    <span className='latest-burst-time'>{this.state.sitl_present !== null ? this.state.sitl_present.toISOString().slice(0, -8): ''}</span>
                                </button>
                            </div>
                            <div className='yearPicker'>
                                <button
                                    className='yearNav leftarrow-year'
                                    onClick={() => {
                                        let obj = this.state.dateObj;
                                        obj.showYear = this.state.dateObj.showYear - 1;
                                        this.setState({ dateObj: obj }, () => {
                                            this.createMonthsCalendar(true)
                                        });
                                    }}
                                    disabled={this.minDateObj.year >= this.state.dateObj.showYear}
                                    aria-label="move selected right"
                                >
                                </button>
                                <h3 className='yearTitle'>{this.state.dateObj.showYear}</h3>
                                <button
                                    className='yearNav rightarrow-year'
                                    onClick={() => {
                                        let obj = this.state.dateObj;
                                        obj.showYear = this.state.dateObj.showYear + 1;
                                        this.setState({ dateObj: obj }, () => {
                                            this.createMonthsCalendar(true)
                                        });
                                    }}
                                    disabled={this.maxDateObj.year <= this.state.dateObj.showYear}
                                    aria-label="move selected right"
                                >
                                </button>
                            </div>
                            <hr class="divider"></hr>
                            <div className='calendarAlignment'>
                                <div className='dayPicker'>
                                    <h3 className='monthTitle'>January</h3>
                                    {this.state.calendar.days}
                                    {this.state.calendar.January.display}
                                    <h3 className='monthTitle'>February</h3>
                                    {this.state.calendar.days}
                                    {this.state.calendar.February.display}
                                    <h3 className='monthTitle'>March</h3>
                                    {this.state.calendar.days}
                                    {this.state.calendar.March.display}
                                    <h3 className='monthTitle'>April</h3>
                                    {this.state.calendar.days}
                                    {this.state.calendar.April.display}
                                    <h3 className='monthTitle'>May</h3>
                                    {this.state.calendar.days}
                                    {this.state.calendar.May.display}
                                    <h3 className='monthTitle'>June</h3>
                                    {this.state.calendar.days}
                                    {this.state.calendar.June.display}
                                    <h3 className='monthTitle'>July</h3>
                                    {this.state.calendar.days}
                                    {this.state.calendar.July.display}
                                    <h3 className='monthTitle'>August</h3>
                                    {this.state.calendar.days}
                                    {this.state.calendar.August.display}
                                    <h3 className='monthTitle'>September</h3>
                                    {this.state.calendar.days}
                                    {this.state.calendar.September.display}
                                    <h3 className='monthTitle'>October</h3>
                                    {this.state.calendar.days}
                                    {this.state.calendar.October.display}
                                    <h3 className='monthTitle'>November</h3>
                                    {this.state.calendar.days}
                                    {this.state.calendar.November.display}
                                    <h3 className='monthTitle'>December</h3>
                                    {this.state.calendar.days}
                                    {this.state.calendar.December.display}
                                </div>
                                <div className='timePicker'>
                                    {timeDisplay}
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={2.3} xl={2.3} >
                        <div className={"item-border item-container"}>
                            <h2 className='subheader'>Featured Events</h2>
                            <div className='selection-container'>
                                <a className='reference-button' href='https://doi.org/10.1029/2018JA025245' target="_blank">
                                    Reference
                                </a>
                            </div>
                            <List className={"list-style"}>
                                {eventMenuItems}
                            </List>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={5.2} xl={5.2} >
                        <div className={"item-border item-container SITL"}>
                            <h2 className='subheader'>SITL Comment Search</h2>
                            <SITLSearch
                                setSearchDateTime={this.setSearchDateTime}
                            />
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={1.8} xl={1.8} >
                        <div className={"item-border item-container selection-container add-2-buttons"}>
                            <h2 className='subheader'>Plot Duration</h2>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="plot dutation radio buttons"
                                    value={this.state.duration}
                                    onChange={(event) => {
                                        selectedDate.duration = event.target.value;
                                        this.setState({ units: (+selectedDate.duration === 10 ? 'minutes' : 'hours') });
                                        this.setState({ duration: event.target.value }, () => { this.props.holdNewCriteria(this.state); });
                                    }}
                                >
                                    <FormControlLabel value={10} control={<Radio color="default" size="small" />} label="10 minutes" />
                                    <FormControlLabel value={1} control={<Radio color="default" size="small" />} label="1 hour" />
                                    <FormControlLabel value={2} control={<Radio color="default" size="small" />} label="2 hours" />
                                    <FormControlLabel value={4} control={<Radio color="default" size="small" />} label="4 hours" />
                                    <FormControlLabel value={6} control={<Radio color="default" size="small" />} label="6 hours" />
                                </RadioGroup>
                            </FormControl>
                            {/* <Select
                                value={this.state.duration}
                                onChange={(event) => this.setState({ duration: event.target.value })}>
                                <MenuItem value={10}>10 min</MenuItem>
                                <MenuItem value={1}>1 hr</MenuItem>
                                <MenuItem value={2}>2 hrs</MenuItem>
                                <MenuItem value={4}>4 hrs</MenuItem>
                                <MenuItem value={6}>6 hrs</MenuItem>
                            </Select> */}
                        </div>
                        <button className='reset-button' onClick={() => {
                            let obj = { showYear: 2015, year: 2015, month: 10, day: 16, time: '12:00' }
                            this.setState({ dateObj: obj }, () => {
                                this.createMonthsCalendar(true)
                            });
                        }}>Reset</button>
                        <button className='next-button' onClick={() => {
                            this.props.nextButton();
                        }}>Next <span class="rightarrow-form"></span></button>
                    </Grid>
                </Grid>
            </React.Fragment >
        );
    }
}

export {
    ControlFormVariables,
    ControlFormDateTime,
}