import React, { useEffect, useState, useRef } from 'react';
import Box from "@mui/material/Box";
import axios from "axios";
import Constants from "./Constants";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import './Distributions.css';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import MultiRangeSlider from "./MultiRangeSlider";
import jetcb from "./images/jetcb.png";
import blueend from "./images/blueend.png";
import redend from "./images/redend.png"; ``

function Distributions(props) {
    const navigate = useNavigate();
    const nSlicePeriods = 20; // 11;
    const [criteria, setCriteria] = useState(props.criteria);
    const [previousBurst, setPreviousBurst] = useState(props.sharedStore.getState().previousBurst);
    const [which_species, setSpecies] = useState({
        species: props.sharedStore.getState().species || props.info.species,
        time_delta: (nSlicePeriods * .15).toFixed(2) + " secs",
        currzoom_placeholder: "1500",
        nSlice: nSlicePeriods,
        plot_detail: props.sharedStore.getState().plot_detail || "browse"
    });
    const [image_w, setImage_w] = useState(250);
    const [image_min, setImage_min] = useState(0);
    const [image_max, setImage_max] = useState(255);
    const [image_ext, setImage_ext] = useState(1);
    const [image_offset, setImage_offset] = useState(1);
    const [mytable, setTable] = useState({
        response: null,
        rDist: props.rDist,
        rDistCurrent: "electron0",
        redirect: null,
        order: 'asc',
        sharedStore: props.sharedStore,
        selected: [],
        page: 0,
        rowsPerPage: 25,
        counter: 0,
        data: null
    });
    const [status, setProgStatus] = useState("Processing");
    const [progressPercent, setPercent] = useState(0);
    const timerRef = useRef(null);
    const [reload, setReload] = useState(false);
    const [linePos, setLinePos] = useState('');

    const [skipTime, setSkipTime] = useState('1.8 sec')
    const [deltaTime, setDeltaTime] = useState('.15 sec')

    const handleSliderChange = (event, newValue) => {
        setSpecies({ currzoom_placeholder: newValue });
        console.log('handleSliderChange which_species', which_species);
    }

    const handleInputChange = (event) => {
        setSpecies({ currzoom_placeholder: event.target.value })
        console.log('handleInputChange which_species', which_species);
    }

    const handleBlur = () => {
        if (which_species.currzoom_placeholder < 100) {
            setSpecies({ currzoom_placeholder: 100 })
        } else if (which_species.currzoom_placeholder > 10000) {
            setSpecies({ currzoom_placeholder: 100 })
        }
    };

    function setReloadWrapper(toggle) {
        if (! props.firstDistLoad) {
            setReload(toggle);
        }
    }
    function incrementValue() {
        let zoomNumber = Number(which_species.currzoom_placeholder)
        zoomNumber = zoomNumber + 100;
        setSpecies({ ...which_species, currzoom_placeholder: '' + zoomNumber });
        setReloadWrapper(true);
    }

    function decrementValue() {
        let zoomNumber = Number(which_species.currzoom_placeholder)
        zoomNumber = zoomNumber - 100;
        setSpecies({ ...which_species, currzoom_placeholder: '' + zoomNumber });
        setReloadWrapper(true);
    }

    useEffect(() => {
        componentDidMount();
        return () => clearTimeout(timerRef.current);
    }, []);

    useEffect(() => {
        props.handleDist4.current = getDist_StatusMsgs
    }, [props.handleDist4.current]);

    useEffect(() => {
        props.dist_resize.current = resize;
    }, [props.dist_resize.current]);

    function componentDidMount() {
        props.line_pos = props.sharedStore.getState().line_pos;
        console.log("distributions line_pos:", props.line_pos); // sitl return
    }

    const get_slider = (min, max) => {
        let factor = .75;
        let edgefactor = .75
        let b = 0;
        if ((max - min) < 150) {
            factor = .75;
        }
        if (image_min !== min || image_max !== max) {
            setReloadWrapper(true);
        }
        setImage_min(min);
        setImage_max(max);

        let i_w = Math.ceil((factor * (max - min) - b));
        let i_b = Math.ceil(min * edgefactor)
        setImage_w(i_w);
        setImage_offset(i_b);
        setImage_ext(195 - (i_w + i_b));
    }

    function figure_skip_time(species) {
        if (species == "ions") {
            let time_skip = nSlicePeriods * .15;
            let time_skip_string = time_skip.toFixed(2) + " secs";
            return time_skip_string
        }
        else {
            let time_skip = nSlicePeriods * .03;
            let time_skip_string = time_skip.toFixed(2) + " secs";
            return time_skip_string
        }
    }

    function change_time_label(ion_selected) {
        //This will need to be reworked
        let time_skip_str = figure_skip_time("electron");
        let time_delta_str = ".03 secs";
        if (ion_selected) {
            time_skip_str = figure_skip_time("ions");
            time_delta_str = ".15 secs";
        }
        setSkipTime(time_skip_str);
        setDeltaTime(time_delta_str);
        if (ion_selected) {
            setSpecies({ ...which_species, time_delta: time_delta_str, currzoom_placeholder: "1500" });
            const czp = document.getElementById("resize-value-zoom");
            czp.placeholder = "1500"
        } else {
            setSpecies({ ...which_species, time_delta: time_delta_str, currzoom_placeholder: "10000" });
            const czp = document.getElementById("resize-value-zoom");
            czp.placeholder = "10000"
        }
    }

    function setSpeciesHandler(selected_species) {
        if (selected_species == "ions") {
            document.getElementById("ion-sel").checked = true;
            document.getElementById("electron-sel").checked = false;
            change_time_label(true);
        } else {
            document.getElementById("electron-sel").checked = true;
            document.getElementById("ion-sel").checked = false;
            change_time_label(false);
        }
        // if we stay on the page:
        setSpecies({ ...which_species, species: selected_species });
        // if we leave the page
        props.sharedStore.dispatch({
            type: 'SET_SPECIES', value: selected_species
        });
    }

    function setPlotHandler(plot_detail) {
        console.log('plot_detail', plot_detail);
        let nslice = 5;
        if (plot_detail == "browse") {
            document.getElementById("browse-sel").checked = true;
            document.getElementById("publish-sel").checked = false;
            nslice = 20; //11;
        } else {
            document.getElementById("publish-sel").checked = true;
            document.getElementById("browse-sel").checked = false;
        }
        props.sharedStore.dispatch({
            type: 'SET_PLOT_DETAIL', value: plot_detail
        });
        setSpecies({ ...which_species, nSlice: nslice, plot_detail: plot_detail });
    }

    function next_file() {
        props.setCurrentStatus("Starting next workflow");
        // move slider is a date manipulating function in ResultsScreen
        props.sharedStore.dispatch({
            type: 'SET_LINEPOS',
            value: props.move_slider(props.line_pos, 1.0 * parseFloat((figure_skip_time(which_species.species)).slice(0, 4)))
        });
        console.log("Moving slider ", (figure_skip_time(which_species.species)).slice(0, 4));

        document.getElementById("progress0").style.visibility = "visible";
        document.getElementById("progress0").style.display = "inline";

        getDist_StatusMsgs(props.criteria.spacecraft, null, null, null,
            props.sharedStore.getState().previousBurst, "next");
        
        let newTime = props.sharedStore.getState().line_pos;
        update_main_slider_pos(newTime);
        setLinePos(newTime);
    }

    function prev_file() {
        props.setCurrentStatus("Starting prev workflow");
        // move slider is a date manipulating function in ResultsScreen
        props.sharedStore.dispatch({
            type: 'SET_LINEPOS',
            value: props.move_slider(props.line_pos, -1.0 * parseFloat((figure_skip_time(which_species.species)).slice(0, 4)))
        });
        console.log("Moving slider ", (figure_skip_time(which_species.species)).slice(0, 4));

        document.getElementById("progress0").style.visibility = "visible";
        document.getElementById("progress0").style.display = "inline";

        getDist_StatusMsgs(props.criteria.spacecraft, null, null, null,
            props.sharedStore.getState().previousBurst, "prev");
        
        let newTime = props.sharedStore.getState().line_pos;
        update_main_slider_pos(newTime);
        setLinePos(newTime);
    }
    function same_time_file() {
        props.setCurrentStatus("Starting workflow");
        setPercent(1)
        document.getElementById("progress0").style.visibility = "visible";
        document.getElementById("progress0").style.display = "inline";
        getDist_StatusMsgs(props.criteria.spacecraft, null, null, null,
            props.sharedStore.getState().previousBurst, "same");
        setReloadWrapper(false)
    }
    function slider_dist() {
        document.getElementById("slider-button").disabled = true;
        props.setCurrentStatus("Starting slider workflow");
        setPercent(1)
        getDist_StatusMsgs(props.criteria.spacecraft, null, null, null,
            props.line_pos, null);
        setLinePos(props.line_pos);
    }


    function DisplayNext(direction) {
        var hide = [];
        var divtop = which_species.nSlice - 1;
        var next_make_visible = ""
        var periods = []
        for (i = 0; i < which_species.nSlice; ++i) {
            periods.push(i)
        }
        console.log('divtop', divtop);
        periods.push(0)
        for (var elem = 0; elem <= divtop; ++elem) {
            if (mytable.rDistCurrent == "electron".concat(periods[elem].toString())) {
                setTable({ ...mytable, rDistCurrent: "electron".concat((periods[elem + direction]).toString()) }); /* set curr disp to next item */
                next_make_visible = "-dr".concat((periods[elem + direction]).toString()); /* this one we will make visible */
                for (var i = 0; i <= divtop; ++i) { /* all the rest will be hidden */
                    if (i != periods[elem + direction]) {
                        hide.push(periods[i]) /* hide all but the one next being displayed */
                    }
                }
                if (!document.getElementById("ele".concat(next_make_visible)).querySelector("svg")) {
                    /* if the plot hasn't already been loaded, load it in the next-to-be-shown  div */
                    Plotly.newPlot('ele'.concat(next_make_visible), {
                        data: mytable.rDist.dist.electron[elem + direction].data,
                        layout: mytable.rDist.dist.electron[elem + direction].layout
                    });
                    Plotly.newPlot('ion'.concat(next_make_visible), {
                        data: mytable.rDist.dist.ion[elem + direction].data,
                        layout: mytable.rDist.dist.ion[elem + direction].layout
                    });
                    Plotly.newPlot('sl3'.concat(next_make_visible), {
                        data: mytable.rDist.dist.slice3[elem + direction].data,
                        layout: mytable.rDist.dist.slice3[elem + direction].layout
                    });

                    const dRion0 = document.getElementById('ion'.concat(next_make_visible));
                    const dRele0 = document.getElementById('ele'.concat(next_make_visible));
                    const dRsl30 = document.getElementById('sl3'.concat(next_make_visible));
                    dRion0.on('plotly_relayout', eventData => {
                        if (eventData.hasOwnProperty('xaxis.range[0]')) {
                            onDistZoom('ion'.concat(next_make_visible), eventData, dRion0);
                        }
                    });
                    dRele0.on('plotly_relayout', eventData => {
                        if (eventData.hasOwnProperty('xaxis.range[0]')) {
                            onDistZoom('ele'.concat(next_make_visible), eventData, dRele0);
                        }
                    });
                    dRsl30.on('plotly_relayout', eventData => {
                        if (eventData.hasOwnProperty('xaxis.range[0]')) {
                            onDistZoom('sl3'.concat(next_make_visible), eventData, dRsl30);
                        }
                    });

                }
            }
        }

        document.getElementById("ion".concat(next_make_visible)).style.visibility = "visible";
        document.getElementById("ion".concat(next_make_visible)).style.display = "inline-block";
        document.getElementById("ele".concat(next_make_visible)).style.visibility = "visible";
        document.getElementById("ele".concat(next_make_visible)).style.display = "inline-block";
        document.getElementById("sl3".concat(next_make_visible)).style.visibility = "visible";
        document.getElementById("sl3".concat(next_make_visible)).style.display = "inline-block";


        for (let fix in hide) {
            let sfix = "-dr".concat((hide[fix]).toString());
            document.getElementById("ele".concat(sfix)).style.visibility = "hidden";
            document.getElementById("ele".concat(sfix)).style.display = "none";
            document.getElementById("ion".concat(sfix)).style.visibility = "hidden";
            document.getElementById("ion".concat(sfix)).style.display = "none";
            document.getElementById("sl3".concat(sfix)).style.visibility = "hidden";
            document.getElementById("sl3".concat(sfix)).style.display = "none";
        }

    }

    function getDist_StatusMsgs(satellite, burstTime, burstStart, burstStop, previousBurst, inc_dir) {
        var currentSpecies = props.getSpecies();
        var h = image_max;
        var l = image_min;
        axios
            .get(`api/start_dist_workflow?workflow_id=${props.workflowId}&next=${inc_dir}&satellite=${satellite}` +
                `&burstTime=${burstTime}&previousTime=${previousBurst}&species=${currentSpecies}&nslices=${which_species.nSlice}&i_high=${h}&i_low=${l}`)
            .then(resp => {
                // Intercept Error

                // Set Loading Status
                const {
                    workflowId,
                    status,
                    progressPercent
                } = resp.data;

                setProgStatus(status);
                setPercent(progressPercent);
                tick_for_dist4(workflowId, 0);
            })
            .catch(error => {
                const message = "An unknown error occurred generating the plots.";
                const redirect = navigate(`/error/${message}`);
            });
    }

    function getDist4Results(workflowId) {
        const progressPercentAtStart = progressPercent;
        const remaining = 100 - progressPercentAtStart;
        const onDownloadProgress = (event) => {
            if (event.lengthComputable) {
                const fraction = event.loaded / event.total;
                const progressPercent = Math.round(
                    progressPercentAtStart + fraction * remaining);
            }
        };
        setProgStatus({ status: "Transferring plot data to your computer" });
        axios
            .get(`api/get_workflow_results_dist4?workflow_id=${workflowId}&nslices=${which_species.nSlice}`,
                { onDownloadProgress })

            .then(resp => {
                const { results } = resp.data;
                const responseDist = resp.data;


                if (!responseDist) {
                    const faux = props.tooMuchData('file transfer'); // file transfer failed (usually Chrome)
                    props.sharedStore.dispatch({
                        type: 'SET_DIST',
                        value: faux
                    });
                } else {
                    props.sharedStore.dispatch({
                        type: 'SET_DIST',
                        value: responseDist
                    });
                    props.getSpecies();
                    resize(responseDist.dist.electron[0].layout, responseDist.dist.electron[0].data[0], 0);
                    resize(responseDist.dist.ion[0].layout, responseDist.dist.ion[0].data[0], 0);
                    resize(responseDist.dist.slice3[0].layout, responseDist.dist.slice3[0].data[0], 0);

                    Plotly.newPlot(
                        'ele-dr0', {
                        data: responseDist.dist.electron[0].data,
                        layout: responseDist.dist.electron[0].layout
                    });
                    Plotly.newPlot(
                        'ion-dr0', {
                        data: responseDist.dist.ion[0].data,
                        layout: responseDist.dist.ion[0].layout
                    });
                    Plotly.newPlot(
                        'sl3-dr0', {
                        data: responseDist.dist.slice3[0].data,
                        layout: responseDist.dist.slice3[0].layout
                    });

                    props.setCurrentStatus("Loading remaining plots...");
                    setTable({
                        ...mytable, rDist: responseDist, rDistCurrent: "electron0"
                    });
                    /* for the initial set, make invisible all but the first,
                                            remove any plots that have already been placed and
                                            make invisible all but the first
                     */
                    for (var i = 1; i < which_species.nSlice; ++i) {
                        resize(responseDist.dist.electron[i].layout, responseDist.dist.electron[i].data[0], 0);
                        resize(responseDist.dist.ion[i].layout, responseDist.dist.ion[i].data[0], 0);
                        resize(responseDist.dist.slice3[i].layout, responseDist.dist.slice3[i].data[0], 0);

                        var thisElem = "ele-dr".concat(i.toString())
                        if (document.getElementById(thisElem).querySelector("svg")) {
                            document.getElementById(thisElem).removeChild(
                                document.getElementById(thisElem).firstElementChild
                            );
                        }
                        thisElem = "ion-dr".concat(i.toString())
                        if (document.getElementById(thisElem).querySelector("svg")) {
                            document.getElementById(thisElem).removeChild(
                                document.getElementById(thisElem).firstElementChild
                            );
                        }
                        thisElem = "sl3-dr".concat(i.toString())
                        if (document.getElementById(thisElem).querySelector("svg")) {
                            document.getElementById(thisElem).removeChild(
                                document.getElementById(thisElem).firstElementChild
                            );
                        }
                    }

                    document.getElementById("ele-dr0").style.visibility = "visible";
                    document.getElementById("ele-dr0").style.display = "inline-block";
                    document.getElementById("ion-dr0").style.visibility = "visible";
                    document.getElementById("ion-dr0").style.display = "inline-block";
                    document.getElementById("sl3-dr0").style.visibility = "visible";
                    document.getElementById("sl3-dr0").style.display = "inline-block";
                    if (document.getElementById("example-dr0") != null) {
                        document.getElementById("example-dr0").style.visibility = "hidden";
                        document.getElementById("example-dr0").style.display = "none";
                    }

                    if (responseDist) {
                        let valid = resp.data.dist.chosen.search("DES");
                        if (valid < 0) {
                            let finddatebegin = resp.data.dist.chosen.search("__");
                            let parse1 = resp.data.dist.chosen.substring(finddatebegin + 2, finddatebegin + 24);
                            setPreviousBurst(parse1.replace("_", "T"));
                            props.sharedStore.dispatch({ type: 'SET_PREVIOUS', value: parse1.replace("_", "T") });
                            props.sharedStore.dispatch({
                                type: 'SET_PREVIOUS',
                                value: parse1.replace("_", "T")
                            });
                        }
                        setTable({
                            ...mytable, rDist: responseDist, rDistCurrent: "electron0"
                        });
                    }

                    document.getElementById("burst-instructions").style.visibility = "visible";
                    document.getElementById("burst-instructions").style.display = "inline";

                    document.getElementById("progress0").style.visibility = "hidden";
                    document.getElementById("progress0").style.display = "none";
                    // next set of dist tools.
                    document.getElementById("burst-instructions").style.visibility = "hidden";
                    document.getElementById("burst-instructions").style.display = "none";
                    document.getElementById("next-set").style.visibility = "visible";
                    document.getElementById("next-set").style.display = "flex";
                    document.getElementById("prev-set").style.visibility = "visible";
                    document.getElementById("prev-set").style.display = "flex";
                    document.getElementById("slider-dist-button").style.visibility = "hidden";
                    document.getElementById("slider-dist-button").style.display = "none";
                    props.sharedStore.dispatch({ type: 'SET_DIST_BUTTON', value: 'next-set' });

                    const dRion0 = document.getElementById('ion-dr0');
                    const dRele0 = document.getElementById('ele-dr0');
                    const dRsl30 = document.getElementById('sl3-dr0');
                    dRion0.on('plotly_relayout', eventData => {
                        if (eventData.hasOwnProperty('xaxis.range[0]')) {
                            onDistZoom('ion-dr0', eventData, dRion0);
                        }
                    });
                    dRele0.on('plotly_relayout', eventData => {
                        if (eventData.hasOwnProperty('xaxis.range[0]')) {
                            onDistZoom('ele-dr0', eventData, dRele0);
                        }
                    });
                    dRsl30.on('plotly_relayout', eventData => {
                        if (eventData.hasOwnProperty('xaxis.range[0]')) {
                            onDistZoom('sl3-dr0', eventData, dRsl30);
                        }
                    });
                    props.sharedStore.dispatch({
                        type: 'SET_DIST_BUTTON',
                        value: "next-set"
                    });

                }
            })
            .catch(error => {
                const faux = props.tooMuchData('client processing');  // client side processing failed
                props.sharedStore.dispatch({
                    type: 'SET_RESULTS',
                    value: faux
                });
                setProgStatus('Completed');
                setPercent(0);
                props.setCurrentStatus("No burst data error. Move the slider to a position that has burst data");
                setTimeout(() => {
                    const redirect = navigate(`/results/${JSON.stringify(criteria)}`);
                    props.setState({ redirect });
                }, 100);
            });
    }

    /*
    This is called during useEffect so this is why we are settng the ion/electron checkbox here.
     */
    function resize(layout, data, setForExample) {
        var localSpecies = props.getSpecies();
        var local_detail = props.sharedStore.getState().plot_detail;
        if (local_detail != null) {
            setPlotHandler(local_detail);
        }
        setSpeciesHandler(localSpecies);
        if (localSpecies == "ions") {
            var pzoom = 1500
            var psize = 350
            /* const tdl = document.getElementById("time_label");
            tdl.innerHTML = deltaTime;
            */
            const czp = document.getElementById("resize-value-zoom");
            czp.innerHTML = "1500"
        } else {
            var pzoom = 9000
            var psize = 350
            /*
            const tdl = document.getElementById("time_label");
            tdl.innerHTML = deltaTime;
             */
            const czp = document.getElementById("resize-value-zoom");
            czp.innerHTML = "10000"
        }
        var user_values_zoom = document.getElementById("resize-value-zoom").value;
        var user_values_size = document.getElementById("resize-value-size").value;
        if (user_values_zoom || user_values_size) { // 100
            // Both ION and ELECTRON image sizes can only be so small...
            if (user_values_size < 230) {
                user_values_size = 350
                document.getElementById('resize-value-size').value = user_values_size;
                document.getElementById('resize-value-zoom').value = user_values_zoom
            }
            // ION can zoom in much closer since it is 10 times slower
            if (localSpecies == "ions") {
                if (user_values_zoom == 0) {
                    user_values_zoom = 1500;
                }
                else if (user_values_zoom < 200) {
                    user_values_zoom = 200
                    document.getElementById('resize-value-size').value = user_values_size;
                    document.getElementById('resize-value-zoom').value = user_values_zoom
                }
            } else {
                if (user_values_zoom == 0) {
                    user_values_zoom = 12000;
                }
                else if (user_values_zoom < 2000) {
                    user_values_zoom = 2000
                    document.getElementById('resize-value-size').value = user_values_size;
                    document.getElementById('resize-value-zoom').value = user_values_zoom
                }
            }

            psize = parseInt(user_values_size);
            pzoom = parseInt(user_values_zoom);
        }
        layout.height = psize;
        layout.width = psize;
        layout.xaxis.range = [-pzoom, pzoom];
        layout.yaxis.range = [-pzoom, pzoom];
        var adjust = Math.floor(psize) / 50
        adjust = adjust + 1
        if (pzoom >= 350) {
            adjust = Math.floor(psize / 35)
        }

        if (setForExample == 1) {
            layout.title.text = "Example Burst Distribution";
        }
        layout.title.font.size = adjust;
        layout.xaxis.tickfont.size = adjust;
        layout.yaxis.tickfont.size = adjust;
        layout.xaxis.title.font.size = adjust;
        layout.yaxis.title.font.size = adjust;
        data.colorbar.tickfont.size = adjust;

    }

    function tick_for_dist4(workflowId, tickCount) {
        tickCount = tickCount + 1;
        axios
            .get(`api/poll_workflow?workflow_id=${workflowId}&tick=${tickCount}`)
            .then(resp => {
                const {
                    status,
                    progressPercent,
                    done
                } = resp.data;

                if (done) {
                    /* in case we are reloading files and don't hit intermediate progress values */
                    setPercent(100);
                    props.setCurrentStatus("Loading first 3 of " + (which_species.nSlice * 3).toString() + " distribution plots..." + " " + tickCount);
                    getDist4Results(workflowId); // I'll need to write this guy
                    return;
                }
                if (progressPercent > 0) {
                    props.setCurrentStatus(status + " " + tickCount);
                    document.getElementById("progress0").style.visibility = "visible";
                    document.getElementById("progress0").style.display = "inline";
                }
                setProgStatus(status)
                if (progressPercent + tickCount <= 100) {
                    var currentPercent = progressPercent + tickCount
                    setPercent(currentPercent);
                }
                timerRef.current = setTimeout(() =>
                    tick_for_dist4(workflowId, tickCount), Constants.PROGRESS_POLL_INTERVAL);
            })
            .catch(error => {
                const message = "An unknown error occurred when checking on the workflow.";
                const redirect = navigate(`/error/${message}`);
            });
    }


    function do_we_have_burst_tracker() {
        if (document.getElementById('plotly-results')) {
            var gd = document.getElementById('plotly-results');
            if (gd.data != null) {
                if (gd.data[1].hovertemplate === '%{customdata}') { // if burst tracker is here it will be in data[1]
                    return true
                }
            }
        }
        return false
    }

    function update_main_slider_pos(new_pos) {
        var gd = document.getElementById('plotly-results');
        if (new_pos != null) {
            var update = {
                'shapes[0].visible': true,
                'shapes[0].x0': new_pos,
                'shapes[0].x1': new_pos,
            }
            props.sharedStore.dispatch({
                type: 'SET_SLIDER_COND', value: "auto"
            });

            Plotly.relayout(gd, update);
        }

    }
    function showMainSlider(truefalse) {
        props.sharedStore.dispatch({
            type: 'SET_SLIDER_COND', value: "user"
        });
        if (do_we_have_burst_tracker()) {
            document.getElementById("start_with_distributions").style.visibility = "visible";
            document.getElementById("start_with_distributions").style.display = "block";
            document.getElementById("no-burst-tracker-message").style.visibility = "hidden";
            document.getElementById("no-burst-tracker-message").style.display = "none";
        }
        else {
            document.getElementById("start_with_distributions").style.visibility = "hidden";
            document.getElementById("start_with_distributions").style.display = "none";
            document.getElementById("no-burst-tracker-message").style.visibility = "visible";
            document.getElementById("no-burst-tracker-message").style.display = "block";
            return;
        }
        var gd = document.getElementById('plotly-results');
        if (props.mid_time != null) {
            var update = {
                'shapes[0].visible': truefalse,
                'shapes[0].x0': props.mid_time,
                'shapes[0].x1': props.mid_time
            }
            // this is in ResultsScreen setLinePos(props.mid_time);
        }
        else {
            var update = {
                'shapes[0].visible': truefalse,
            }
        }
        Plotly.relayout(gd, update);
        if (document.getElementById("electron-distributions-results")) {
            document.getElementById("electron-distributions-results").style.visibility = "visible";
            document.getElementById("electron-distributions-results").style.display = "block";
        }
        document.getElementById("start_with_distributions").style.visibility = "hidden";
        document.getElementById("start_with_distributions").style.display = "none";
        document.getElementById("burst-instructions").style.visibility = "visible";
        document.getElementById("burst-instructions").style.display = "block";
        if (document.getElementById("middle-dist-id") != null) {
            document.getElementById("middle-dist-id").style.visibility = "visible";
            document.getElementById("middle-dist-id").style.display = "block";
        }
        document.getElementById("slider-dist-button").style.visibility = "hidden";
        document.getElementById("slider-dist-button").style.display = "none";
        props.sharedStore.dispatch({
            type: 'SET_DIST_BUTTON', value: "slider-button"
        });
    }

    const onDistZoom = (thisDistDiv, eventData, thisPlotlyDiv) => {
        var range_xmin = 10000;
        var range_xmax = -10000;
        var range_ymin = 10000;
        var range_ymax = -10000;
        var currentMin = 256;
        var currentMax = -1
        var relayout_signal = 0;

        for (const key in eventData) {
            // Discover the range of color levels (between 0-255)
            const value = eventData[key];
            if (key.includes('xaxis.range[0]')) {
                range_xmin = value;
            }
            else if (key.includes('xaxis.range[1]')) {
                range_xmax = value;
            }
            else if (key.includes('yaxis.range[0]')) {
                range_ymin = value;
            }
            else if (key.includes('yaxis.range[1]')) {
                range_ymax = value;
            }
        }
        if (range_xmax == range_ymax) {
            // Then this is a restore axis event.
            relayout_signal = 1; // restore axis
        }
        for (let x = 2; x < thisPlotlyDiv.data.length; ++x) {
            let thisSectorColor = parseInt(thisPlotlyDiv.data[x].customdata[0].split(":")[0]);
            if (thisSectorColor == 255) {
                continue;
            }
            // if a couple arbitrary sides are in the box range selected by the user:
            if (thisPlotlyDiv.data[x].x[0] > range_xmin && thisPlotlyDiv.data[x].x[3] < range_xmax
                && thisPlotlyDiv.data[x].y[0] > range_ymin && thisPlotlyDiv.data[x].y[3] < range_ymax) {
                if (thisSectorColor < currentMin) {
                    currentMin = thisSectorColor;
                }
                if (thisSectorColor > currentMax) {
                    currentMax = thisSectorColor;
                }
            }
        }
        if (currentMax < 0) {
            currentMax = 254;
        }
        if (currentMin < 0) {
            currentMin = 0;
        }
        thisPlotlyDiv = adjust_colorbar(thisPlotlyDiv, currentMax, currentMin, relayout_signal);
        thisPlotlyDiv = ratio_colorbar_labels(thisPlotlyDiv, currentMax, currentMin, relayout_signal);
        Plotly.react(
            thisDistDiv, thisPlotlyDiv.data, thisPlotlyDiv.layout, null);
    }
    function ratio_colorbar_labels(thisPlotlyDiv, currentMax, currentMin, relayout_signal) {
        // this returns all original labels if asked,
        // otherwise returns only 2 or 3
        if (relayout_signal == 1) { // return to default axis event
            let xs = ["10<sup>" + thisPlotlyDiv.data[0].x[0] + "</sup>"];
            for (let i = 1; i < thisPlotlyDiv.data[0].x.length; ++i) {
                xs.push("10<sup>" + thisPlotlyDiv.data[0].x[i] + "</sup>");
            }
            thisPlotlyDiv.data[0].colorbar.ticktext = xs;
            return thisPlotlyDiv;
        }
        // else put 2 or 3 labels down, but no more
        let orgrange = thisPlotlyDiv.data[0].x;
        let n = thisPlotlyDiv.data[0].x.length;
        let low_ratio = currentMin / 255.0 * n + orgrange[0];
        let high_ratio = currentMax / 255.0 * n + orgrange[0];
        let low_value = Math.floor(low_ratio);
        let high_value = Math.trunc(high_ratio); // higher negative number...
        let xs = [" "];
        for (let i = 0; i < n; ++i) {
            xs.push(" ")
        }
        thisPlotlyDiv.data[0].colorbar.ticktext = xs;
        // just 3
        xs[0] = "10<sup>" + (low_value - 1).toString() + "</sup>";
        if (high_value - 1 > low_value) {
            if (n / 2.0 == 0) {
                xs[Math.floor((n) / 2)] = "10<sup>" + (Math.trunc((low_value + high_value) / 2)).toString() + "</sup>";
            }
            else {
                xs[Math.floor((n) / 2)] = "10<sup>" + ((low_value + high_value) / 2).toString() + "</sup>";
            }
        }
        xs[n - 1] = "10<sup>" + high_value + "</sup>";
        thisPlotlyDiv.data[0].colorbar.ticktext = xs;
        return thisPlotlyDiv;


    }

    function adjust_colorbar(thisPlotlyDiv, currentMax, currentMin, relayout_signal) {
        var n = thisPlotlyDiv.data[0].colorscale.length;

        if (relayout_signal == 1) {
            var cscale = [
                [0, 'rgb(0,0,131)'],
                [.2, 'rgb(0,60,170)'],
                [.4, 'rgb(5,255,255)'],
                [.6, 'rgb(255,255,0)'],
                [.8, 'rgb(250,0,0)'],
                [1, 'rgb(128,0,0)']
            ];
            thisPlotlyDiv.data[0].colorscale = cscale;
            return thisPlotlyDiv;
        }

        var cscale = [ // 27
            [0, 'rgb(0,0,131)'],
            [0, 'rgb(0,12,139)'],
            [0, 'rgb(0,24,147)'],
            [0, 'rgb(0,36,155)'],
            [0, 'rgb(0,48,162)'],

            [.2, 'rgb(0,60,170)'],
            [.2, 'rgb(1,100,187)'],
            [.2, 'rgb(2,140,201)'],
            [.2, 'rgb(3,180,221)'],
            [.2, 'rgb(4,220,238)'],

            [.4, 'rgb(5,255,255)'],
            [.4, 'rgb(45,255,205)'],
            [.4, 'rgb(95,255,155)'],
            [.4, 'rgb(135,255,105)'],
            [.4, 'rgb(180,255,55)'],
            [.4, 'rgb(220,255,5)'],

            [.6, 'rgb(255,255,0)'],
            [.6, 'rgb(254,205,0)'],
            [.6, 'rgb(253,155,0)'],
            [.6, 'rgb(252,105,0)'],
            [.6, 'rgb(251,55,0)'],

            [.8, 'rgb(250,0,0)'],
            [.8, 'rgb(225,0,0)'],
            [.8, 'rgb(200,0,0)'],
            [.8, 'rgb(175,0,0)'],
            [.8, 'rgb(150,0,0)'],
            [1, 'rgb(128,0,0)'],
        ];

        let start = Math.floor((currentMin / 255) * cscale.length) - 2;
        let end = Math.floor((currentMax / 255) * cscale.length) + 2;
        let delta = Math.floor((end - start) / n)
        for (let i = 0; i < n; ++i) {
            let jump = start + i * delta
            thisPlotlyDiv.data[0].colorscale[i][1] = cscale[jump][1];
        }

        return thisPlotlyDiv;
    }

    function drawDivs() {
        var ion_divs = [];
        var ele_divs = [];
        var sl3_divs = [];



        for (let i = 1; i < which_species.nSlice; ++i) {
            let suffix = i.toString();
            let thisDiv = "ion-dr".concat(suffix);
            ion_divs.push(<div id={thisDiv} key={thisDiv} style={{ visibility: "hidden", display: "none" }}></div>);
            thisDiv = "ele-dr".concat(suffix);
            ele_divs.push(<div id={thisDiv} key={thisDiv} style={{ visibility: "hidden", display: "none" }}></div>);
            thisDiv = "sl3-dr".concat(suffix);
            sl3_divs.push(<div id={thisDiv} key={thisDiv} style={{ visibility: "hidden", display: "none" }}></div>);
        }

        var e = document.getElementById('ion-dr0');
        if (e !== null) {
            if (e.classList.contains('js-plotly-plot')) {
                props.setfirstDistLoad(false);
            }
        }


        return (
            <React.Fragment>

                <div id="ion-dr0"></div>
                {ion_divs}
                <div id="ele-dr0"></div>
                {ele_divs}
                <div id="sl3-dr0"></div>
                {sl3_divs}
            </React.Fragment>
        )
    }
    // if(props.line_pos !== '' && linePos !== props.line_pos && !reload) {
    //     console.log('linePos', linePos);
    //     console.log('props.line_pos', props.line_pos);
    //     setReload(true);
    // }
    return (
        <div class="dist-container">

            <div className="left-arrow-container">
                <div id="prev-set" className="left-arrow-inner-container" style={{ visibility: "hidden", display: "none" }}>
                    <button className="next-dist-left" onClick={() => DisplayNext(-1)}>
                        <div className="leftarrow-dist" />
                        <label id="time_label" className="left-time-label arrowtext"
                            htmlFor="ion_sel">Previous {deltaTime}</label>
                    </button>
                    {/* <Tooltip title="Show the previous set of 3, already loaded distribution plots" placement="top">
                    </Tooltip> */}

                    <button className="arrow-housing-dist-left" href={"#"} onClick={() => prev_file()}>
                        <div className="leftarrow-dist-double" />
                        <label className="next-set-label-left arrowtext">Previous Set {skipTime}</label>
                    </button>
                    {/* <Tooltip title="Build and Display the next set of Distribution plots" placement="top">
                    </Tooltip> */}
                </div>
            </div>
            <Grid item xs={12} style={{ overflow: "hidden" }}> {/* Distributions grid  this is the third vertical grid for the whole page*/}
                <Grid container direction="column" justifyContent="center" alignItems="center">

                    <Grid item xs={12}>
                        <h2 className='plot-subheaders'>Burst Distributions </h2>
                    </Grid>
                    {/* start of junk merged in from master */}
                    <Grid item xs={12}>
                        <div class="top-dist-container">
                            <div id="no-burst-tracker-message" style={{ padding: "2px", visibility: "hidden", display: "none" }}>
                                "<h9><i>Distributions are only available if the burst-tracker plot is shown</i></h9>";
                            </div>
                            <div id="start_with_distributions">
                                <button className="tour_click_here buttonHouse" href={"#"}
                                    onClick={(event) => showMainSlider(true)} disabled={props.disableButton}>
                                    <h6>Click here to get started with Distributions</h6>
                                    <i>movable slider will appear on graph<br />
                                        move the slider to select Distribution time period</i>
                                </button>
                            </div>

                            <div id="slider-dist-button" style={{ padding: "2px", visibility: "hidden", display: "none" }}>
                                <Tooltip title="Plot Burst Distribution" placement="top">
                                    <button id="slider-button" className="tour_start buttonHouse"
                                        href={"#"}
                                        onClick={() => slider_dist()}
                                        style={{ height: '40px', width: '320px', }}
                                    >Apply new time to burst distributions</button>
                                </Tooltip>
                            </div>
                        </div>
                    </Grid>

                    {/* end of junk merged in from master */}

                    <Grid item xs={12}>
                        <div id="middle-dist-id" class="middle-dist-container" style={{ visibility: "hidden", display: "none" }}>


                            <i className='burst-instructions' id="burst-instructions" style={{ visibility: "hidden", display: "none" }}>
                                Next, move the slider to desired time position</i>

                            {/* GRID */}
                            <div id="progress0" style={{ visibility: "hidden", display: "none" }}>
                                <h7>Distributions: {props.cs}</h7>
                                <center>
                                    <LinearProgress
                                        variant="determinate"
                                        value={progressPercent} />
                                </center>
                            </div>
                            {/* GRID */}
                            <div id="dist-button" class="dist-button-container">
                                <p className={'slider-timer-disp ' + (props.line_pos === '' || linePos !== props.line_pos ? 'hide': '')}>Slider Position: <span className='time-display'>{props.line_pos}</span></p>
                                <p className={'slider-timer-disp new ' + (props.line_pos === '' || linePos === props.line_pos ? 'hide': '')}>New Slider Position: <span className='time-display'>{props.line_pos}</span></p>
                                    {/* <input type="text" id="burst-start-time" name='burst-time' min='0' max='23'
                                        value={props.line_pos} /> */}
                                <div class="species-selector">
                                    {/* This code below will be moved to the arrows on the side */}

                                    <span>
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                defaultValue="ions"
                                                aria-labelledby="Set species handler"
                                                onChange={(event) => {
                                                    setSpeciesHandler(event.target.value);
                                                    setReloadWrapper(true);
                                                }}
                                            >
                                                <FormControlLabel className='ion-elec' value={"ions"} control={<Radio color="default" size="small" />} label="Ion" style={{ fontWeight: '500' }} id="ion-sel" />
                                                <FormControlLabel className='ion-elec' value={"electron"} control={<Radio color="default" size="small" />} label="Electron" style={{ fontWeight: '500' }} id="electron-sel" />
                                            </RadioGroup>
                                        </FormControl>
                                    </span>
                                    <span class="vl">
                                    </span>
                                    <span>
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                defaultValue="browse"
                                                aria-labelledby="plot handler select"
                                                onChange={(event) => {
                                                    setPlotHandler(event.target.value);
                                                    setReloadWrapper(true);
                                                }}
                                                style={{
                                                    paddingLeft: "20px"
                                                }}
                                            >
                                                <FormControlLabel className='brow-pub' value={"browse"} control={<Radio color="default" size="small" />} label="Browse" id="browse-sel" />
                                                <FormControlLabel className='brow-pub' value={"publish"} control={<Radio color="default" size="small" />} label="Publish" id="publish-sel"
                                                    style={{
                                                        marginRight: "0",
                                                    }} />
                                            </RadioGroup>
                                        </FormControl>

                                        {/* <span style={{ flexGrow: '1', whiteSpace: 'nowrap' }}>
                                    <span>
                                        <input id="browse-sel" type="checkbox"
                                            style={{ margin: '0px 3px 0px 0px' }}
                                            onClick={(event) => setPlotHandler("browse")} />
                                        <label htmlFor="browse-sel" style={{ verticalAlign: 'top' }}>browse</label>
                                    </span>
                                    <span style={{ margin: '0px 0px 0px 5px' }}>
                                        <input id="publish-sel" type="checkbox"
                                            style={{ margin: '0px 3px 0px 0px' }}
                                            onClick={(event) => setPlotHandler("publish")} />
                                        <label htmlFor="electron-sel" style={{ verticalAlign: 'top' }}>publish</label>
                                    </span>
                                </span> */}
                                    </span>
                                </div>
                                <div className="dist-controls">
                                    <div className="image-size">
                                        <label for="size" class='label'>Image Size (px):</label>
                                        <input type="text" id="resize-value-size" placeholder="350" name="size" onChange={() => { setReloadWrapper(true) }} />
                                    </div>
                                    <div className="zoom-level">
                                        <label for="zoom" className='zoomText label'>Zoom (km/s):</label>
                                        <RemoveIcon variant="contained" style={{ color: '#999999', margin: '3px', width: '1rem' }} ></RemoveIcon>
                                        <input type="text" id="resize-value-zoom" name='zoom'
                                            placeholder={which_species.currzoom_placeholder} onChange={() => {setReloadWrapper(true)}}/>
                                        <AddIcon variant="contained" style={{ color: '#999999', margin: '3px', width: '1rem' }}></AddIcon>
                                    </div>
                                </div>

                                <Grid item xs={12} class="dist-controls lower-section" >
                                    <label for='color' class='label'>
                                        Color Range:
                                    </label>
                                    <div>
                                        <div className="container color-bar">
                                            <img src={blueend} style={{
                                                height: '15px', width: image_offset.toString() + 'px', marginBottom: '1px'
                                            }} alt="bbar" />

                                            <img src={jetcb} style={{
                                                height: '15px', width: image_w.toString() + 'px', marginBottom: '1px'
                                            }} alt="cbar" />

                                            <img src={redend} style={{
                                                height: '15px', width: image_ext.toString() + 'px', marginBottom: '1px'
                                            }} alt="rbar" />
                                        </div>
                                        <div className="squeezebar color-slider">
                                            <Tooltip
                                                title="To show more detail in a particular range, adjust the slider to apply the entire palette of colors to that smaller span of values"
                                                placement="top">
                                                <div>
                                                    <MultiRangeSlider min={0}
                                                        name="color"
                                                        max={255}
                                                        onChange={({ min, max }) => {
                                                            get_slider(min, max);
                                                        }}
                                                        image_w={image_w}
                                                        image_offset={image_offset}
                                                        image_ext={image_ext}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div id="next-set-server-tools-div">
                                        <Tooltip title="Reload" placement="top">
                                            <button disabled={!reload} className={"tour_apply buttonHouse "} onClick={() => same_time_file()} style={{ marginLeft: "20px" }}>
                                                <label id="time_label" htmlFor="ion_sel"
                                                    style={{ padding: "0px" }}>Apply Changes</label>
                                            </button>
                                        </Tooltip>
                                    </div>
                                    {reload}
                                </Grid>

                            </div>
                            <hr class="divider"></hr>
                            < div className='dist-plot-container'>
                                {drawDivs()}
                            </div >
                        </div>
                    </Grid>
                </Grid>
            </Grid>


            <div class="right-arrow-container">
                <div id="next-set" class="right-arrow-inner-container" style={{ visibility: "hidden", display: "none" }}>
                    <button className="next-dist-right" onClick={() => DisplayNext(1)}>
                        <label id="time_label" class="right-time-label arrowtext" htmlFor="ion_sel"
                        >Next {deltaTime}</label>
                        <div className="rightarrow-dist" />
                    </button>
                    {/* <Tooltip title="Show the next set of 3, already loaded, distribution plots" placement="top">
                    </Tooltip> */}


                    <button className="arrow-housing-dist-right" href={"#"} onClick={() => next_file()}>
                        <label class="next-set-label-right arrowtext" htmlFor="ion_sel"
                        >Next set {skipTime}</label>
                        <div className="rightarrow-dist-double" />
                    </button>
                    {/* <Tooltip title="Build and Display the next set of Distribution plots" placement="top">
                    </Tooltip> */}
                </div>
            </div>
        </div >
    );
}
export default Distributions;