/**
 * Reducer for the shared state container between different routes of the=
 * application.
 * 
 * @param state Current state object
 * @param action Describes the action on the state to be executed. Should
 * contain at least a 'type' key.
 */
export default (state = null, action) => {
    const {type} = action;
    
    if (state === null) {
        state = getInitialState();
    }
    else if (type === "SET_CRITERIA") {
        state.criteria = action.value;
    }
    else if (type === "SET_RESULTS") {
        state.results = action.value;
    }
    else if(type === "SET_DIST"){
        state.dist = action.value;
    }
    else if (type === "SET_WORKFLOWID") {
        state.workflowId = action.value;
    }
    else if (type === "SET_STARTTIME") {
        state.startTime = action.value;
    }
    else if (type === "SET_SITL") {
        state.searchQuery = action.value;
    }
    else if (type === "SET_PREVIOUS") {
        state.previousBurst = action.value;
    }
    else if (type === "SET_SPECIES") {
        state.species = action.value;
    }
    else if (type === "SET_PLOT_DETAIL") {
        state.plot_detail = action.value;
    }
    else if (type === "SET_LINEPOS") {
        state.line_pos = action.value;
    }
    else if (type === "SET_DIST_BUTTON") { /* get started with distributions button */
        state.dist_button_state = action.value;
    }
    else if (type === "SET_SLIDER_COND") { /* same-as or click-to-show-pos buttons */
        state.slider_condition = action.value;
    }

    return state;
};


/**
 * Initial values fo the state container.
 * 
 * @returns container state object
 */
function getInitialState() {
    return {
        criteria: null,
        results: null,
        dist: null,
        workflowId: null,
        startTime: null

    };
}
